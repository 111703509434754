import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  menuClicksSub = Subscription.EMPTY;

  items: NbMenuItem[] = [
    { title: 'Profile', data: 'profile' },
    { title: 'Log Out', data: 'log-out' },
  ];

  constructor(private nbMenuService: NbMenuService,
              private router:Router,
              private authService:AuthService) { }

  ngOnDestroy(): void {
    this.menuClicksSub.unsubscribe();
  }

  ngOnInit(): void {
    this.menuClicksSub = this.nbMenuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'header-context-menu'),
      map(({ item: { data } }) => data)
    )
    .subscribe(key => {
      switch (key) {
        case 'log-out': this.logout(); break;
        case 'profile': this.goToProfile(); break;
        default: break;
      }
    });

  }

  async logout() {
    await this.authService.logout();
    this.router.navigate(['/login']);
  }

  async goToProfile() {
   this.router.navigate(['/account']);
  }

  getFirstName(){
    return this.authService.activeUser ? this.authService.activeUser.nameFirst : "";
  }

}
