import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NbButtonModule, NbCardModule, NbCheckboxModule, NbIconModule, NbInputModule, NbSelectModule, NbSpinnerModule, NbThemeModule, NbToggleComponent, NbToggleModule } from "@nebular/theme";
import { ProductsListComponent } from "./product-list/product-list.component";
import { ProductsRoutingModule } from "./products-routing.module";
import { ProductHomeComponent } from './product-home/product-home.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductMeasurementsComponent } from './product-measurements/product-measurements.component';
import { ProductFitParametersComponent } from './product-fit-parameters/product-fit-parameters.component';
import { ProductClientAssignmentComponent } from './product-client-assignment/product-client-assignment.component';
import { ProductAssessmentComponent } from "./product-assessment/product-assessment.component";
import { ProductFitRecommendationAssessmentComponent } from "./product-fit-recommendation-assesment/product-fit-recommendation-assessment.component";

@NgModule({
  declarations:[
    ProductsListComponent,
    ProductHomeComponent,
    ProductDetailComponent,
    ProductMeasurementsComponent,
    ProductFitParametersComponent,
    ProductClientAssignmentComponent,
    ProductAssessmentComponent,
    ProductFitRecommendationAssessmentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ProductsRoutingModule,

    NbThemeModule.forRoot(),
    NbCheckboxModule,
    NbInputModule,
    NbSelectModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbSpinnerModule,
    NbToggleModule
  ]
})
export class ProductsModule {}
