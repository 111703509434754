import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule,  } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';


// import { RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly
import { NbThemeModule } from '@nebular/theme';
import { NbSidebarModule } from '@nebular/theme';
import { NbLayoutModule } from '@nebular/theme';
import { NbTabsetModule } from '@nebular/theme';
import { NbButtonModule } from '@nebular/theme';
import { NbSpinnerModule } from '@nebular/theme';
import { NbCardModule } from '@nebular/theme';
import { NbCheckboxModule } from '@nebular/theme';
import { NbTooltipModule } from '@nebular/theme';
import { NbSelectModule } from '@nebular/theme';
import { NbInputModule } from '@nebular/theme';
import { NbMenuModule } from '@nebular/theme';
import { NbContextMenuModule } from '@nebular/theme';
import { NbToggleModule } from '@nebular/theme';
import { NbPopoverModule } from '@nebular/theme';
import { NbFormFieldModule } from '@nebular/theme';
import { NbIconModule } from '@nebular/theme';
import { NbDialogModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { AmplifyService, AmplifyModules } from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';
import {Amplify} from '@aws-amplify/core';
import API from '@aws-amplify/api';

import Interactions from '@aws-amplify/interactions';
import Storage from '@aws-amplify/storage';
// import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
// import { ChangepasswordComponent } from './changepassword/changepassword.component';
// import { LoginheaderComponent } from './loginheader/loginheader.component';
// import { ForgotpasswordheaderComponent } from './forgotpasswordheader/forgotpasswordheader.component';
import { AuthService } from './services/auth.service';
import { AuthGuard, AuthAdminGuard, UnAuthGuard } from './services/auth-guard.service';
// import { ProductshomeComponent } from './products/productshome/productshome.component';
// import { ProductslistComponent } from './products/productslist/productslist.component';
// import { ProductdetailComponent } from './products/productdetail/productdetail.component';
// import { BrandsHomeComponent } from './brands/brands-home.component';

import { ProductmodelService } from './model/productmodel.service';
import { CustomerModelService } from './model/customermodel.service';
import { LookupModelService } from './model/lookupmodel.service';
import { PortalUser } from './model/portaluser.model';
import { FileTransferService } from './services/filetransfer.service';
import { ClientModelService } from './model/clientmodel.service';

import { environment } from '../environments/environment';
// import { ProductsfilterComponent } from './products/productsfilter/productsfilter.component';
import { AccountComponent } from './account/account.component';
// import { ProducteditComponent } from './products/productedit/productedit.component';
// import { ProductgeneraldetailsComponent } from './products/productgeneraldetails/productgeneraldetails.component';
// import { ProductmeasurementsComponent } from './products/productmeasurements/productmeasurements.component';
// import { ProductfitparametersComponent } from './products/productfitparameters/productfitparameters.component';
// import { ProductclientassignmentComponent } from './products/productclientassignment/productclientassignment.component';
// import { DndDirective } from './dnd/dnd.directive';
// import { ProductmeasurementdetailsComponent } from './products/productmeasurementdetails/productmeasurementdetails.component';
// import { CustomershomeComponent } from './customers/customershome/customershome.component';
import { SidebarmenuComponent } from './sidebarmenu/sidebarmenu.component';
import { CommonModule } from '@angular/common';
import { CustomersModule } from './customers/customers.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProductsModule } from './products/products.module';
// import { CustomerlistComponent } from './customers/customerlist/customerlist.component';
// import { CustomereditComponent } from './customers/customeredit/customeredit.component';
// import { CustomerdetailComponent } from './customers/customerdetail/customerdetail.component';
// import { CustomeraccountdetailsComponent } from './customers/customeraccountdetails/customeraccountdetails.component';
// import { CustomerfithistoryComponent } from './customers/customerfithistory/customerfithistory.component';
// import { CustomermeasurementprofileComponent } from './customers/customermeasurementprofile/customermeasurementprofile.component';
// import { FileChooserComponent } from './file-chooser/file-chooser.component';
// import { ClientshomeComponent } from './clients/clientshome/clientshome.component';
// import { SliderComponent } from './slider/slider.component';
// import { CommonModule } from '@angular/common';
// import { CustomersModule } from './customers/customers.module';

import { LocalStorageService } from './services/local-storage.service';

Amplify.configure(environment.awsconfig);
Auth.configure(environment.awsconfig);
API.configure(environment.awsconfig);

@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
    HeaderComponent,
    LoginComponent,
    ForgotpasswordComponent,
    // ChangepasswordComponent,
    // LoginheaderComponent,
    // ForgotpasswordheaderComponent,
    // ProductshomeComponent,
    // ProductslistComponent,
    // ProductdetailComponent,
    // ProductsfilterComponent,
    AccountComponent,
    // ProducteditComponent,
    // ProductgeneraldetailsComponent,
    // ProductmeasurementsComponent,
    // ProductfitparametersComponent,
    // ProductclientassignmentComponent,
    // DndDirective,
    // ProductmeasurementdetailsComponent,
    // CustomershomeComponent,
    SidebarmenuComponent,
    PageNotFoundComponent,
    // CustomerlistComponent,
    // CustomereditComponent,
    // CustomerdetailComponent,
    // CustomeraccountdetailsComponent,
    // CustomerfithistoryComponent,
    // CustomermeasurementprofileComponent,
    // BrandsHomeComponent,
    // FileChooserComponent,
    // ClientshomeComponent,
    // SliderComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    NbLayoutModule,
    NbSidebarModule.forRoot(),
    NbThemeModule.forRoot({ name: 'passen' }),
    NbMenuModule.forRoot(),
    NbContextMenuModule,
    NbTabsetModule,
    NbButtonModule,
    NbSpinnerModule,
    NbCardModule,
    NbCheckboxModule,
    NbTooltipModule,
    BrowserAnimationsModule,
    NbSelectModule,
    NbInputModule,
    NbToggleModule,
    NbPopoverModule,
    NbFormFieldModule,
    NbIconModule,
    NbDialogModule.forRoot(),
    NbEvaIconsModule,
    HttpClientModule,
    NgxDatatableModule,
    CustomersModule,
    ProductsModule,
    AppRoutingModule,
  ],
  providers: [
    AuthService,
    AuthGuard,
    AuthAdminGuard,
    UnAuthGuard,
    ProductmodelService,
    LookupModelService,
    FileTransferService,
    PortalUser,
    CustomerModelService,
    ClientModelService,
    LocalStorageService,
    {
      provide: AmplifyService,
      useFactory:  () => {
        return AmplifyModules({
          Auth,
          Storage,
          Interactions
        });
      }
    },

  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
