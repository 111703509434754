import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    await this.authService.getAuthenticatedUser();

    if(!this.authService.isAuthenticated){
      // you can save redirect url so after authing we can move them back to the page they requested
      // console.log('AuthGuard:canActivate : state.url', state.url);
      // this.router.navigate([state.url]);
      // this.router.navigate(['/']);
      // return false;
      return this.router.createUrlTree(['login']);
    } else {
      return true;
    }
  }
}

@Injectable()
export class UnAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
    if (this.authService.isAuthenticated) {
      return this.router.createUrlTree([''])
    } else {
      return true;
    }
  }
}

@Injectable()
export class AuthAdminGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.authService.getAuthenticatedUser();

    if(
      this.authService.isAuthenticated
      && this.authService.activeUser
      && (this.authService.activeUser.isAdmin() || this.authService.activeUser.isPartner())
    ) {
      return true;
    }
    else {
      return this.router.createUrlTree(['login']);
    }
  }
}

