import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FitFormsModel, SizeModel } from 'src/app/model/lookupmodel.service';
import { ProductModel, ProductmodelService } from 'src/app/model/productmodel.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-product-fit-parameters',
  templateUrl: './product-fit-parameters.component.html',
  styleUrls: ['./product-fit-parameters.component.scss']
})
export class ProductFitParametersComponent implements OnInit {

  showFitDetailsMsg = true;
  doNotShowFitDetailsMsg = false;
  processing = false;

  isEditMode() { return true; }
  isNewMode() { return false; }

  product: ProductModel;
  fitForms: FitFormsModel[];
  productSizeList:SizeModel[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productModelService: ProductmodelService,
    private localStorageService: LocalStorageService
  ) {
    if (this.localStorageService.getItem('dontShowFitMsg') === true) {
      this.showFitDetailsMsg = false;
    }
  }

  ngOnInit(): void {
    this.route.data.subscribe(
      (data: {
          product: ProductModel;
          fitForms: FitFormsModel[];
          productSizeList: SizeModel[];
      }) => {
          this.product = data.product;
          this.fitForms = data.fitForms;
          this.productSizeList = data.productSizeList;

          this.productModelService.updateProductFitParametersBasedOnMeasurements()
      }
    );
  }

  async onSave() {
    this.processing = true;
    try {
        const updatedProduct = await this.product.update();

        this.router.navigate(['/product', updatedProduct.id, 'client-assignment']);
    } catch (err) {
        console.error(err);
    } finally {
        this.processing = false;
    }
  }

  removeFitDetailsMsg() {
    this.showFitDetailsMsg = false;
    if (this.doNotShowFitDetailsMsg) {
      this.localStorageService.setItem('dontShowFitMsg', true);
    }
  }

  getTotalFitWeightingOfThoseSet(){
    let returnValue:number = 0
    for(let item of this.productModelService.activeProduct.productFitParameters){
      returnValue = item.fit_weight_set ? (returnValue + item.fit_weighting) : returnValue;
    }
    return returnValue;
  }

  getTotalFitWeightingNotSet(){
    let returnValue:number = 0
    for(let item of this.productModelService.activeProduct.productFitParameters){
      returnValue = !item.fit_weight_set ? (returnValue + 1) : returnValue;
    }
    return returnValue;
  }

  onUpdateFitWeighting(event,index){
    this.product.productFitParameters[index].fit_weighting = event;
    this.product.productFitParameters[index].fit_weight_set = true;

    if((index+1) == this.product.productFitParameters.length && this.product.productFitParameters.length > 1){
      this.product.productFitParameters[index-1].fit_weight_set = false;
    }

    if((index+2) == this.product.productFitParameters.length){
      this.product.productFitParameters[index+1].fit_weight_set = false;
    }

    let totalFitWeight = this.getTotalFitWeightingOfThoseSet();
    let totalUndefined = this.getTotalFitWeightingNotSet();

    let split:number = (100-totalFitWeight)/totalUndefined;
    split = +split.toFixed(2);

    for(let i=0;i<this.product.productFitParameters.length;i++){
      if(!this.product.productFitParameters[i].fit_weight_set){
        this.product.productFitParameters[i].fit_weighting = split;
      }
    }
  }

  onUpdateIdealSize(idealPreference:number){
    this.productModelService.updateIdealSizeOnProductFitParameter(idealPreference);
  }

  onUpdateLoosePreferenceSize(loosePreferenceSize:number){
    this.productModelService.updateLoosePreferenceSizeOnProductFitParameter(loosePreferenceSize);
  }

  onUpdateTightPreferenceSize(tightPreferenceSize:number){
    this.productModelService.updateTightPreferenceSizeOnProductFitParameter(tightPreferenceSize);
  }

  getTotalFitWeighting() {
    return this.product.productFitParameters.reduce((acc, item) => {
      return acc + item.fit_weighting;
    }, 0)
  }

  requiredFields() {
    if(this.product.fit_form_id == undefined ||
      this.product.fit_form_id <= 0 ||
      this.product.ideal_size_id == undefined ||
      this.product.ideal_size_id <= 0 ||
      this.product.loose_preference_size_id == undefined ||
      this.product.loose_preference_size_id <= 0 ||
      this.product.tight_preference_size_id == undefined ||
      this.product.tight_preference_size_id <= 0){
        return false;
      }

    for(let item of this.product.productFitParameters){
      if(item.fit_weighting == undefined ||
         item.fit_weighting < 0 ||
         item.ideal_size_adjustment == undefined ||
         item.tight_size_adjustment == undefined ||
         item.loose_size_adjustment == undefined){
           return false;
         }
    }

    // if(this.getTotalFitWeighting() != 100){
    //   return false;
    // }

    return true;
  }

}
