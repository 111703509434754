import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {
  @Input() label: string;
  @Input() value: number;

  @Input() labelLow: string = 'Tight';
  @Input() labelHigh: string = 'Loose';

  @Input() inFitLow = 0.3;
  @Input() inFitHigh = 0.7;

}
