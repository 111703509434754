<router-outlet></router-outlet>

<div class="row no-gutters d-flex align-items-center mb-4">
  <div class="labelText mr-4">Select Gender</div>
  <nb-select
    class="selectionstyle"
    [selected]="selectedGenderId"
    (selectedChange)="filterByGender($event)"
  >
    <nb-option *ngFor="let item of genderList" [value]="item.id">
      {{item.description }}
    </nb-option>
  </nb-select>
</div>

<div class="row product-list no-gutters">
    <div
        class="product-card"
        *ngFor="let product of filteredProductList"
        [routerLink]="['product', product.id]"
        [queryParamsHandling]="'preserve'"
    >
        <img
            *ngIf="product.images?.length; else noImage"
            class="product-image"
            [src]="product.images[0].imageUrl"
        />

        <div class="product-brand">{{ product.brand.name }}</div>
        <div class="product-name">{{ product.name }}</div>

        <div class="product-cta">
            <img src="../../assets/brand/PassenLogo_MarkSecondary.png" />
            Analyze Fit
        </div>
    </div>
</div>

<ng-template #noImage>
  <div class="product-no-image">&nbsp;</div>
</ng-template>
