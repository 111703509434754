<div class="container">
  <!-- Left side for the form -->
  <div class="form-column">
    <form #f="ngForm" class="row">
      <!-- CLIENT Dropdown -->
      <div class="col-md-12">
        <div class="labelText mb-1">CLIENT</div>
        <nb-select
          *ngIf="clientList.length > 0"
          class="selectionstyle mb-4"
          id="clientlist"
          name="clientlist"
          placeholder="Select Client"
          [(ngModel)]="customer.organizationId"
          required
        >
          <nb-option *ngFor="let item of clientList" [value]="item.id">{{
            item.name
          }}</nb-option>
        </nb-select>

        <!-- RETAILERS -->
        <div class="labelText mb-1">RETAILERS</div>
        <div class="mb-4">
          <input nbInput [value]="retailerList.join(', ')" readonly placeholder="Retailers List" />
        </div>

        <!-- FIRST NAME -->
        <div class="labelText mb-1">FIRST NAME</div>
        <input
          class="mb-4"
          type="text"
          nbInput
          id="firstname"
          name="firstname"
          required
          [(ngModel)]="customer.nameFirst"
        />

        <!-- LAST NAME -->
        <div class="labelText mb-1">LAST NAME</div>
        <input
          class="mb-4"
          type="text"
          nbInput
          id="lastname"
          name="lastname"
          required
          [(ngModel)]="customer.nameLast"
        />

        <!-- Unit Selector for Weight -->
        <div class="d-flex align-items-center mb-1">
          <div class="labelText mr-3">WEIGHT</div>
          <nb-select
            [(ngModel)]="weightUnit"
            name="weightUnit"
            id="weightUnit"
            class="unit-selector"
            (ngModelChange)="onWeightUnitChange()"
          >
            <nb-option value="kg">Kg</nb-option>
            <nb-option value="lbs">Lbs</nb-option>
          </nb-select>
        </div>
        <input
          class="mb-4"
          type="number"
          nbInput
          id="weight"
          name="weight"
          required
          [(ngModel)]="customer.weight"
        />

        <!-- Unit Selector for Height -->
        <div class="d-flex align-items-center mb-1">
          <div class="labelText mr-3">HEIGHT</div>
          <nb-select
            [(ngModel)]="heightUnit"
            name="heightUnit"
            id="heightUnit"
            class="unit-selector"
            (ngModelChange)="onHeightUnitChange()"
          >
            <nb-option value="cm">Cm</nb-option>
            <nb-option value="in">Inches</nb-option>
          </nb-select>
        </div>
        <input
          class="mb-4"
          type="number"
          nbInput
          id="height"
          name="height"
          required
          [(ngModel)]="customer.height"
        />

        <!-- GENDER Dropdown -->
        <div class="labelText mb-1">GENDER</div>
        <nb-select
          *ngIf="genderList.length > 0"
          class="selectionstyle mb-4"
          placeholder="Select Gender"
          name="genderid"
          id="genderid"
          required
          [(ngModel)]="customer.genderId"
        >
          <nb-option *ngFor="let item of genderList" [value]="item.id">{{
            item.name | titlecase
          }}</nb-option>
        </nb-select>

        <!-- EMAIL ADDRESS -->
        <div class="labelText mb-1">EMAIL ADDRESS</div>
        <input
          class="mb-4"
          type="text"
          nbInput
          id="email"
          name="email"
          [(ngModel)]="customer.email"
          required
          email
        />

        <!-- PHONE NUMBER -->
        <div class="labelText mb-1">PHONE NUMBER</div>
        <input
          class="mb-4"
          type="text"
          nbInput
          id="phone"
          name="phone"
          required
          [(ngModel)]="customer.phoneNumber"
        />

        <!-- COUNTRY Dropdown (disabled) -->
        <div class="labelText mb-1">COUNTRY</div>
        <nb-select
          *ngIf="countryList.length > 0"
          class="selectionstyle mb-4"
          placeholder="Select Country"
          name="countryid"
          id="countryid"
          required
          [selected]="'1'"
          disabled
        >
          <nb-option *ngFor="let item of countryList" [value]="item.id">{{
            item.description
          }}</nb-option>
        </nb-select>

        <!-- POSTAL CODE -->
        <div class="labelText mb-1">POSTAL CODE</div>
        <input
          class="mb-4"
          type="text"
          nbInput
          id="postalcode"
          name="postalcode"
          required
          [(ngModel)]="customer.postalCode"
        />
      </div>

      <!-- SAVE Button -->
      <div class="row">
        <button
          class="ml-4"
          nbSpinnerStatus="primary"
          shape="round"
          status="primary"
          nbButton
          [nbSpinner]="processing"
          (click)="onSave()"
        >
          SAVE
        </button>
      </div>
    </form>
  </div>


</div>
