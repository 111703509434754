import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private ls: Storage

  constructor() {
    this.ls = localStorage
  }

  setItem(key:string, value: any):void {
    this.ls.setItem(key, JSON.stringify(value));
  }

  getItem(key):any {
    try {
      return JSON.parse(this.ls.getItem(key))
    } catch (e) {}
    return null;
  }
}
