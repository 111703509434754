import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ProductModel } from 'src/app/model/productmodel.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-product-home',
  templateUrl: './product-home.component.html',
  styleUrls: ['./product-home.component.scss']
})
export class ProductHomeComponent implements OnInit {

  product: ProductModel;

  isNew = false;
  isAdmin = false;

  constructor(private route: ActivatedRoute, private authService: AuthService) {}

  ngOnInit() {
    this.route.data
      .subscribe((data: {
        product: ProductModel,
      }) => {
        this.product = data.product;
        this.isNew = (this.route.url as BehaviorSubject<UrlSegment[]>)
          .value[0]?.path === 'new-product';
        this.isAdmin = this.authService.activeUser?.isAdmin();
      });
  }
}
