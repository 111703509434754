export const environment = {
  production: true,
  awsconfig: {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:57c3bc89-c344-4ecc-85b9-e223c822b201",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_1q2eryl33",
    aws_user_pools_web_client_id: "1h3i284krkdj6b3r6fopn3pnis",
    oauth: {
        domain: "passen-prod.auth.us-east-1.amazoncognito.com",
        scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: "https://localhost:4200/",
        redirectSignOut: "https://localhost:4200/",
        responseType: "code"
    },
    federationTarget: "COGNITO_USER_POOLS",

    aws_appsync_authenticationType: "AWS_IAM",
    aws_appsync_graphqlEndpoint: "https://hwhlb7opozhyxpl6jwke5k7kvm.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_appid: "mqrwbwetzncjjk7zgpe2tshtoa"
  }
};
