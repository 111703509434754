<div class="row">
    <div class="col-md-5">
        <div class="labelText mb-1">BRAND</div>
        <nb-select
            *ngIf="brands.length > 0"
            class="selectionstyle"
            id="brandlist"
            name="brandlist"
            placeholder="Select"
            [(ngModel)]="product.brand_id"
            required
        >
            <nb-option *ngFor="let item of brands" [value]="item.id">{{
                item.name
            }}</nb-option>
        </nb-select>

        <div class="mt-3 mb-4">
            <a [routerLink]="" class="linkstyles" (click)="onEditBrands()"
                >+ ADD/EDIT BRANDS</a
            >
        </div>

        <div class="labelText mb-1">TYPE</div>
        <nb-select
            *ngIf="productTypes.length > 0"
            class="selectionstyle mb-5"
            placeholder="Select"
            [(ngModel)]="product.product_type_id"
        >
            <nb-option *ngFor="let item of productTypes" [value]="item.id">{{
                item.description
            }}</nb-option>
        </nb-select>

        <div class="labelText mb-1">NAME</div>
        <input
            class="mb-4"
            type="text"
            nbInput
            id="name"
            name="name"
            [(ngModel)]="product.name"
        />

        <div class="labelText mb-1">SKU</div>
        <input
            class="mb-4"
            type="text"
            nbInput
            id="sku"
            name="sku"
            [(ngModel)]="product.sku"
        />

        <div class="labelText mb-1">GENDER</div>
        <nb-select
            *ngIf="genderList.length > 0"
            class="selectionstyle mb-4"
            placeholder="Select"
            [(ngModel)]="product.gender_id"
        >
            <nb-option *ngFor="let item of genderList" [value]="item.id">{{
                item.description
            }}</nb-option>
        </nb-select>
    </div>
    <div class="col-md-5">
        <div class="labelText mb-1">SIZES</div>
        <nb-select
            *ngIf="sizeList.length > 0"
            class="selectionstyle mb-4"
            placeholder="Select"
            multiple
            [(ngModel)]="product.sizeIds"
        >
            <nb-option *ngFor="let item of sizeList" [value]="item.id">{{
                item.shortName
            }}</nb-option>
        </nb-select>

        <div class="labelText mb-1">DESCRIPTION (OPTIONAL)</div>
        <textarea
            nbInput
            class="textareastyle mb-4"
            [(ngModel)]="product.description"
        >
        </textarea>

        <div class="labelText"
             style="padding-bottom: 10px;"
          >IMAGES (OPTIONAL)</div>
        <table class="mb-1">
            <tr>
                <td>
                    <!-- Drag and drop functionality is commented out for now -->
                    <!--
                    <div
                        class="downloadbox"
                        appDnd
                        (fileDropped)="onFileDropped($event)"
                    >
                        <input
                            type="file"
                            id="fileDropRef"
                            multiple
                            (change)="fileBrowseHandler($event.target.files)"
                            #fileDropRef
                            style="display: none"
                        />

                        <div>
                            <img
                                style="
                                    width: 32px;
                                    height: 32px;
                                    vertical-align: middle;
                                "
                                src="../../assets/icons/27-icon-download.png"
                            />
                        </div>
                        <div class="downloadboxtext">Drag</div>
                        <div class="downloadboxtext">a File Here</div>
                    </div>
                    -->
                </td>
                <td style="vertical-align: top">
                    <span
                        class="downloadboxtext"
                        style="font-size: 14px;"
                        >&nbsp;</span
                    >
                    <a
                        [routerLink]=""
                        class="linkstyles"
                        (click)="fileDropRef.click()"
                        style="font-size: 14px"
                        >CHOOSE A FILE</a
                    >
                    <input
                        type="file"
                        id="fileDropRef"
                        multiple
                        (change)="fileBrowseHandler($event.target.files)"
                        #fileDropRef
                        style="display: none"
                    />
                </td>
            </tr>
        </table>
        <table class="mb-4 images">
            <tr *ngFor="let item of product.images; let i = index">
                <td *ngIf="item.imageUrl" class="images">
                    <img
                        style="width: 32px; height: 32px; vertical-align: middle;"
                        [src]="item.imageUrl"
                        (error)="imgError($event, item.id)"
                    />
                </td>
                <td class="images">
                    <span class="labelText mb-1">{{ item.imageKey }}</span>
                </td>
                <td class="images">
                    <a (click)="removeFile(i)">
                        <img
                            style="width: 24px; height: 24px"
                            src="../../assets/icons/03-button-1-24-px-tiny-2-outline-1-primary.png"
                        />
                    </a>
                </td>
            </tr>
        </table>
    </div>

    <div class="col-md-2 titleText mt-4">
        <div>
            <button
                class="mb-2 save-btn"
                [nbSpinner]="processing"
                nbSpinnerStatus="primary"
                shape="round"
                [disabled]="!requiredFields()"
                nbButton
                (click)="onSave()"
            >
                SAVE
            </button>
        </div>
    </div>

    <div class="col-md-2 titleText mt-4">
        <div>
            <button
                class="mb-2 save-btn"
                shape="round"
                [disabled]="!requiredFields()"
                nbButton
                (click)="onCloneProduct(product.id)"
            >
            CLONE
            </button>
        </div>
    </div>
</div>
