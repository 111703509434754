/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const organizations = /* GraphQL */ `
  query Organizations($filter: FilterInput, $limit: Int, $offset: Int) {
    organizations(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      contactName
      contactPhone
      contactEmail
      notes
      organizationType {
        id
        name
        description
      }
      customerCount
      productCount
      userCount
      createdAt
      updatedAt
      createdBy {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
      updatedBy {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
    }
  }
`;
export const organization = /* GraphQL */ `
  query Organization($id: ID!) {
    organization(id: $id) {
      id
      name
      contactName
      contactPhone
      contactEmail
      notes
      organizationType {
        id
        name
        description
      }
      customerCount
      productCount
      userCount
      createdAt
      updatedAt
      createdBy {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
      updatedBy {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
    }
  }
`;
export const organizationTypes = /* GraphQL */ `
  query OrganizationTypes($filter: FilterInput, $limit: Int, $offset: Int) {
    organizationTypes(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      description
    }
  }
`;
export const organizationType = /* GraphQL */ `
  query OrganizationType($id: ID!) {
    organizationType(id: $id) {
      id
      name
      description
    }
  }
`;
export const users = /* GraphQL */ `
  query Users($filter: FilterInput, $limit: Int, $offset: Int) {
    users(filter: $filter, limit: $limit, offset: $offset) {
      id
      userName
      nameFirst
      nameLast
      email
      phone
      memberships {
        id
        user {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        organization {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        role {
          id
          name
          description
        }
        bodyMeasurementsComplete
        fitSessionComplete
        createdAt
      }
    }
  }
`;
export const user = /* GraphQL */ `
  query User($id: ID, $email: String) {
    user(id: $id, email: $email) {
      id
      userName
      nameFirst
      nameLast
      email
      phone
      memberships {
        id
        user {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        organization {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        role {
          id
          name
          description
        }
        bodyMeasurementsComplete
        fitSessionComplete
        createdAt
      }
    }
  }
`;
export const products = /* GraphQL */ `
  query Products($filter: FilterInput, $limit: Int, $offset: Int) {
    products(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      sku
      description
      baseUnitPrice
      brand {
        id
        name
        imageUrl
      }
      gender {
        id
        name
        description
      }
      productType {
        id
        name
        description
      }
      productStatus {
        id
        name
        description
      }
      clients {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
      sizes {
        productId
        sizeId
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
      }
      benchmarkMeasurements {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
      }
      coreMeasurements {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
      }
      fitForm {
        id
        name
        gender {
          id
          name
          description
        }
        fitFormTypeId
        fitFormType {
          id
          name
          description
        }
      }
      idealSize {
        id
        shortName
        longName
        rank
      }
      loosePreferenceSize {
        id
        shortName
        longName
        rank
      }
      tightPreferenceSize {
        id
        shortName
        longName
        rank
      }
      fitParameters {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        idealSizeAdjustment
        looseSizeAdjustment
        tightSizeAdjustment
        isFitPoint
        fitWeighting
      }
      images {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        productImageType {
          id
          name
          description
        }
        imageKey
        imageUrl
        color {
          id
          name
          description
          code
        }
      }
    }
  }
`;
export const product = /* GraphQL */ `
  query Product($id: ID!) {
    product(id: $id) {
      id
      name
      sku
      description
      baseUnitPrice
      brand {
        id
        name
        imageUrl
      }
      gender {
        id
        name
        description
      }
      productType {
        id
        name
        description
      }
      productStatus {
        id
        name
        description
      }
      clients {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
      sizes {
        productId
        sizeId
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
      }
      benchmarkMeasurements {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
      }
      coreMeasurements {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
      }
      fitForm {
        id
        name
        gender {
          id
          name
          description
        }
        fitFormTypeId
        fitFormType {
          id
          name
          description
        }
      }
      idealSize {
        id
        shortName
        longName
        rank
      }
      loosePreferenceSize {
        id
        shortName
        longName
        rank
      }
      tightPreferenceSize {
        id
        shortName
        longName
        rank
      }
      fitParameters {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        idealSizeAdjustment
        looseSizeAdjustment
        tightSizeAdjustment
        isFitPoint
        fitWeighting
      }
      images {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        productImageType {
          id
          name
          description
        }
        imageKey
        imageUrl
        color {
          id
          name
          description
          code
        }
      }
    }
  }
`;
export const productStatuses = /* GraphQL */ `
  query ProductStatuses($filter: FilterInput, $limit: Int, $offset: Int) {
    productStatuses(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      description
    }
  }
`;
export const brand = /* GraphQL */ `
  query Brand($id: ID!) {
    brand(id: $id) {
      id
      name
      imageUrl
    }
  }
`;
export const brands = /* GraphQL */ `
  query Brands($filter: FilterInput, $limit: Int, $offset: Int) {
    brands(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      imageUrl
    }
  }
`;
export const productType = /* GraphQL */ `
  query ProductType($id: ID!) {
    productType(id: $id) {
      id
      name
      description
    }
  }
`;
export const productTypes = /* GraphQL */ `
  query ProductTypes($filter: FilterInput, $limit: Int, $offset: Int) {
    productTypes(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      description
    }
  }
`;
export const productImageType = /* GraphQL */ `
  query ProductImageType($id: ID!) {
    productImageType(id: $id) {
      id
      name
      description
    }
  }
`;
export const productImageTypes = /* GraphQL */ `
  query ProductImageTypes($filter: FilterInput, $limit: Int, $offset: Int) {
    productImageTypes(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      description
    }
  }
`;
export const membership = /* GraphQL */ `
  query Membership($id: ID!) {
    membership(id: $id) {
      id
      user {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
      organization {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
      role {
        id
        name
        description
      }
      bodyMeasurementsComplete
      fitSessionComplete
      createdAt
    }
  }
`;
export const memberships = /* GraphQL */ `
  query Memberships($filter: FilterInput, $limit: Int, $offset: Int) {
    memberships(filter: $filter, limit: $limit, offset: $offset) {
      id
      user {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
      organization {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
      role {
        id
        name
        description
      }
      bodyMeasurementsComplete
      fitSessionComplete
      createdAt
    }
  }
`;
export const measurement = /* GraphQL */ `
  query Measurement($id: ID!) {
    measurement(id: $id) {
      id
      name
      description
      measurementType {
        id
        name
        description
      }
    }
  }
`;
export const measurements = /* GraphQL */ `
  query Measurements($filter: FilterInput, $limit: Int, $offset: Int) {
    measurements(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      description
      measurementType {
        id
        name
        description
      }
    }
  }
`;
export const size = /* GraphQL */ `
  query Size($id: ID!) {
    size(id: $id) {
      id
      shortName
      longName
      rank
    }
  }
`;
export const sizes = /* GraphQL */ `
  query Sizes($filter: FilterInput, $limit: Int, $offset: Int) {
    sizes(filter: $filter, limit: $limit, offset: $offset) {
      id
      shortName
      longName
      rank
    }
  }
`;
export const gender = /* GraphQL */ `
  query Gender($id: ID!) {
    gender(id: $id) {
      id
      name
      description
    }
  }
`;
export const genders = /* GraphQL */ `
  query Genders($filter: FilterInput, $limit: Int, $offset: Int) {
    genders(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      description
    }
  }
`;
export const fitForm = /* GraphQL */ `
  query FitForm($id: ID!) {
    fitForm(id: $id) {
      id
      name
      gender {
        id
        name
        description
      }
      fitFormTypeId
      fitFormType {
        id
        name
        description
      }
    }
  }
`;
export const fitForms = /* GraphQL */ `
  query FitForms($filter: FilterInput, $limit: Int, $offset: Int) {
    fitForms(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      gender {
        id
        name
        description
      }
      fitFormTypeId
      fitFormType {
        id
        name
        description
      }
    }
  }
`;
export const color = /* GraphQL */ `
  query Color($id: ID!) {
    color(id: $id) {
      id
      name
      description
      code
    }
  }
`;
export const colors = /* GraphQL */ `
  query Colors($filter: FilterInput, $limit: Int, $offset: Int) {
    colors(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      description
      code
    }
  }
`;
export const organizationProduct = /* GraphQL */ `
  query OrganizationProduct($id: ID!) {
    organizationProduct(id: $id) {
      productId
      organizationId
      product {
        id
        name
        sku
        description
        baseUnitPrice
        brand {
          id
          name
          imageUrl
        }
        gender {
          id
          name
          description
        }
        productType {
          id
          name
          description
        }
        productStatus {
          id
          name
          description
        }
        clients {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        sizes {
          productId
          sizeId
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
        }
        benchmarkMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        coreMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        fitForm {
          id
          name
          gender {
            id
            name
            description
          }
          fitFormTypeId
          fitFormType {
            id
            name
            description
          }
        }
        idealSize {
          id
          shortName
          longName
          rank
        }
        loosePreferenceSize {
          id
          shortName
          longName
          rank
        }
        tightPreferenceSize {
          id
          shortName
          longName
          rank
        }
        fitParameters {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          measurement {
            id
            name
            description
          }
          idealSizeAdjustment
          looseSizeAdjustment
          tightSizeAdjustment
          isFitPoint
          fitWeighting
        }
        images {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          productImageType {
            id
            name
            description
          }
          imageKey
          imageUrl
          color {
            id
            name
            description
            code
          }
        }
      }
      organization {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
    }
  }
`;
export const organizationProducts = /* GraphQL */ `
  query OrganizationProducts($filter: FilterInput, $limit: Int, $offset: Int) {
    organizationProducts(filter: $filter, limit: $limit, offset: $offset) {
      productId
      organizationId
      product {
        id
        name
        sku
        description
        baseUnitPrice
        brand {
          id
          name
          imageUrl
        }
        gender {
          id
          name
          description
        }
        productType {
          id
          name
          description
        }
        productStatus {
          id
          name
          description
        }
        clients {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        sizes {
          productId
          sizeId
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
        }
        benchmarkMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        coreMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        fitForm {
          id
          name
          gender {
            id
            name
            description
          }
          fitFormTypeId
          fitFormType {
            id
            name
            description
          }
        }
        idealSize {
          id
          shortName
          longName
          rank
        }
        loosePreferenceSize {
          id
          shortName
          longName
          rank
        }
        tightPreferenceSize {
          id
          shortName
          longName
          rank
        }
        fitParameters {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          measurement {
            id
            name
            description
          }
          idealSizeAdjustment
          looseSizeAdjustment
          tightSizeAdjustment
          isFitPoint
          fitWeighting
        }
        images {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          productImageType {
            id
            name
            description
          }
          imageKey
          imageUrl
          color {
            id
            name
            description
            code
          }
        }
      }
      organization {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
    }
  }
`;
export const customer = /* GraphQL */ `
  query Customer($id: ID!) {
    customer(id: $id) {
      id
      nameFirst
      nameLast
      email
      phoneNumber
      postalCode
      gender {
        id
        name
        description
      }
      createdAt
      bodyMeasurementsComplete
      fitSessionCompleted
      country {
        id
        code
        description
        continent
      }
      user {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
      organization {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
      sizeStreamAccountId
    }
  }
`;
export const customers = /* GraphQL */ `
  query Customers($filter: FilterInput, $limit: Int, $offset: Int) {
    customers(filter: $filter, limit: $limit, offset: $offset) {
      id
      nameFirst
      nameLast
      email
      phoneNumber
      postalCode
      gender {
        id
        name
        description
      }
      createdAt
      bodyMeasurementsComplete
      fitSessionCompleted
      country {
        id
        code
        description
        continent
      }
      user {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
      organization {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
      sizeStreamAccountId
    }
  }
`;
export const customerMeasurements = /* GraphQL */ `
  query CustomerMeasurements(
    $customerId: ID!
    $filter: FilterInput
    $limit: Int
    $offset: Int
  ) {
    customerMeasurements(
      customerId: $customerId
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      customer {
        id
        nameFirst
        nameLast
        email
        phoneNumber
        postalCode
        gender {
          id
          name
          description
        }
        createdAt
        bodyMeasurementsComplete
        fitSessionCompleted
        country {
          id
          code
          description
          continent
        }
        user {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        organization {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        sizeStreamAccountId
      }
      measurement {
        id
        name
        description
        measurementType {
          id
          name
          description
        }
      }
      value
    }
  }
`;
export const country = /* GraphQL */ `
  query Country($id: ID!) {
    country(id: $id) {
      id
      code
      description
      continent
    }
  }
`;
export const countries = /* GraphQL */ `
  query Countries($filter: FilterInput, $limit: Int, $offset: Int) {
    countries(filter: $filter, limit: $limit, offset: $offset) {
      id
      code
      description
      continent
    }
  }
`;
export const fitSessionResult = /* GraphQL */ `
  query FitSessionResult($id: ID!) {
    fitSessionResult(id: $id) {
      id
      customerId
      productId
      sizeId
      measurementId
      fitTechnicianNormalizedScore
      fitTechnicianInputDate
      isValid
    }
  }
`;
export const fitSessionResults = /* GraphQL */ `
  query FitSessionResults($filter: FilterInput, $limit: Int, $offset: Int) {
    fitSessionResults(filter: $filter, limit: $limit, offset: $offset) {
      id
      customerId
      productId
      sizeId
      measurementId
      fitTechnicianNormalizedScore
      fitTechnicianInputDate
      isValid
    }
  }
`;
export const fitRecommendations = /* GraphQL */ `
  query FitRecommendations(
    $customerId: ID!
    $productIds: [ID]!
    $options: FitOptions
  ) {
    fitRecommendations(
      customerId: $customerId
      productIds: $productIds
      options: $options
    ) {
      productFitRecommendations {
        productId
        success
        errorMessage
        recommendation {
          size
          weightedScore
          sizeByFitPreference
          sizeByTopThreeSelectivePreference
          sizeByVariableSelectivePreference
          sizeByFitPointPreference
        }
        weightedScores {
          size
          weightedScore
        }
        weightedScoreBreakdown {
          size
          scoreBreakdown {
            fitPoint
            value
            weightedScore
            normalizedScore
          }
        }
      }
      input
    }
  }
`;
export const scannerUserByPhone = /* GraphQL */ `
  query ScannerUserByPhone($phoneNumber: AWSPhone!) {
    scannerUserByPhone(phoneNumber: $phoneNumber) {
      phoneNumber
      nameFirst
      nameLast
      id
    }
  }
`;
export const scanExists = /* GraphQL */ `
  query ScanExists($id: ID!, $tokenInput: SessionTokenInput!) {
    scanExists(id: $id, tokenInput: $tokenInput) {
      exists
      scan
    }
  }
`;
export const getBodyModelUrl = /* GraphQL */ `
  query GetBodyModelUrl($sizeStreamAccountId: ID!) {
    getBodyModelUrl(sizeStreamAccountId: $sizeStreamAccountId) {
      url
    }
  }
`;
export const getRecommendationsRequests = /* GraphQL */ `
  query GetRecommendationsRequests($customerId: ID!) {
    getRecommendationsRequests(customerId: $customerId) {
      brandId
      id
      productId
      brandName
    }
  }
`;
export const getScanCoreMeasures = /* GraphQL */ `
  query GetScanCoreMeasures($id: ID!, $tokenInput: SessionTokenInput!) {
    getScanCoreMeasures(id: $id, tokenInput: $tokenInput) {
      measures
    }
  }
`;
export const scannerUserByID = /* GraphQL */ `
  query ScannerUserByID($id: ID!) {
    scannerUserByID(id: $id) {
      phoneNumber
      nameFirst
      nameLast
      id
    }
  }
`;
export const accountPhoneVerification = /* GraphQL */ `
  query AccountPhoneVerification($phoneNumber: AWSPhone!) {
    accountPhoneVerification(phoneNumber: $phoneNumber) {
      phoneNumberRecordExists
    }
  }
`;
export const verifyOTP = /* GraphQL */ `
  query VerifyOTP($phoneNumber: AWSPhone!, $otp: String!, $source: String!) {
    verifyOTP(phoneNumber: $phoneNumber, otp: $otp, source: $source) {
      success
    }
  }
`;
export const verifySessionToken = /* GraphQL */ `
  query VerifySessionToken($phoneNumber: AWSPhone!, $sessionToken: ID!) {
    verifySessionToken(phoneNumber: $phoneNumber, sessionToken: $sessionToken) {
      valid
    }
  }
`;
export const analyzeFitRecommendation = /* GraphQL */ `
  query AnalyzeFitRecommendation($customerId: ID!, $productId: ID!) {
    analyzeFitRecommendation(customerId: $customerId, productId: $productId) {
      output
    }
  }
`;
export const generateFitSessionComparison = /* GraphQL */ `
  query GenerateFitSessionComparison(
    $customerIds: [ID]!
    $productIds: [ID]!
    $useManualMeasurements: Boolean
  ) {
    generateFitSessionComparison(
      customerIds: $customerIds
      productIds: $productIds
      useManualMeasurements: $useManualMeasurements
    ) {
      output
    }
  }
`;
export const generateFitPreferenceComparison = /* GraphQL */ `
  query GenerateFitPreferenceComparison(
    $customerIds: [ID]!
    $productIds: [ID]!
    $useManualMeasurements: Boolean
  ) {
    generateFitPreferenceComparison(
      customerIds: $customerIds
      productIds: $productIds
      useManualMeasurements: $useManualMeasurements
    ) {
      output
    }
  }
`;
