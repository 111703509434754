import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MeasurementModel, SizeModel } from 'src/app/model/lookupmodel.service';
import { ProductModel, ProductmodelService, ProductMeasurementsModel} from 'src/app/model/productmodel.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-product-measurements',
  templateUrl: './product-measurements.component.html',
  styleUrls: ['./product-measurements.component.scss']
})
export class ProductMeasurementsComponent implements OnInit {

  showCoreProductMeasurementMsg = true;
  doNotShowCoreMeasurementsMsg = false;
  showBenchmarkProductMeasurementMsg = true;
  doNotShowBenchmarkMeasurementsMsg = false;

  selectedBenchmarkItem: number = -1;
  selectedCoreItem: number = -1;

  processing:boolean = false;

  product: ProductModel;
  productSizeList:SizeModel[];
  benchmarkMeasurementList: MeasurementModel[];
  coreMeasurementList: MeasurementModel[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productModelService: ProductmodelService,
    private localStorageService: LocalStorageService
  ) {
    if (this.localStorageService.getItem('dontShowBenchmarkMsg') === true) {
      this.showBenchmarkProductMeasurementMsg = false;
    }
    if (this.localStorageService.getItem('dontShowCoreMsg') === true) {
      this.showCoreProductMeasurementMsg = false;
    }
  }

  ngOnInit(): void {
    this.route.data.subscribe(
      (data: {
          product: ProductModel;
          productSizeList: SizeModel[];
          benchmarkMeasurementList: MeasurementModel[];
      }) => {
          this.product = data.product;
          this.productSizeList = data.productSizeList;
          this.benchmarkMeasurementList = data.benchmarkMeasurementList;
          this.coreMeasurementList = data.benchmarkMeasurementList;

          this.ensureAllSizesInBenchmark();
          this.ensureAllSizesInCore();
      }
    );

  }
ensureAllSizesInBenchmark() {
  this.product.benchmarkMeasurementsList.forEach((benchmarkItem) => {
    const existingSizeIds = benchmarkItem.map(bm => bm.sizeId);

    // Ensure every size in productSizeList has an entry in benchmarkItem
    this.productSizeList.forEach(size => {
      if (!existingSizeIds.includes(size.id)) {
        // Create a new benchmark measurement entry using the ProductMeasurementsModel class
        let newBenchmarkMeasurement = new ProductMeasurementsModel();

        // Assign the necessary properties to the newBenchmarkMeasurement object
        newBenchmarkMeasurement.productId = Number(this.product.id);
        newBenchmarkMeasurement.measurementId = benchmarkItem[0].measurementId;
        newBenchmarkMeasurement.measurement = benchmarkItem[0].measurement;
        newBenchmarkMeasurement.sizeId = size.id;
        newBenchmarkMeasurement.size = size;
        newBenchmarkMeasurement.isBenchmarkMeasurement = true;
        newBenchmarkMeasurement.gradeRule = null;
        newBenchmarkMeasurement.gradeRuleEdited = false;
        newBenchmarkMeasurement.value = undefined;
        newBenchmarkMeasurement.isHalfMeasurement = false;  // Add the missing field

        // Push the new object to the benchmarkItem array
        benchmarkItem.push(newBenchmarkMeasurement);
      }
    });

    // Sort the benchmarkItem list by sizeId after adding any missing entries
    benchmarkItem.sort((a, b) => a.sizeId - b.sizeId);
  });
}
ensureAllSizesInCore() {
  this.product.coreMeasurementsList.forEach((coreItem) => {
    const existingSizeIds = coreItem.map(cm => cm.sizeId);

    this.productSizeList.forEach(size => {
      if (!existingSizeIds.includes(size.id)) {
        // Create a new ProductMeasurementsModel object
        let newCoreMeasurement = new ProductMeasurementsModel();

        // Assign the necessary properties
        newCoreMeasurement.productId = Number(this.product.id);
        newCoreMeasurement.measurementId = coreItem[0].measurementId;
        newCoreMeasurement.measurement = coreItem[0].measurement;
        newCoreMeasurement.sizeId = size.id;
        newCoreMeasurement.size = size;
        newCoreMeasurement.isBenchmarkMeasurement = false;
        newCoreMeasurement.gradeRule = null;
        newCoreMeasurement.gradeRuleEdited = false;
        newCoreMeasurement.value = undefined;
        newCoreMeasurement.isHalfMeasurement = false;  // Add any required fields

        // Add the new core measurement to the coreItem array
        coreItem.push(newCoreMeasurement);
      }
    });

    // Sort the coreItem list by sizeId
    coreItem.sort((a, b) => a.sizeId - b.sizeId);
  });
}

  cantAddBenchmark() {
    return !this.selectedBenchmarkItem || this.product.benchmarkMeasurementsList.findIndex(m =>
      m.length && m[0].measurementId && m[0].measurementId === this.selectedBenchmarkItem
    ) !== -1;
  }

  addBenchmark() {
    this.productModelService.addNewItemToBenchMarkList(this.selectedBenchmarkItem, this.product)
      .finally(() => this.selectedBenchmarkItem = -1)
  }

  removeBenchmark(index) {
    this.product.benchmarkMeasurementsList.splice(index, 1);
  }

  setBenchmarkHalfMeasurement(value, index) {
    for (let measurement of this.product.benchmarkMeasurementsList[index]) {
      measurement.isHalfMeasurement = value;
    }
  }

  cantAddCore() {
    return !this.selectedCoreItem || this.product.coreMeasurementsList.findIndex(m =>
      m.length && m[0].measurementId && m[0].measurementId === this.selectedCoreItem
    ) !== -1;
  }

  addCore() {
    this.productModelService.addNewItemToCoreList(this.selectedCoreItem, this.product)
      .finally(() => this.selectedCoreItem = -1)
  }

  removeCore(index) {
    this.product.coreMeasurementsList.splice(index, 1);
  }

  setCoreHalfMeasurement(value, index) {
    for (let measurement of this.product.coreMeasurementsList[index]) {
      measurement.isHalfMeasurement = value;
    }
    // this.product.coreMeasurementsList[index] = this.product.coreMeasurementsList[index]
    //   .map(size => ({ ...size, isHalfMeasurement:value }))
  }

  async onSave() {
    this.processing = true;
    try {
        const updatedProduct = await this.product.update();

        this.router.navigate(['/product', updatedProduct.id, 'fit-parameters']);
    } catch (err) {
        console.error(err);
    } finally {
        this.processing = false;
    }
  }

  requiredFields() {
    if (this.productModelService.activeProduct.benchmarkMeasurementsList.length == 0) {
      console.log("Benchmark measurements are empty.");
      return false;
    }

    if (this.productModelService.activeProduct.coreMeasurementsList.length == 0) {
      console.log("Core measurements are empty.");
      return false;
    }

//     for (var item of this.productModelService.activeProduct.benchmarkMeasurementsList) {
//     console.log(this.productModelService.activeProduct.benchmarkMeasurementsList)
//       for (var element of item) {
//         if (element.value == undefined || element.value <= 0) {
//           console.log("Invalid benchmark value detected:", element.value);
//           return false;
//         }
//       }
//     }

//     for (var item of this.productModelService.activeProduct.coreMeasurementsList) {
//       for (var i = 0; i < item.length; i++) {
//         if (item[i].value == undefined || item[i].value <= 0) {
//           console.log("Invalid core value detected:", item[i].value);
//           return false;
//         }
//
//         if (i + 1 != item.length) {
//           if (item[i].gradeRule == undefined) {
//             console.log("Missing grade rule detected for core.");
//             return false;
//           }
//         }
//       }
//     }

    return true;
  }

  onCoreCalcValue(i, j) {
    let previousMeasurement = this.product.coreMeasurementsList[i][j].value;
    if(this.product.coreMeasurementsList[i][j+1] != undefined &&
       this.product.coreMeasurementsList[i][j+1].value != undefined &&
       this.product.coreMeasurementsList[i][j+1].value > 0 &&
       !this.product.coreMeasurementsList[i][j].gradeRuleEdited){
       this.product.coreMeasurementsList[i][j].gradeRule = parseFloat((this.product.coreMeasurementsList[i][j+1].value - previousMeasurement).toFixed(2));
    }

    let nextMeasurement = this.product.coreMeasurementsList[i][j].value;
    if(this.product.coreMeasurementsList[i][j-1] != undefined &&
      this.product.coreMeasurementsList[i][j-1].value != undefined &&
      this.product.coreMeasurementsList[i][j-1].value > 0 &&
      !this.product.coreMeasurementsList[i][j-1].gradeRuleEdited){
      this.product.coreMeasurementsList[i][j-1].gradeRule = parseFloat((nextMeasurement - this.product.coreMeasurementsList[i][j-1].value).toFixed(2));
    }
  }

  onCoreCalcGradeRule(i, j) {
    if(this.product.coreMeasurementsList[i][j].gradeRule != undefined){
      this.product.coreMeasurementsList[i][j].gradeRuleEdited = true;
    }
    else {
      this.product.coreMeasurementsList[i][j].gradeRuleEdited = false;
      this.onCoreCalcValue(i,j);
    }
  }

  removeBenchmarkMeasurementMsg() {
    this.showBenchmarkProductMeasurementMsg = false;
    if (this.doNotShowBenchmarkMeasurementsMsg) {
      this.localStorageService.setItem('dontShowBenchmarkMsg', true);
    }
  }

  removeCoreMeasurementMsg() {
    this.showCoreProductMeasurementMsg = false;
    if (this.doNotShowCoreMeasurementsMsg) {
      this.localStorageService.setItem('dontShowCoreMsg', true);
    }
  }

}
