/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type User = {
  __typename: "User",
  id?: string | null,
  userName?: string | null,
  nameFirst?: string | null,
  nameLast?: string | null,
  email?: string | null,
  phone?: string | null,
  memberships?:  Array<Membership > | null,
};

export type Membership = {
  __typename: "Membership",
  id: string,
  user?: User | null,
  organization?: Organization | null,
  role?: Role | null,
  bodyMeasurementsComplete?: boolean | null,
  fitSessionComplete?: boolean | null,
  createdAt?: string | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name?: string | null,
  contactName?: string | null,
  contactPhone?: string | null,
  contactEmail?: string | null,
  notes?: string | null,
  organizationType?: OrganizationType | null,
  customerCount?: number | null,
  productCount?: number | null,
  userCount?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdBy?: User | null,
  updatedBy?: User | null,
};

export type OrganizationType = {
  __typename: "OrganizationType",
  id?: string | null,
  name?: string | null,
  description?: string | null,
};

export type Role = {
  __typename: "Role",
  id: string,
  name?: string | null,
  description?: string | null,
};

export type FilterInput = {
  conjunction?: Conjunction | null,
  conditions?: Array< FilterCondition | null > | null,
  filterGroups?: Array< FilterInput | null > | null,
};

export enum Conjunction {
  AND = "AND",
  OR = "OR",
}


export type FilterCondition = {
  field: string,
  operator: Condition,
  value: string,
};

export enum Condition {
  EQUAL = "EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
  SMALLER_THAN = "SMALLER_THAN",
  SMALLER_THAN_OR_EQUAL = "SMALLER_THAN_OR_EQUAL",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
  IN = "IN",
  NOT_IN = "NOT_IN",
  LIKE = "LIKE",
  NOT_LIKE = "NOT_LIKE",
  BETWEEN = "BETWEEN",
  NOT_BETWEEN = "NOT_BETWEEN",
  IS_NULL = "IS_NULL",
  IS_NOT_NULL = "IS_NOT_NULL",
}


export type Product = {
  __typename: "Product",
  id: string,
  name?: string | null,
  sku?: string | null,
  description?: string | null,
  baseUnitPrice?: number | null,
  brand?: Brand | null,
  gender?: Gender | null,
  productType?: ProductType | null,
  productStatus?: ProductStatus | null,
  clients?:  Array<Organization | null > | null,
  sizes?:  Array<ProductSize | null > | null,
  benchmarkMeasurements?:  Array<ProductMeasurement | null > | null,
  coreMeasurements?:  Array<ProductMeasurement | null > | null,
  fitForm?: FitForm | null,
  idealSize?: Size | null,
  loosePreferenceSize?: Size | null,
  tightPreferenceSize?: Size | null,
  fitParameters?:  Array<ProductFitParameter | null > | null,
  images?:  Array<ProductImage | null > | null,
};

export type Brand = {
  __typename: "Brand",
  id?: string | null,
  name?: string | null,
  imageUrl?: string | null,
};

export type Gender = {
  __typename: "Gender",
  id?: string | null,
  name?: string | null,
  description?: string | null,
};

export type ProductType = {
  __typename: "ProductType",
  id?: string | null,
  name?: string | null,
  description?: string | null,
};

export type ProductStatus = {
  __typename: "ProductStatus",
  id?: string | null,
  name?: string | null,
  description?: string | null,
};

export type ProductSize = {
  __typename: "ProductSize",
  productId?: string | null,
  sizeId?: string | null,
  product?: Product | null,
  size?: Size | null,
};

export type Size = {
  __typename: "Size",
  id?: string | null,
  shortName?: string | null,
  longName?: string | null,
  rank?: number | null,
};

export type ProductMeasurement = {
  __typename: "ProductMeasurement",
  id?: string | null,
  product?: Product | null,
  size?: Size | null,
  measurement?: Measurement | null,
  isBenchmarkMeasurement?: boolean | null,
  value?: number | null,
  gradeRule?: number | null,
  isHalfMeasurement?: boolean | null,
};

export type Measurement = {
  __typename: "Measurement",
  id?: string | null,
  name?: string | null,
  description?: string | null,
  measurementType?: MeasurementType | null,
};

export type MeasurementType = {
  __typename: "MeasurementType",
  id?: string | null,
  name?: string | null,
  description?: string | null,
};

export type FitForm = {
  __typename: "FitForm",
  id?: string | null,
  name?: string | null,
  gender?: Gender | null,
  fitFormTypeId?: number | null,
  fitFormType?: FitFormType | null,
};

export type FitFormType = {
  __typename: "FitFormType",
  id?: string | null,
  name?: string | null,
  description?: string | null,
};

export type ProductFitParameter = {
  __typename: "ProductFitParameter",
  id?: string | null,
  product?: Product | null,
  measurement?: Measurement | null,
  idealSizeAdjustment?: number | null,
  looseSizeAdjustment?: number | null,
  tightSizeAdjustment?: number | null,
  isFitPoint?: boolean | null,
  fitWeighting?: number | null,
};

export type ProductImage = {
  __typename: "ProductImage",
  id?: string | null,
  product?: Product | null,
  productImageType?: ProductImageType | null,
  imageKey?: string | null,
  imageUrl?: string | null,
  color?: Color | null,
};

export type ProductImageType = {
  __typename: "ProductImageType",
  id?: string | null,
  name?: string | null,
  description?: string | null,
};

export type Color = {
  __typename: "Color",
  id?: string | null,
  name?: string | null,
  description?: string | null,
  code?: string | null,
};

export type UserInput = {
  userName?: string | null,
  nameFirst?: string | null,
  nameLast?: string | null,
  email?: string | null,
  phone?: string | null,
};

export type ProductInput = {
  name?: string | null,
  brandId?: string | null,
  sku?: string | null,
  description?: string | null,
  baseUnitPrice?: number | null,
  genderId?: string | null,
  productStatusId?: string | null,
  productTypeId?: string | null,
  fitFormId?: string | null,
  idealSizeId?: string | null,
  loosePreferenceSizeId?: string | null,
  tightPreferenceSizeId?: string | null,
};

export type ProductSizeInput = {
  productId: string,
  sizeId: string,
};

export type ProductMeasurementInput = {
  productId: string,
  measurementId: string,
  sizeId: string,
  isBenchmarkMeasurement?: boolean | null,
  isHalfMeasurement?: boolean | null,
  value?: number | null,
  gradeRule?: number | null,
};

export type ProductFitParameterInput = {
  productId: string,
  measurementId: string,
  idealSizeAdjustment?: number | null,
  looseSizeAdjustment?: number | null,
  tightSizeAdjustment?: number | null,
  isFitPoint?: boolean | null,
  fitWeighting?: number | null,
};

export type OrganizationProductInput = {
  productId: string,
  organizationId: string,
};

export type OrganizationProduct = {
  __typename: "OrganizationProduct",
  productId: string,
  organizationId: string,
  product?: Product | null,
  organization?: Organization | null,
};

export type ProductImageInput = {
  productId: string,
  productImageTypeId: string,
  imageKey?: string | null,
  imageUrl?: string | null,
  colorId?: string | null,
};

export type Customer = {
  __typename: "Customer",
  id?: string | null,
  nameFirst?: string | null,
  nameLast?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  postalCode?: string | null,
  gender?: Gender | null,
  createdAt?: string | null,
  bodyMeasurementsComplete?: boolean | null,
  fitSessionCompleted?: boolean | null,
  country?: Country | null,
  user?: User | null,
  organization?: Organization | null,
  sizeStreamAccountId?: string | null,
};

export type Country = {
  __typename: "Country",
  id?: string | null,
  code?: string | null,
  description?: string | null,
  continent?: string | null,
};

export type CustomerInput = {
  nameFirst?: string | null,
  nameLast?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  postalCode?: string | null,
  genderId?: string | null,
  bodyMeasurementsComplete?: boolean | null,
  fitSessionCompleted?: boolean | null,
  countryId?: string | null,
  userId?: string | null,
  organizationId?: string | null,
  sizeStreamAccountId?: string | null,
};

export type CustomerMeasurement = {
  __typename: "CustomerMeasurement",
  customer?: Customer | null,
  measurement?: Measurement | null,
  value?: number | null,
};

export type OrganizationInput = {
  name?: string | null,
  contactName?: string | null,
  contactPhone?: string | null,
  contactEmail?: string | null,
  notes?: string | null,
  organizationTypeId?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  createdById?: number | null,
  updatedById?: number | null,
};

export type DeletionResponse = {
  __typename: "DeletionResponse",
  id: string,
};

export type ProductSizeDeletionResponse = {
  __typename: "ProductSizeDeletionResponse",
  productId: string,
  sizeId: string,
};

export type FileObject = {
  __typename: "FileObject",
  downloadUrl?: string | null,
  uploadUrl?: string | null,
  fileKey?: string | null,
};

export type BrandInput = {
  name?: string | null,
};

export type ProductTypeInput = {
  name: string,
  description?: string | null,
};

export type OrganizationProductDeletionResponse = {
  __typename: "OrganizationProductDeletionResponse",
  organizationId: string,
  productId: string,
};

export type ColorInput = {
  name: string,
  description?: string | null,
  code?: string | null,
};

export type ProductImageTypeInput = {
  name: string,
  description?: string | null,
};

export type FitSessionResultInput = {
  customerId: string,
  productId: string,
  sizeId: string,
  measurementId: string,
  fitTechnicianNormalizedScore: number,
  isValid: boolean,
};

export type FitSessionResult = {
  __typename: "FitSessionResult",
  id: string,
  customerId?: string | null,
  productId?: string | null,
  sizeId?: string | null,
  measurementId?: string | null,
  fitTechnicianNormalizedScore?: number | null,
  fitTechnicianInputDate?: string | null,
  isValid?: boolean | null,
};

export type CustomerMeasurementImportResult = {
  __typename: "CustomerMeasurementImportResult",
  measurementsUpdated?: number | null,
};

export type Result = {
  __typename: "Result",
  id: string,
  status: ResultStatus,
};

export enum ResultStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}


export type ScannerUserInput = {
  phoneNumber: string,
  nameFirst: string,
  nameLast: string,
};

export type SessionTokenInput = {
  phoneNumber: string,
  sessionToken: string,
};

export type ScannerUserResponse = {
  __typename: "ScannerUserResponse",
  phoneNumber?: string | null,
  nameFirst?: string | null,
  nameLast?: string | null,
  id?: string | null,
};

export type PinpointResponse = {
  __typename: "PinpointResponse",
  success?: boolean | null,
};

export type CreateSessionTokenResponse = {
  __typename: "CreateSessionTokenResponse",
  sessionId: string,
};

export type SessionTokenFromOTPResponse = {
  __typename: "SessionTokenFromOTPResponse",
  success?: boolean | null,
  token?: string | null,
};

export type CustomerMeasurementUpdateResult = {
  __typename: "CustomerMeasurementUpdateResult",
  measurementsUpdated?: number | null,
};

export type FitSessionMeasurementUpdate = {
  customerID: string,
  measurementId: string,
};

export type FitSessionMeasurementUpdateResult = {
  __typename: "FitSessionMeasurementUpdateResult",
  updatedValue?: number | null,
  success?: boolean | null,
  message?: string | null,
};

export type FitOptions = {
  debug?: boolean | null,
  useManualMeasurements?: boolean | null,
  fitPreferences?: Array< ProductFitPreferenceInput | null > | null,
};

export type ProductFitPreferenceInput = {
  productId: string,
  fitPreferenceId?: string | null,
  preferredMeasurementId?: string | null,
};

export type FitRecommendationResult = {
  __typename: "FitRecommendationResult",
  productFitRecommendations?:  Array<ProductFitRecommendation | null > | null,
  input?: string | null,
};

export type ProductFitRecommendation = {
  __typename: "ProductFitRecommendation",
  productId?: string | null,
  success?: boolean | null,
  errorMessage?: string | null,
  recommendation?: FitRecommendation | null,
  weightedScores?:  Array<FitWeightedScore | null > | null,
  weightedScoreBreakdown?:  Array<FitWeightedScoreBreakdown | null > | null,
};

export type FitRecommendation = {
  __typename: "FitRecommendation",
  size?: string | null,
  weightedScore?: number | null,
  sizeByFitPreference?: string | null,
  sizeByTopThreeSelectivePreference?: string | null,
  sizeByVariableSelectivePreference?: string | null,
  sizeByFitPointPreference?: string | null,
};

export type FitWeightedScore = {
  __typename: "FitWeightedScore",
  size?: string | null,
  weightedScore?: number | null,
};

export type FitWeightedScoreBreakdown = {
  __typename: "FitWeightedScoreBreakdown",
  size?: string | null,
  scoreBreakdown?:  Array<FitPointWeightedScore | null > | null,
};

export type FitPointWeightedScore = {
  __typename: "FitPointWeightedScore",
  fitPoint?: number | null,
  value?: number | null,
  weightedScore?: number | null,
  normalizedScore?: number | null,
};

export type ScanExistsResponse = {
  __typename: "ScanExistsResponse",
  exists?: boolean | null,
  scan?: string | null,
};

export type GetBodyModelUrlResponse = {
  __typename: "GetBodyModelUrlResponse",
  url?: string | null,
};

export type ScanCoreMeasuresResponse = {
  __typename: "ScanCoreMeasuresResponse",
  measures?: string | null,
};

export type PhoneVerificationResponse = {
  __typename: "PhoneVerificationResponse",
  phoneNumberRecordExists?: boolean | null,
};

export type VerifySessionTokenResponse = {
  __typename: "VerifySessionTokenResponse",
  valid: boolean,
};

export type FitRecommendationAnalysisResponse = {
  __typename: "FitRecommendationAnalysisResponse",
  output?: string | null,
};

export type UserWithRoleQueryVariables = {
  id?: string | null,
  email?: string | null,
};

export type UserWithRoleQuery = {
  user?:  {
    __typename: "User",
    id?: string | null,
    userName?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phone?: string | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      role?:  {
        __typename: "Role",
        name?: string | null,
        description?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
      } | null,
    } > | null,
  } | null,
};

export type ProductsListingQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type ProductsListingQuery = {
  products:  Array< {
    __typename: "Product",
    id: string,
    name?: string | null,
    sku?: string | null,
    description?: string | null,
    baseUnitPrice?: number | null,
    brand?:  {
      __typename: "Brand",
      id?: string | null,
      name?: string | null,
    } | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productStatus?:  {
      __typename: "ProductStatus",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    clients?:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      createdAt?: string | null,
    } | null > | null,
  } >,
};

export type ProductsListingWImagesQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type ProductsListingWImagesQuery = {
  products:  Array< {
    __typename: "Product",
    id: string,
    name?: string | null,
    sku?: string | null,
    description?: string | null,
    baseUnitPrice?: number | null,
    brand?:  {
      __typename: "Brand",
      id?: string | null,
      name?: string | null,
    } | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productStatus?:  {
      __typename: "ProductStatus",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    clients?:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      createdAt?: string | null,
    } | null > | null,
    images?:  Array< {
      __typename: "ProductImage",
      id?: string | null,
      imageKey?: string | null,
      imageUrl?: string | null,
    } | null > | null,
  } >,
};

export type ProductCustomQueryVariables = {
  id: string,
};

export type ProductCustomQuery = {
  product?:  {
    __typename: "Product",
    id: string,
    name?: string | null,
    sku?: string | null,
    description?: string | null,
    baseUnitPrice?: number | null,
    brand?:  {
      __typename: "Brand",
      id?: string | null,
      name?: string | null,
    } | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productStatus?:  {
      __typename: "ProductStatus",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    clients?:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      createdAt?: string | null,
    } | null > | null,
    sizes?:  Array< {
      __typename: "ProductSize",
      productId?: string | null,
      sizeId?: string | null,
    } | null > | null,
    benchmarkMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
      } | null,
    } | null > | null,
    coreMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
      } | null,
    } | null > | null,
    idealSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
    } | null,
    loosePreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
    } | null,
    tightPreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
    } | null,
    fitParameters?:  Array< {
      __typename: "ProductFitParameter",
      id?: string | null,
      idealSizeAdjustment?: number | null,
      looseSizeAdjustment?: number | null,
      tightSizeAdjustment?: number | null,
      isFitPoint?: boolean | null,
      fitWeighting?: number | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
      } | null,
    } | null > | null,
    fitForm?:  {
      __typename: "FitForm",
      id?: string | null,
      name?: string | null,
      fitFormTypeId?: number | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
      } | null,
    } | null,
    images?:  Array< {
      __typename: "ProductImage",
      id?: string | null,
      imageKey?: string | null,
      imageUrl?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
      } | null,
      productImageType?:  {
        __typename: "ProductImageType",
        id?: string | null,
      } | null,
      color?:  {
        __typename: "Color",
        id?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type UpdateUserCustomMutationVariables = {
  id: string,
  input?: UserInput | null,
};

export type UpdateUserCustomMutation = {
  updateUser:  {
    __typename: "User",
    id?: string | null,
    userName?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phone?: string | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        createdAt?: string | null,
      } | null,
      role?:  {
        __typename: "Role",
        name?: string | null,
        description?: string | null,
      } | null,
    } > | null,
  },
};

export type CreateProductCustomMutationVariables = {
  input?: ProductInput | null,
};

export type CreateProductCustomMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    name?: string | null,
  } | null,
};

export type UpdateProductCustomMutationVariables = {
  id: string,
  input?: ProductInput | null,
};

export type UpdateProductCustomMutation = {
  updateProduct:  {
    __typename: "Product",
    id: string,
    name?: string | null,
    sku?: string | null,
    description?: string | null,
    baseUnitPrice?: number | null,
  },
};

export type CreateProductSizeCustomMutationVariables = {
  input?: ProductSizeInput | null,
};

export type CreateProductSizeCustomMutation = {
  createProductSize?:  {
    __typename: "ProductSize",
    productId?: string | null,
    sizeId?: string | null,
  } | null,
};

export type CloneProductCustomMutationVariables = {
  id: string,
};

export type CloneProductCustomMutation = {
  cloneProduct?:  {
    __typename: "Product",
    id: string,
  } | null,
};

export type CreateProductMeasurementCustomMutationVariables = {
  input?: ProductMeasurementInput | null,
};

export type CreateProductMeasurementCustomMutation = {
  createProductMeasurement?:  {
    __typename: "ProductMeasurement",
    id?: string | null,
    isBenchmarkMeasurement?: boolean | null,
    value?: number | null,
    gradeRule?: number | null,
    isHalfMeasurement?: boolean | null,
  } | null,
};

export type UpdateProductMeasurementCustomMutationVariables = {
  id: string,
  input?: ProductMeasurementInput | null,
};

export type UpdateProductMeasurementCustomMutation = {
  updateProductMeasurement:  {
    __typename: "ProductMeasurement",
    id?: string | null,
    isBenchmarkMeasurement?: boolean | null,
    value?: number | null,
    gradeRule?: number | null,
    isHalfMeasurement?: boolean | null,
  },
};

export type CreateProductFitParameterCustomMutationVariables = {
  input?: ProductFitParameterInput | null,
};

export type CreateProductFitParameterCustomMutation = {
  createProductFitParameter?:  {
    __typename: "ProductFitParameter",
    id?: string | null,
    idealSizeAdjustment?: number | null,
    looseSizeAdjustment?: number | null,
    tightSizeAdjustment?: number | null,
    isFitPoint?: boolean | null,
    fitWeighting?: number | null,
  } | null,
};

export type UpdateProductFitParameterCustomMutationVariables = {
  id: string,
  input?: ProductFitParameterInput | null,
};

export type UpdateProductFitParameterCustomMutation = {
  updateProductFitParameter:  {
    __typename: "ProductFitParameter",
    id?: string | null,
    idealSizeAdjustment?: number | null,
    looseSizeAdjustment?: number | null,
    tightSizeAdjustment?: number | null,
    isFitPoint?: boolean | null,
    fitWeighting?: number | null,
  },
};

export type CreateOrganizationProductCustomMutationVariables = {
  input?: OrganizationProductInput | null,
};

export type CreateOrganizationProductCustomMutation = {
  createOrganizationProduct?:  {
    __typename: "OrganizationProduct",
    productId: string,
    organizationId: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
    } | null,
  } | null,
};

export type UpdateProductImageCustomMutationVariables = {
  id: string,
  input?: ProductImageInput | null,
};

export type UpdateProductImageCustomMutation = {
  updateProductImage:  {
    __typename: "ProductImage",
    id?: string | null,
  },
};

export type CreateProductImageCustomMutationVariables = {
  input?: ProductImageInput | null,
};

export type CreateProductImageCustomMutation = {
  createProductImage?:  {
    __typename: "ProductImage",
    id?: string | null,
  } | null,
};

export type CustomersListQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
};

export type CustomersListQuery = {
  customers:  Array< {
    __typename: "Customer",
    id?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    createdAt?: string | null,
    bodyMeasurementsComplete?: boolean | null,
    fitSessionCompleted?: boolean | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
    } | null,
    country?:  {
      __typename: "Country",
      id?: string | null,
      code?: string | null,
      description?: string | null,
    } | null,
  } >,
};

export type CustomerDetailsQueryVariables = {
  id: string,
};

export type CustomerDetailsQuery = {
  customer?:  {
    __typename: "Customer",
    id?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    createdAt?: string | null,
    bodyMeasurementsComplete?: boolean | null,
    fitSessionCompleted?: boolean | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
    } | null,
    country?:  {
      __typename: "Country",
      id?: string | null,
      code?: string | null,
      description?: string | null,
    } | null,
  } | null,
};

export type CreateCustomerCustomMutationVariables = {
  input?: CustomerInput | null,
};

export type CreateCustomerCustomMutation = {
  createCustomer?:  {
    __typename: "Customer",
    id?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    createdAt?: string | null,
    bodyMeasurementsComplete?: boolean | null,
    fitSessionCompleted?: boolean | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
    } | null,
    country?:  {
      __typename: "Country",
      id?: string | null,
      code?: string | null,
      description?: string | null,
    } | null,
  } | null,
};

export type UpdateCustomerCustomMutationVariables = {
  id: string,
  input?: CustomerInput | null,
};

export type UpdateCustomerCustomMutation = {
  updateCustomer:  {
    __typename: "Customer",
    id?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    createdAt?: string | null,
    bodyMeasurementsComplete?: boolean | null,
    fitSessionCompleted?: boolean | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
    } | null,
    country?:  {
      __typename: "Country",
      id?: string | null,
      code?: string | null,
      description?: string | null,
    } | null,
  },
};

export type OrganizationsCustomQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type OrganizationsCustomQuery = {
  organizations:  Array< {
    __typename: "Organization",
    id: string,
    name?: string | null,
    contactName?: string | null,
    contactPhone?: string | null,
    contactEmail?: string | null,
    notes?: string | null,
    organizationType?:  {
      __typename: "OrganizationType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    customerCount?: number | null,
    productCount?: number | null,
    userCount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } >,
};

export type CustomerMeasurementsCustomQueryVariables = {
  customerId: string,
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type CustomerMeasurementsCustomQuery = {
  customerMeasurements:  Array< {
    __typename: "CustomerMeasurement",
    customer?:  {
      __typename: "Customer",
      id?: string | null,
    } | null,
    measurement?:  {
      __typename: "Measurement",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    value?: number | null,
  } >,
};

export type CreateOrganizationMutationVariables = {
  input?: OrganizationInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    contactName?: string | null,
    contactPhone?: string | null,
    contactEmail?: string | null,
    notes?: string | null,
    organizationType?:  {
      __typename: "OrganizationType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    customerCount?: number | null,
    productCount?: number | null,
    userCount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
    updatedBy?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  id: string,
  input?: OrganizationInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    contactName?: string | null,
    contactPhone?: string | null,
    contactEmail?: string | null,
    notes?: string | null,
    organizationType?:  {
      __typename: "OrganizationType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    customerCount?: number | null,
    productCount?: number | null,
    userCount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
    updatedBy?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
  },
};

export type DeleteOrganizationMutationVariables = {
  id: string,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "DeletionResponse",
    id: string,
  } | null,
};

export type CreateProductMutationVariables = {
  input?: ProductInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    name?: string | null,
    sku?: string | null,
    description?: string | null,
    baseUnitPrice?: number | null,
    brand?:  {
      __typename: "Brand",
      id?: string | null,
      name?: string | null,
      imageUrl?: string | null,
    } | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productStatus?:  {
      __typename: "ProductStatus",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    clients?:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null > | null,
    sizes?:  Array< {
      __typename: "ProductSize",
      productId?: string | null,
      sizeId?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
    } | null > | null,
    benchmarkMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
    } | null > | null,
    coreMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
    } | null > | null,
    fitForm?:  {
      __typename: "FitForm",
      id?: string | null,
      name?: string | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      fitFormTypeId?: number | null,
      fitFormType?:  {
        __typename: "FitFormType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
    } | null,
    idealSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    loosePreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    tightPreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    fitParameters?:  Array< {
      __typename: "ProductFitParameter",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSizeAdjustment?: number | null,
      looseSizeAdjustment?: number | null,
      tightSizeAdjustment?: number | null,
      isFitPoint?: boolean | null,
      fitWeighting?: number | null,
    } | null > | null,
    images?:  Array< {
      __typename: "ProductImage",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      productImageType?:  {
        __typename: "ProductImageType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      imageKey?: string | null,
      imageUrl?: string | null,
      color?:  {
        __typename: "Color",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        code?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type UpdateProductMutationVariables = {
  id: string,
  input?: ProductInput | null,
};

export type UpdateProductMutation = {
  updateProduct:  {
    __typename: "Product",
    id: string,
    name?: string | null,
    sku?: string | null,
    description?: string | null,
    baseUnitPrice?: number | null,
    brand?:  {
      __typename: "Brand",
      id?: string | null,
      name?: string | null,
      imageUrl?: string | null,
    } | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productStatus?:  {
      __typename: "ProductStatus",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    clients?:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null > | null,
    sizes?:  Array< {
      __typename: "ProductSize",
      productId?: string | null,
      sizeId?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
    } | null > | null,
    benchmarkMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
    } | null > | null,
    coreMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
    } | null > | null,
    fitForm?:  {
      __typename: "FitForm",
      id?: string | null,
      name?: string | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      fitFormTypeId?: number | null,
      fitFormType?:  {
        __typename: "FitFormType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
    } | null,
    idealSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    loosePreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    tightPreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    fitParameters?:  Array< {
      __typename: "ProductFitParameter",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSizeAdjustment?: number | null,
      looseSizeAdjustment?: number | null,
      tightSizeAdjustment?: number | null,
      isFitPoint?: boolean | null,
      fitWeighting?: number | null,
    } | null > | null,
    images?:  Array< {
      __typename: "ProductImage",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      productImageType?:  {
        __typename: "ProductImageType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      imageKey?: string | null,
      imageUrl?: string | null,
      color?:  {
        __typename: "Color",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        code?: string | null,
      } | null,
    } | null > | null,
  },
};

export type DeleteProductMutationVariables = {
  id: string,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "DeletionResponse",
    id: string,
  } | null,
};

export type CloneProductMutationVariables = {
  id: string,
};

export type CloneProductMutation = {
  cloneProduct?:  {
    __typename: "Product",
    id: string,
    name?: string | null,
    sku?: string | null,
    description?: string | null,
    baseUnitPrice?: number | null,
    brand?:  {
      __typename: "Brand",
      id?: string | null,
      name?: string | null,
      imageUrl?: string | null,
    } | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productStatus?:  {
      __typename: "ProductStatus",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    clients?:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null > | null,
    sizes?:  Array< {
      __typename: "ProductSize",
      productId?: string | null,
      sizeId?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
    } | null > | null,
    benchmarkMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
    } | null > | null,
    coreMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
    } | null > | null,
    fitForm?:  {
      __typename: "FitForm",
      id?: string | null,
      name?: string | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      fitFormTypeId?: number | null,
      fitFormType?:  {
        __typename: "FitFormType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
    } | null,
    idealSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    loosePreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    tightPreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    fitParameters?:  Array< {
      __typename: "ProductFitParameter",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSizeAdjustment?: number | null,
      looseSizeAdjustment?: number | null,
      tightSizeAdjustment?: number | null,
      isFitPoint?: boolean | null,
      fitWeighting?: number | null,
    } | null > | null,
    images?:  Array< {
      __typename: "ProductImage",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      productImageType?:  {
        __typename: "ProductImageType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      imageKey?: string | null,
      imageUrl?: string | null,
      color?:  {
        __typename: "Color",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        code?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type CreateProductSizeMutationVariables = {
  input?: ProductSizeInput | null,
};

export type CreateProductSizeMutation = {
  createProductSize?:  {
    __typename: "ProductSize",
    productId?: string | null,
    sizeId?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      name?: string | null,
      sku?: string | null,
      description?: string | null,
      baseUnitPrice?: number | null,
      brand?:  {
        __typename: "Brand",
        id?: string | null,
        name?: string | null,
        imageUrl?: string | null,
      } | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productStatus?:  {
        __typename: "ProductStatus",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      clients?:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null > | null,
      sizes?:  Array< {
        __typename: "ProductSize",
        productId?: string | null,
        sizeId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
      } | null > | null,
      benchmarkMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      coreMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      fitForm?:  {
        __typename: "FitForm",
        id?: string | null,
        name?: string | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        fitFormTypeId?: number | null,
        fitFormType?:  {
          __typename: "FitFormType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      loosePreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      tightPreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      fitParameters?:  Array< {
        __typename: "ProductFitParameter",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        idealSizeAdjustment?: number | null,
        looseSizeAdjustment?: number | null,
        tightSizeAdjustment?: number | null,
        isFitPoint?: boolean | null,
        fitWeighting?: number | null,
      } | null > | null,
      images?:  Array< {
        __typename: "ProductImage",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        productImageType?:  {
          __typename: "ProductImageType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        imageKey?: string | null,
        imageUrl?: string | null,
        color?:  {
          __typename: "Color",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          code?: string | null,
        } | null,
      } | null > | null,
    } | null,
    size?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
  } | null,
};

export type DeleteProductSizeMutationVariables = {
  productId: string,
  sizeId: string,
};

export type DeleteProductSizeMutation = {
  deleteProductSize?:  {
    __typename: "ProductSizeDeletionResponse",
    productId: string,
    sizeId: string,
  } | null,
};

export type CreateProductImageMutationVariables = {
  input?: ProductImageInput | null,
};

export type CreateProductImageMutation = {
  createProductImage?:  {
    __typename: "ProductImage",
    id?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      name?: string | null,
      sku?: string | null,
      description?: string | null,
      baseUnitPrice?: number | null,
      brand?:  {
        __typename: "Brand",
        id?: string | null,
        name?: string | null,
        imageUrl?: string | null,
      } | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productStatus?:  {
        __typename: "ProductStatus",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      clients?:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null > | null,
      sizes?:  Array< {
        __typename: "ProductSize",
        productId?: string | null,
        sizeId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
      } | null > | null,
      benchmarkMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      coreMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      fitForm?:  {
        __typename: "FitForm",
        id?: string | null,
        name?: string | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        fitFormTypeId?: number | null,
        fitFormType?:  {
          __typename: "FitFormType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      loosePreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      tightPreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      fitParameters?:  Array< {
        __typename: "ProductFitParameter",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        idealSizeAdjustment?: number | null,
        looseSizeAdjustment?: number | null,
        tightSizeAdjustment?: number | null,
        isFitPoint?: boolean | null,
        fitWeighting?: number | null,
      } | null > | null,
      images?:  Array< {
        __typename: "ProductImage",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        productImageType?:  {
          __typename: "ProductImageType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        imageKey?: string | null,
        imageUrl?: string | null,
        color?:  {
          __typename: "Color",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          code?: string | null,
        } | null,
      } | null > | null,
    } | null,
    productImageType?:  {
      __typename: "ProductImageType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    imageKey?: string | null,
    imageUrl?: string | null,
    color?:  {
      __typename: "Color",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      code?: string | null,
    } | null,
  } | null,
};

export type UpdateProductImageMutationVariables = {
  id: string,
  input?: ProductImageInput | null,
};

export type UpdateProductImageMutation = {
  updateProductImage:  {
    __typename: "ProductImage",
    id?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      name?: string | null,
      sku?: string | null,
      description?: string | null,
      baseUnitPrice?: number | null,
      brand?:  {
        __typename: "Brand",
        id?: string | null,
        name?: string | null,
        imageUrl?: string | null,
      } | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productStatus?:  {
        __typename: "ProductStatus",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      clients?:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null > | null,
      sizes?:  Array< {
        __typename: "ProductSize",
        productId?: string | null,
        sizeId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
      } | null > | null,
      benchmarkMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      coreMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      fitForm?:  {
        __typename: "FitForm",
        id?: string | null,
        name?: string | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        fitFormTypeId?: number | null,
        fitFormType?:  {
          __typename: "FitFormType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      loosePreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      tightPreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      fitParameters?:  Array< {
        __typename: "ProductFitParameter",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        idealSizeAdjustment?: number | null,
        looseSizeAdjustment?: number | null,
        tightSizeAdjustment?: number | null,
        isFitPoint?: boolean | null,
        fitWeighting?: number | null,
      } | null > | null,
      images?:  Array< {
        __typename: "ProductImage",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        productImageType?:  {
          __typename: "ProductImageType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        imageKey?: string | null,
        imageUrl?: string | null,
        color?:  {
          __typename: "Color",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          code?: string | null,
        } | null,
      } | null > | null,
    } | null,
    productImageType?:  {
      __typename: "ProductImageType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    imageKey?: string | null,
    imageUrl?: string | null,
    color?:  {
      __typename: "Color",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      code?: string | null,
    } | null,
  },
};

export type DeleteProductImageMutationVariables = {
  id: string,
};

export type DeleteProductImageMutation = {
  deleteProductImage?:  {
    __typename: "DeletionResponse",
    id: string,
  } | null,
};

export type ManageProductImageFileMutationVariables = {
  productId: string,
  imageKey: string,
};

export type ManageProductImageFileMutation = {
  manageProductImageFile?:  {
    __typename: "FileObject",
    downloadUrl?: string | null,
    uploadUrl?: string | null,
    fileKey?: string | null,
  } | null,
};

export type CreateProductMeasurementMutationVariables = {
  input?: ProductMeasurementInput | null,
};

export type CreateProductMeasurementMutation = {
  createProductMeasurement?:  {
    __typename: "ProductMeasurement",
    id?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      name?: string | null,
      sku?: string | null,
      description?: string | null,
      baseUnitPrice?: number | null,
      brand?:  {
        __typename: "Brand",
        id?: string | null,
        name?: string | null,
        imageUrl?: string | null,
      } | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productStatus?:  {
        __typename: "ProductStatus",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      clients?:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null > | null,
      sizes?:  Array< {
        __typename: "ProductSize",
        productId?: string | null,
        sizeId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
      } | null > | null,
      benchmarkMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      coreMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      fitForm?:  {
        __typename: "FitForm",
        id?: string | null,
        name?: string | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        fitFormTypeId?: number | null,
        fitFormType?:  {
          __typename: "FitFormType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      loosePreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      tightPreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      fitParameters?:  Array< {
        __typename: "ProductFitParameter",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        idealSizeAdjustment?: number | null,
        looseSizeAdjustment?: number | null,
        tightSizeAdjustment?: number | null,
        isFitPoint?: boolean | null,
        fitWeighting?: number | null,
      } | null > | null,
      images?:  Array< {
        __typename: "ProductImage",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        productImageType?:  {
          __typename: "ProductImageType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        imageKey?: string | null,
        imageUrl?: string | null,
        color?:  {
          __typename: "Color",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          code?: string | null,
        } | null,
      } | null > | null,
    } | null,
    size?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    measurement?:  {
      __typename: "Measurement",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      measurementType?:  {
        __typename: "MeasurementType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
    } | null,
    isBenchmarkMeasurement?: boolean | null,
    value?: number | null,
    gradeRule?: number | null,
    isHalfMeasurement?: boolean | null,
  } | null,
};

export type UpdateProductMeasurementMutationVariables = {
  id: string,
  input?: ProductMeasurementInput | null,
};

export type UpdateProductMeasurementMutation = {
  updateProductMeasurement:  {
    __typename: "ProductMeasurement",
    id?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      name?: string | null,
      sku?: string | null,
      description?: string | null,
      baseUnitPrice?: number | null,
      brand?:  {
        __typename: "Brand",
        id?: string | null,
        name?: string | null,
        imageUrl?: string | null,
      } | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productStatus?:  {
        __typename: "ProductStatus",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      clients?:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null > | null,
      sizes?:  Array< {
        __typename: "ProductSize",
        productId?: string | null,
        sizeId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
      } | null > | null,
      benchmarkMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      coreMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      fitForm?:  {
        __typename: "FitForm",
        id?: string | null,
        name?: string | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        fitFormTypeId?: number | null,
        fitFormType?:  {
          __typename: "FitFormType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      loosePreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      tightPreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      fitParameters?:  Array< {
        __typename: "ProductFitParameter",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        idealSizeAdjustment?: number | null,
        looseSizeAdjustment?: number | null,
        tightSizeAdjustment?: number | null,
        isFitPoint?: boolean | null,
        fitWeighting?: number | null,
      } | null > | null,
      images?:  Array< {
        __typename: "ProductImage",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        productImageType?:  {
          __typename: "ProductImageType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        imageKey?: string | null,
        imageUrl?: string | null,
        color?:  {
          __typename: "Color",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          code?: string | null,
        } | null,
      } | null > | null,
    } | null,
    size?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    measurement?:  {
      __typename: "Measurement",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      measurementType?:  {
        __typename: "MeasurementType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
    } | null,
    isBenchmarkMeasurement?: boolean | null,
    value?: number | null,
    gradeRule?: number | null,
    isHalfMeasurement?: boolean | null,
  },
};

export type DeleteProductMeasurementMutationVariables = {
  id: string,
};

export type DeleteProductMeasurementMutation = {
  deleteProductMeasurement?:  {
    __typename: "DeletionResponse",
    id: string,
  } | null,
};

export type CreateProductFitParameterMutationVariables = {
  input?: ProductFitParameterInput | null,
};

export type CreateProductFitParameterMutation = {
  createProductFitParameter?:  {
    __typename: "ProductFitParameter",
    id?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      name?: string | null,
      sku?: string | null,
      description?: string | null,
      baseUnitPrice?: number | null,
      brand?:  {
        __typename: "Brand",
        id?: string | null,
        name?: string | null,
        imageUrl?: string | null,
      } | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productStatus?:  {
        __typename: "ProductStatus",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      clients?:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null > | null,
      sizes?:  Array< {
        __typename: "ProductSize",
        productId?: string | null,
        sizeId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
      } | null > | null,
      benchmarkMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      coreMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      fitForm?:  {
        __typename: "FitForm",
        id?: string | null,
        name?: string | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        fitFormTypeId?: number | null,
        fitFormType?:  {
          __typename: "FitFormType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      loosePreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      tightPreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      fitParameters?:  Array< {
        __typename: "ProductFitParameter",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        idealSizeAdjustment?: number | null,
        looseSizeAdjustment?: number | null,
        tightSizeAdjustment?: number | null,
        isFitPoint?: boolean | null,
        fitWeighting?: number | null,
      } | null > | null,
      images?:  Array< {
        __typename: "ProductImage",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        productImageType?:  {
          __typename: "ProductImageType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        imageKey?: string | null,
        imageUrl?: string | null,
        color?:  {
          __typename: "Color",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          code?: string | null,
        } | null,
      } | null > | null,
    } | null,
    measurement?:  {
      __typename: "Measurement",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      measurementType?:  {
        __typename: "MeasurementType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
    } | null,
    idealSizeAdjustment?: number | null,
    looseSizeAdjustment?: number | null,
    tightSizeAdjustment?: number | null,
    isFitPoint?: boolean | null,
    fitWeighting?: number | null,
  } | null,
};

export type UpdateProductFitParameterMutationVariables = {
  id: string,
  input?: ProductFitParameterInput | null,
};

export type UpdateProductFitParameterMutation = {
  updateProductFitParameter:  {
    __typename: "ProductFitParameter",
    id?: string | null,
    product?:  {
      __typename: "Product",
      id: string,
      name?: string | null,
      sku?: string | null,
      description?: string | null,
      baseUnitPrice?: number | null,
      brand?:  {
        __typename: "Brand",
        id?: string | null,
        name?: string | null,
        imageUrl?: string | null,
      } | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productStatus?:  {
        __typename: "ProductStatus",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      clients?:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null > | null,
      sizes?:  Array< {
        __typename: "ProductSize",
        productId?: string | null,
        sizeId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
      } | null > | null,
      benchmarkMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      coreMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      fitForm?:  {
        __typename: "FitForm",
        id?: string | null,
        name?: string | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        fitFormTypeId?: number | null,
        fitFormType?:  {
          __typename: "FitFormType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      loosePreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      tightPreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      fitParameters?:  Array< {
        __typename: "ProductFitParameter",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        idealSizeAdjustment?: number | null,
        looseSizeAdjustment?: number | null,
        tightSizeAdjustment?: number | null,
        isFitPoint?: boolean | null,
        fitWeighting?: number | null,
      } | null > | null,
      images?:  Array< {
        __typename: "ProductImage",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        productImageType?:  {
          __typename: "ProductImageType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        imageKey?: string | null,
        imageUrl?: string | null,
        color?:  {
          __typename: "Color",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          code?: string | null,
        } | null,
      } | null > | null,
    } | null,
    measurement?:  {
      __typename: "Measurement",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      measurementType?:  {
        __typename: "MeasurementType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
    } | null,
    idealSizeAdjustment?: number | null,
    looseSizeAdjustment?: number | null,
    tightSizeAdjustment?: number | null,
    isFitPoint?: boolean | null,
    fitWeighting?: number | null,
  },
};

export type DeleteProductFitParameterMutationVariables = {
  id: string,
};

export type DeleteProductFitParameterMutation = {
  deleteProductFitParameter?:  {
    __typename: "DeletionResponse",
    id: string,
  } | null,
};

export type CreateBrandMutationVariables = {
  input?: BrandInput | null,
};

export type CreateBrandMutation = {
  createBrand?:  {
    __typename: "Brand",
    id?: string | null,
    name?: string | null,
    imageUrl?: string | null,
  } | null,
};

export type UpdateBrandMutationVariables = {
  id: string,
  input?: BrandInput | null,
};

export type UpdateBrandMutation = {
  updateBrand:  {
    __typename: "Brand",
    id?: string | null,
    name?: string | null,
    imageUrl?: string | null,
  },
};

export type DeleteBrandMutationVariables = {
  id: string,
};

export type DeleteBrandMutation = {
  deleteBrand?:  {
    __typename: "DeletionResponse",
    id: string,
  } | null,
};

export type CreateProductTypeMutationVariables = {
  input?: ProductTypeInput | null,
};

export type CreateProductTypeMutation = {
  createProductType?:  {
    __typename: "ProductType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  } | null,
};

export type UpdateProductTypeMutationVariables = {
  id: string,
  input?: ProductTypeInput | null,
};

export type UpdateProductTypeMutation = {
  updateProductType:  {
    __typename: "ProductType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  },
};

export type DeleteProductTypeMutationVariables = {
  id: string,
};

export type DeleteProductTypeMutation = {
  deleteProductType?:  {
    __typename: "DeletionResponse",
    id: string,
  } | null,
};

export type CreateOrganizationProductMutationVariables = {
  input?: OrganizationProductInput | null,
};

export type CreateOrganizationProductMutation = {
  createOrganizationProduct?:  {
    __typename: "OrganizationProduct",
    productId: string,
    organizationId: string,
    product?:  {
      __typename: "Product",
      id: string,
      name?: string | null,
      sku?: string | null,
      description?: string | null,
      baseUnitPrice?: number | null,
      brand?:  {
        __typename: "Brand",
        id?: string | null,
        name?: string | null,
        imageUrl?: string | null,
      } | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productStatus?:  {
        __typename: "ProductStatus",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      clients?:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null > | null,
      sizes?:  Array< {
        __typename: "ProductSize",
        productId?: string | null,
        sizeId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
      } | null > | null,
      benchmarkMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      coreMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      fitForm?:  {
        __typename: "FitForm",
        id?: string | null,
        name?: string | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        fitFormTypeId?: number | null,
        fitFormType?:  {
          __typename: "FitFormType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      loosePreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      tightPreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      fitParameters?:  Array< {
        __typename: "ProductFitParameter",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        idealSizeAdjustment?: number | null,
        looseSizeAdjustment?: number | null,
        tightSizeAdjustment?: number | null,
        isFitPoint?: boolean | null,
        fitWeighting?: number | null,
      } | null > | null,
      images?:  Array< {
        __typename: "ProductImage",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        productImageType?:  {
          __typename: "ProductImageType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        imageKey?: string | null,
        imageUrl?: string | null,
        color?:  {
          __typename: "Color",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          code?: string | null,
        } | null,
      } | null > | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteOrganizationProductMutationVariables = {
  organizationId: string,
  productId: string,
};

export type DeleteOrganizationProductMutation = {
  deleteOrganizationProduct?:  {
    __typename: "OrganizationProductDeletionResponse",
    organizationId: string,
    productId: string,
  } | null,
};

export type CreateColorMutationVariables = {
  input?: ColorInput | null,
};

export type CreateColorMutation = {
  createColor?:  {
    __typename: "Color",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    code?: string | null,
  } | null,
};

export type UpdateColorMutationVariables = {
  id: string,
  input?: ColorInput | null,
};

export type UpdateColorMutation = {
  updateColor:  {
    __typename: "Color",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    code?: string | null,
  },
};

export type DeleteColorMutationVariables = {
  id: string,
};

export type DeleteColorMutation = {
  deleteColor?:  {
    __typename: "DeletionResponse",
    id: string,
  } | null,
};

export type CreateProductImageTypeMutationVariables = {
  input?: ProductImageTypeInput | null,
};

export type CreateProductImageTypeMutation = {
  createProductImageType?:  {
    __typename: "ProductImageType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  } | null,
};

export type UpdateProductImageTypeMutationVariables = {
  id: string,
  input?: ProductImageTypeInput | null,
};

export type UpdateProductImageTypeMutation = {
  updateProductImageType:  {
    __typename: "ProductImageType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  },
};

export type DeleteProductImageTypeMutationVariables = {
  id: string,
};

export type DeleteProductImageTypeMutation = {
  deleteProductImageType?:  {
    __typename: "DeletionResponse",
    id: string,
  } | null,
};

export type CreateFitSessionResultMutationVariables = {
  input?: FitSessionResultInput | null,
};

export type CreateFitSessionResultMutation = {
  createFitSessionResult?:  {
    __typename: "FitSessionResult",
    id: string,
    customerId?: string | null,
    productId?: string | null,
    sizeId?: string | null,
    measurementId?: string | null,
    fitTechnicianNormalizedScore?: number | null,
    fitTechnicianInputDate?: string | null,
    isValid?: boolean | null,
  } | null,
};

export type UpdateFitSessionResultMutationVariables = {
  id: string,
  input?: FitSessionResultInput | null,
};

export type UpdateFitSessionResultMutation = {
  updateFitSessionResult?:  {
    __typename: "FitSessionResult",
    id: string,
    customerId?: string | null,
    productId?: string | null,
    sizeId?: string | null,
    measurementId?: string | null,
    fitTechnicianNormalizedScore?: number | null,
    fitTechnicianInputDate?: string | null,
    isValid?: boolean | null,
  } | null,
};

export type DeleteFitSessionResultMutationVariables = {
  id: string,
};

export type DeleteFitSessionResultMutation = {
  deleteFitSessionResult?:  {
    __typename: "FitSessionResult",
    id: string,
    customerId?: string | null,
    productId?: string | null,
    sizeId?: string | null,
    measurementId?: string | null,
    fitTechnicianNormalizedScore?: number | null,
    fitTechnicianInputDate?: string | null,
    isValid?: boolean | null,
  } | null,
};

export type CreateCustomerMutationVariables = {
  input?: CustomerInput | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    id?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    createdAt?: string | null,
    bodyMeasurementsComplete?: boolean | null,
    fitSessionCompleted?: boolean | null,
    country?:  {
      __typename: "Country",
      id?: string | null,
      code?: string | null,
      description?: string | null,
      continent?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null,
    sizeStreamAccountId?: string | null,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  id: string,
  input?: CustomerInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer:  {
    __typename: "Customer",
    id?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    createdAt?: string | null,
    bodyMeasurementsComplete?: boolean | null,
    fitSessionCompleted?: boolean | null,
    country?:  {
      __typename: "Country",
      id?: string | null,
      code?: string | null,
      description?: string | null,
      continent?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null,
    sizeStreamAccountId?: string | null,
  },
};

export type DeleteCustomerMutationVariables = {
  id: string,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "DeletionResponse",
    id: string,
  } | null,
};

export type ManageCustomerFileMutationVariables = {
  customerId: string,
  fileKey: string,
};

export type ManageCustomerFileMutation = {
  manageCustomerFile?:  {
    __typename: "FileObject",
    downloadUrl?: string | null,
    uploadUrl?: string | null,
    fileKey?: string | null,
  } | null,
};


export type ManageUploadUsersFileMutationVariables = {
  fileKey: string,
};

export type ManageUploadUsersFileMutation = {
  manageUploadUsersFile?:  {
    __typename: "FileObject",
    downloadUrl?: string | null,
    uploadUrl?: string | null,
    fileKey?: string | null,
  } | null,
};


export type ImportTg3dCustomerMeasurementsMutationVariables = {
  customerId: string,
  fileKey: string,
};

export type ImportTg3dCustomerMeasurementsMutation = {
  importTg3dCustomerMeasurements?:  {
    __typename: "CustomerMeasurementImportResult",
    measurementsUpdated?: number | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  id: string,
  input?: UserInput | null,
};

export type UpdateUserMutation = {
  updateUser:  {
    __typename: "User",
    id?: string | null,
    userName?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phone?: string | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      user?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null,
      role?:  {
        __typename: "Role",
        id: string,
        name?: string | null,
        description?: string | null,
      } | null,
      bodyMeasurementsComplete?: boolean | null,
      fitSessionComplete?: boolean | null,
      createdAt?: string | null,
    } > | null,
  },
};

export type InitiateJobMutationVariables = {
  jobKey: string,
  payload?: string | null,
};

export type InitiateJobMutation = {
  initiateJob?:  {
    __typename: "Result",
    id: string,
    status: ResultStatus,
  } | null,
};

export type PublishResultMutationVariables = {
  id: string,
  status?: ResultStatus | null,
  output?: string | null,
};

export type PublishResultMutation = {
  publishResult?:  {
    __typename: "Result",
    id: string,
    status: ResultStatus,
  } | null,
};

export type CreateScannerUserMutationVariables = {
  input: ScannerUserInput,
  tokenInput: SessionTokenInput,
};

export type CreateScannerUserMutation = {
  createScannerUser?:  {
    __typename: "ScannerUserResponse",
    phoneNumber?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    id?: string | null,
  } | null,
};

export type SendDynamicLinkMutationVariables = {
  phoneNumber: string,
  tokenInput: SessionTokenInput,
};

export type SendDynamicLinkMutation = {
  sendDynamicLink?:  {
    __typename: "PinpointResponse",
    success?: boolean | null,
  } | null,
};

export type SendOTPMutationVariables = {
  phoneNumber: string,
  source: string,
};

export type SendOTPMutation = {
  sendOTP?:  {
    __typename: "PinpointResponse",
    success?: boolean | null,
  } | null,
};

export type CreateSessionTokenMutationVariables = {
  phoneNumber: string,
};

export type CreateSessionTokenMutation = {
  createSessionToken?:  {
    __typename: "CreateSessionTokenResponse",
    sessionId: string,
  } | null,
};

export type CreateSessionFromOTPMutationVariables = {
  phoneNumber: string,
  otp: string,
  source: string,
};

export type CreateSessionFromOTPMutation = {
  createSessionFromOTP?:  {
    __typename: "SessionTokenFromOTPResponse",
    success?: boolean | null,
    token?: string | null,
  } | null,
};

export type SaveSizestreamMeasuresMutationVariables = {
  sizeStreamAccountId: string,
  rawCoreJson: string,
  rawPassenCustomJson: string,
};

export type SaveSizestreamMeasuresMutation = {
  saveSizestreamMeasures?:  {
    __typename: "CustomerMeasurementImportResult",
    measurementsUpdated?: number | null,
  } | null,
};

export type UpdateCustomerSizestreamMeasurementsMutationVariables = {
  sizeStreamAccountId: string,
};

export type UpdateCustomerSizestreamMeasurementsMutation = {
  updateCustomerSizestreamMeasurements?:  {
    __typename: "CustomerMeasurementUpdateResult",
    measurementsUpdated?: number | null,
  } | null,
};

export type UpdateCustomerMeasurementOnFitSessionResultsMutationVariables = {
  input?: FitSessionMeasurementUpdate | null,
};

export type UpdateCustomerMeasurementOnFitSessionResultsMutation = {
  updateCustomerMeasurementOnFitSessionResults?:  {
    __typename: "FitSessionMeasurementUpdateResult",
    updatedValue?: number | null,
    success?: boolean | null,
    message?: string | null,
  } | null,
};

export type OrganizationsQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type OrganizationsQuery = {
  organizations:  Array< {
    __typename: "Organization",
    id: string,
    name?: string | null,
    contactName?: string | null,
    contactPhone?: string | null,
    contactEmail?: string | null,
    notes?: string | null,
    organizationType?:  {
      __typename: "OrganizationType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    customerCount?: number | null,
    productCount?: number | null,
    userCount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
    updatedBy?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
  } >,
};

export type OrganizationQueryVariables = {
  id: string,
};

export type OrganizationQuery = {
  organization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    contactName?: string | null,
    contactPhone?: string | null,
    contactEmail?: string | null,
    notes?: string | null,
    organizationType?:  {
      __typename: "OrganizationType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    customerCount?: number | null,
    productCount?: number | null,
    userCount?: number | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
    updatedBy?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
  } | null,
};

export type OrganizationTypesQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type OrganizationTypesQuery = {
  organizationTypes:  Array< {
    __typename: "OrganizationType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  } >,
};

export type OrganizationTypeQueryVariables = {
  id: string,
};

export type OrganizationTypeQuery = {
  organizationType?:  {
    __typename: "OrganizationType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  } | null,
};

export type UsersQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type UsersQuery = {
  users:  Array< {
    __typename: "User",
    id?: string | null,
    userName?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phone?: string | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      user?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null,
      role?:  {
        __typename: "Role",
        id: string,
        name?: string | null,
        description?: string | null,
      } | null,
      bodyMeasurementsComplete?: boolean | null,
      fitSessionComplete?: boolean | null,
      createdAt?: string | null,
    } > | null,
  } >,
};

export type UserQueryVariables = {
  id?: string | null,
  email?: string | null,
};

export type UserQuery = {
  user?:  {
    __typename: "User",
    id?: string | null,
    userName?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phone?: string | null,
    memberships?:  Array< {
      __typename: "Membership",
      id: string,
      user?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null,
      role?:  {
        __typename: "Role",
        id: string,
        name?: string | null,
        description?: string | null,
      } | null,
      bodyMeasurementsComplete?: boolean | null,
      fitSessionComplete?: boolean | null,
      createdAt?: string | null,
    } > | null,
  } | null,
};

export type ProductsQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type ProductsQuery = {
  products:  Array< {
    __typename: "Product",
    id: string,
    name?: string | null,
    sku?: string | null,
    description?: string | null,
    baseUnitPrice?: number | null,
    brand?:  {
      __typename: "Brand",
      id?: string | null,
      name?: string | null,
      imageUrl?: string | null,
    } | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productStatus?:  {
      __typename: "ProductStatus",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    clients?:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null > | null,
    sizes?:  Array< {
      __typename: "ProductSize",
      productId?: string | null,
      sizeId?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
    } | null > | null,
    benchmarkMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
    } | null > | null,
    coreMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
    } | null > | null,
    fitForm?:  {
      __typename: "FitForm",
      id?: string | null,
      name?: string | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      fitFormTypeId?: number | null,
      fitFormType?:  {
        __typename: "FitFormType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
    } | null,
    idealSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    loosePreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    tightPreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    fitParameters?:  Array< {
      __typename: "ProductFitParameter",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSizeAdjustment?: number | null,
      looseSizeAdjustment?: number | null,
      tightSizeAdjustment?: number | null,
      isFitPoint?: boolean | null,
      fitWeighting?: number | null,
    } | null > | null,
    images?:  Array< {
      __typename: "ProductImage",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      productImageType?:  {
        __typename: "ProductImageType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      imageKey?: string | null,
      imageUrl?: string | null,
      color?:  {
        __typename: "Color",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        code?: string | null,
      } | null,
    } | null > | null,
  } >,
};

export type ProductQueryVariables = {
  id: string,
};

export type ProductQuery = {
  product?:  {
    __typename: "Product",
    id: string,
    name?: string | null,
    sku?: string | null,
    description?: string | null,
    baseUnitPrice?: number | null,
    brand?:  {
      __typename: "Brand",
      id?: string | null,
      name?: string | null,
      imageUrl?: string | null,
    } | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productType?:  {
      __typename: "ProductType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    productStatus?:  {
      __typename: "ProductStatus",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    clients?:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null > | null,
    sizes?:  Array< {
      __typename: "ProductSize",
      productId?: string | null,
      sizeId?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
    } | null > | null,
    benchmarkMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
    } | null > | null,
    coreMeasurements?:  Array< {
      __typename: "ProductMeasurement",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      size?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      isBenchmarkMeasurement?: boolean | null,
      value?: number | null,
      gradeRule?: number | null,
      isHalfMeasurement?: boolean | null,
    } | null > | null,
    fitForm?:  {
      __typename: "FitForm",
      id?: string | null,
      name?: string | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      fitFormTypeId?: number | null,
      fitFormType?:  {
        __typename: "FitFormType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
    } | null,
    idealSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    loosePreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    tightPreferenceSize?:  {
      __typename: "Size",
      id?: string | null,
      shortName?: string | null,
      longName?: string | null,
      rank?: number | null,
    } | null,
    fitParameters?:  Array< {
      __typename: "ProductFitParameter",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      measurement?:  {
        __typename: "Measurement",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        measurementType?:  {
          __typename: "MeasurementType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSizeAdjustment?: number | null,
      looseSizeAdjustment?: number | null,
      tightSizeAdjustment?: number | null,
      isFitPoint?: boolean | null,
      fitWeighting?: number | null,
    } | null > | null,
    images?:  Array< {
      __typename: "ProductImage",
      id?: string | null,
      product?:  {
        __typename: "Product",
        id: string,
        name?: string | null,
        sku?: string | null,
        description?: string | null,
        baseUnitPrice?: number | null,
        brand?:  {
          __typename: "Brand",
          id?: string | null,
          name?: string | null,
          imageUrl?: string | null,
        } | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productType?:  {
          __typename: "ProductType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        productStatus?:  {
          __typename: "ProductStatus",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        clients?:  Array< {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null > | null,
        sizes?:  Array< {
          __typename: "ProductSize",
          productId?: string | null,
          sizeId?: string | null,
        } | null > | null,
        benchmarkMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        coreMeasurements?:  Array< {
          __typename: "ProductMeasurement",
          id?: string | null,
          isBenchmarkMeasurement?: boolean | null,
          value?: number | null,
          gradeRule?: number | null,
          isHalfMeasurement?: boolean | null,
        } | null > | null,
        fitForm?:  {
          __typename: "FitForm",
          id?: string | null,
          name?: string | null,
          fitFormTypeId?: number | null,
        } | null,
        idealSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        loosePreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        tightPreferenceSize?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        fitParameters?:  Array< {
          __typename: "ProductFitParameter",
          id?: string | null,
          idealSizeAdjustment?: number | null,
          looseSizeAdjustment?: number | null,
          tightSizeAdjustment?: number | null,
          isFitPoint?: boolean | null,
          fitWeighting?: number | null,
        } | null > | null,
        images?:  Array< {
          __typename: "ProductImage",
          id?: string | null,
          imageKey?: string | null,
          imageUrl?: string | null,
        } | null > | null,
      } | null,
      productImageType?:  {
        __typename: "ProductImageType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      imageKey?: string | null,
      imageUrl?: string | null,
      color?:  {
        __typename: "Color",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        code?: string | null,
      } | null,
    } | null > | null,
  } | null,
};

export type ProductStatusesQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type ProductStatusesQuery = {
  productStatuses:  Array< {
    __typename: "ProductStatus",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  } >,
};

export type BrandQueryVariables = {
  id: string,
};

export type BrandQuery = {
  brand?:  {
    __typename: "Brand",
    id?: string | null,
    name?: string | null,
    imageUrl?: string | null,
  } | null,
};

export type BrandsQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type BrandsQuery = {
  brands:  Array< {
    __typename: "Brand",
    id?: string | null,
    name?: string | null,
    imageUrl?: string | null,
  } >,
};

export type ProductTypeQueryVariables = {
  id: string,
};

export type ProductTypeQuery = {
  productType?:  {
    __typename: "ProductType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  } | null,
};

export type ProductTypesQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type ProductTypesQuery = {
  productTypes:  Array< {
    __typename: "ProductType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  } >,
};

export type ProductImageTypeQueryVariables = {
  id: string,
};

export type ProductImageTypeQuery = {
  productImageType?:  {
    __typename: "ProductImageType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  } | null,
};

export type ProductImageTypesQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type ProductImageTypesQuery = {
  productImageTypes:  Array< {
    __typename: "ProductImageType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  } >,
};

export type MembershipQueryVariables = {
  id: string,
};

export type MembershipQuery = {
  membership?:  {
    __typename: "Membership",
    id: string,
    user?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null,
    role?:  {
      __typename: "Role",
      id: string,
      name?: string | null,
      description?: string | null,
    } | null,
    bodyMeasurementsComplete?: boolean | null,
    fitSessionComplete?: boolean | null,
    createdAt?: string | null,
  } | null,
};

export type MembershipsQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type MembershipsQuery = {
  memberships:  Array< {
    __typename: "Membership",
    id: string,
    user?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null,
    role?:  {
      __typename: "Role",
      id: string,
      name?: string | null,
      description?: string | null,
    } | null,
    bodyMeasurementsComplete?: boolean | null,
    fitSessionComplete?: boolean | null,
    createdAt?: string | null,
  } >,
};

export type MeasurementQueryVariables = {
  id: string,
};

export type MeasurementQuery = {
  measurement?:  {
    __typename: "Measurement",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    measurementType?:  {
      __typename: "MeasurementType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
  } | null,
};

export type MeasurementsQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type MeasurementsQuery = {
  measurements:  Array< {
    __typename: "Measurement",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    measurementType?:  {
      __typename: "MeasurementType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
  } >,
};

export type SizeQueryVariables = {
  id: string,
};

export type SizeQuery = {
  size?:  {
    __typename: "Size",
    id?: string | null,
    shortName?: string | null,
    longName?: string | null,
    rank?: number | null,
  } | null,
};

export type SizesQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type SizesQuery = {
  sizes:  Array< {
    __typename: "Size",
    id?: string | null,
    shortName?: string | null,
    longName?: string | null,
    rank?: number | null,
  } >,
};

export type GenderQueryVariables = {
  id: string,
};

export type GenderQuery = {
  gender?:  {
    __typename: "Gender",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  } | null,
};

export type GendersQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type GendersQuery = {
  genders:  Array< {
    __typename: "Gender",
    id?: string | null,
    name?: string | null,
    description?: string | null,
  } >,
};

export type FitFormQueryVariables = {
  id: string,
};

export type FitFormQuery = {
  fitForm?:  {
    __typename: "FitForm",
    id?: string | null,
    name?: string | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    fitFormTypeId?: number | null,
    fitFormType?:  {
      __typename: "FitFormType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
  } | null,
};

export type FitFormsQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type FitFormsQuery = {
  fitForms:  Array< {
    __typename: "FitForm",
    id?: string | null,
    name?: string | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    fitFormTypeId?: number | null,
    fitFormType?:  {
      __typename: "FitFormType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
  } >,
};

export type ColorQueryVariables = {
  id: string,
};

export type ColorQuery = {
  color?:  {
    __typename: "Color",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    code?: string | null,
  } | null,
};

export type ColorsQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type ColorsQuery = {
  colors:  Array< {
    __typename: "Color",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    code?: string | null,
  } >,
};

export type OrganizationProductQueryVariables = {
  id: string,
};

export type OrganizationProductQuery = {
  organizationProduct?:  {
    __typename: "OrganizationProduct",
    productId: string,
    organizationId: string,
    product?:  {
      __typename: "Product",
      id: string,
      name?: string | null,
      sku?: string | null,
      description?: string | null,
      baseUnitPrice?: number | null,
      brand?:  {
        __typename: "Brand",
        id?: string | null,
        name?: string | null,
        imageUrl?: string | null,
      } | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productStatus?:  {
        __typename: "ProductStatus",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      clients?:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null > | null,
      sizes?:  Array< {
        __typename: "ProductSize",
        productId?: string | null,
        sizeId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
      } | null > | null,
      benchmarkMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      coreMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      fitForm?:  {
        __typename: "FitForm",
        id?: string | null,
        name?: string | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        fitFormTypeId?: number | null,
        fitFormType?:  {
          __typename: "FitFormType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      loosePreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      tightPreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      fitParameters?:  Array< {
        __typename: "ProductFitParameter",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        idealSizeAdjustment?: number | null,
        looseSizeAdjustment?: number | null,
        tightSizeAdjustment?: number | null,
        isFitPoint?: boolean | null,
        fitWeighting?: number | null,
      } | null > | null,
      images?:  Array< {
        __typename: "ProductImage",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        productImageType?:  {
          __typename: "ProductImageType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        imageKey?: string | null,
        imageUrl?: string | null,
        color?:  {
          __typename: "Color",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          code?: string | null,
        } | null,
      } | null > | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null,
  } | null,
};

export type OrganizationProductsQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type OrganizationProductsQuery = {
  organizationProducts:  Array< {
    __typename: "OrganizationProduct",
    productId: string,
    organizationId: string,
    product?:  {
      __typename: "Product",
      id: string,
      name?: string | null,
      sku?: string | null,
      description?: string | null,
      baseUnitPrice?: number | null,
      brand?:  {
        __typename: "Brand",
        id?: string | null,
        name?: string | null,
        imageUrl?: string | null,
      } | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productType?:  {
        __typename: "ProductType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      productStatus?:  {
        __typename: "ProductStatus",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      clients?:  Array< {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null > | null,
      sizes?:  Array< {
        __typename: "ProductSize",
        productId?: string | null,
        sizeId?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
      } | null > | null,
      benchmarkMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      coreMeasurements?:  Array< {
        __typename: "ProductMeasurement",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        size?:  {
          __typename: "Size",
          id?: string | null,
          shortName?: string | null,
          longName?: string | null,
          rank?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        isBenchmarkMeasurement?: boolean | null,
        value?: number | null,
        gradeRule?: number | null,
        isHalfMeasurement?: boolean | null,
      } | null > | null,
      fitForm?:  {
        __typename: "FitForm",
        id?: string | null,
        name?: string | null,
        gender?:  {
          __typename: "Gender",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        fitFormTypeId?: number | null,
        fitFormType?:  {
          __typename: "FitFormType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
      } | null,
      idealSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      loosePreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      tightPreferenceSize?:  {
        __typename: "Size",
        id?: string | null,
        shortName?: string | null,
        longName?: string | null,
        rank?: number | null,
      } | null,
      fitParameters?:  Array< {
        __typename: "ProductFitParameter",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        measurement?:  {
          __typename: "Measurement",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        idealSizeAdjustment?: number | null,
        looseSizeAdjustment?: number | null,
        tightSizeAdjustment?: number | null,
        isFitPoint?: boolean | null,
        fitWeighting?: number | null,
      } | null > | null,
      images?:  Array< {
        __typename: "ProductImage",
        id?: string | null,
        product?:  {
          __typename: "Product",
          id: string,
          name?: string | null,
          sku?: string | null,
          description?: string | null,
          baseUnitPrice?: number | null,
        } | null,
        productImageType?:  {
          __typename: "ProductImageType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        imageKey?: string | null,
        imageUrl?: string | null,
        color?:  {
          __typename: "Color",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          code?: string | null,
        } | null,
      } | null > | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null,
  } >,
};

export type CustomerQueryVariables = {
  id: string,
};

export type CustomerQuery = {
  customer?:  {
    __typename: "Customer",
    id?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    createdAt?: string | null,
    bodyMeasurementsComplete?: boolean | null,
    fitSessionCompleted?: boolean | null,
    country?:  {
      __typename: "Country",
      id?: string | null,
      code?: string | null,
      description?: string | null,
      continent?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null,
    sizeStreamAccountId?: string | null,
  } | null,
};

export type CustomersQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type CustomersQuery = {
  customers:  Array< {
    __typename: "Customer",
    id?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    email?: string | null,
    phoneNumber?: string | null,
    postalCode?: string | null,
    gender?:  {
      __typename: "Gender",
      id?: string | null,
      name?: string | null,
      description?: string | null,
    } | null,
    createdAt?: string | null,
    bodyMeasurementsComplete?: boolean | null,
    fitSessionCompleted?: boolean | null,
    country?:  {
      __typename: "Country",
      id?: string | null,
      code?: string | null,
      description?: string | null,
      continent?: string | null,
    } | null,
    user?:  {
      __typename: "User",
      id?: string | null,
      userName?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phone?: string | null,
      memberships?:  Array< {
        __typename: "Membership",
        id: string,
        user?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name?: string | null,
          contactName?: string | null,
          contactPhone?: string | null,
          contactEmail?: string | null,
          notes?: string | null,
          customerCount?: number | null,
          productCount?: number | null,
          userCount?: number | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | null,
        role?:  {
          __typename: "Role",
          id: string,
          name?: string | null,
          description?: string | null,
        } | null,
        bodyMeasurementsComplete?: boolean | null,
        fitSessionComplete?: boolean | null,
        createdAt?: string | null,
      } > | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      contactName?: string | null,
      contactPhone?: string | null,
      contactEmail?: string | null,
      notes?: string | null,
      organizationType?:  {
        __typename: "OrganizationType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      customerCount?: number | null,
      productCount?: number | null,
      userCount?: number | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      updatedBy?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
    } | null,
    sizeStreamAccountId?: string | null,
  } >,
};

export type CustomerMeasurementsQueryVariables = {
  customerId: string,
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type CustomerMeasurementsQuery = {
  customerMeasurements:  Array< {
    __typename: "CustomerMeasurement",
    customer?:  {
      __typename: "Customer",
      id?: string | null,
      nameFirst?: string | null,
      nameLast?: string | null,
      email?: string | null,
      phoneNumber?: string | null,
      postalCode?: string | null,
      gender?:  {
        __typename: "Gender",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
      createdAt?: string | null,
      bodyMeasurementsComplete?: boolean | null,
      fitSessionCompleted?: boolean | null,
      country?:  {
        __typename: "Country",
        id?: string | null,
        code?: string | null,
        description?: string | null,
        continent?: string | null,
      } | null,
      user?:  {
        __typename: "User",
        id?: string | null,
        userName?: string | null,
        nameFirst?: string | null,
        nameLast?: string | null,
        email?: string | null,
        phone?: string | null,
        memberships?:  Array< {
          __typename: "Membership",
          id: string,
          bodyMeasurementsComplete?: boolean | null,
          fitSessionComplete?: boolean | null,
          createdAt?: string | null,
        } > | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        contactName?: string | null,
        contactPhone?: string | null,
        contactEmail?: string | null,
        notes?: string | null,
        organizationType?:  {
          __typename: "OrganizationType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
        } | null,
        customerCount?: number | null,
        productCount?: number | null,
        userCount?: number | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
        updatedBy?:  {
          __typename: "User",
          id?: string | null,
          userName?: string | null,
          nameFirst?: string | null,
          nameLast?: string | null,
          email?: string | null,
          phone?: string | null,
        } | null,
      } | null,
      sizeStreamAccountId?: string | null,
    } | null,
    measurement?:  {
      __typename: "Measurement",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      measurementType?:  {
        __typename: "MeasurementType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
      } | null,
    } | null,
    value?: number | null,
  } >,
};

export type CountryQueryVariables = {
  id: string,
};

export type CountryQuery = {
  country?:  {
    __typename: "Country",
    id?: string | null,
    code?: string | null,
    description?: string | null,
    continent?: string | null,
  } | null,
};

export type CountriesQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type CountriesQuery = {
  countries:  Array< {
    __typename: "Country",
    id?: string | null,
    code?: string | null,
    description?: string | null,
    continent?: string | null,
  } >,
};

export type FitSessionResultQueryVariables = {
  id: string,
};

export type FitSessionResultQuery = {
  fitSessionResult?:  {
    __typename: "FitSessionResult",
    id: string,
    customerId?: string | null,
    productId?: string | null,
    sizeId?: string | null,
    measurementId?: string | null,
    fitTechnicianNormalizedScore?: number | null,
    fitTechnicianInputDate?: string | null,
    isValid?: boolean | null,
  } | null,
};

export type FitSessionResultsQueryVariables = {
  filter?: FilterInput | null,
  limit?: number | null,
  offset?: number | null,
};

export type FitSessionResultsQuery = {
  fitSessionResults:  Array< {
    __typename: "FitSessionResult",
    id: string,
    customerId?: string | null,
    productId?: string | null,
    sizeId?: string | null,
    measurementId?: string | null,
    fitTechnicianNormalizedScore?: number | null,
    fitTechnicianInputDate?: string | null,
    isValid?: boolean | null,
  } >,
};

export type FitRecommendationsQueryVariables = {
  customerId: string,
  productIds: Array< string | null >,
  options?: FitOptions | null,
};

export type FitRecommendationsQuery = {
  fitRecommendations?:  {
    __typename: "FitRecommendationResult",
    productFitRecommendations?:  Array< {
      __typename: "ProductFitRecommendation",
      productId?: string | null,
      success?: boolean | null,
      errorMessage?: string | null,
      recommendation?:  {
        __typename: "FitRecommendation",
        size?: string | null,
        weightedScore?: number | null,
        sizeByFitPreference?: string | null,
        sizeByTopThreeSelectivePreference?: string | null,
        sizeByVariableSelectivePreference?: string | null,
        sizeByFitPointPreference?: string | null,
      } | null,
      weightedScores?:  Array< {
        __typename: "FitWeightedScore",
        size?: string | null,
        weightedScore?: number | null,
      } | null > | null,
      weightedScoreBreakdown?:  Array< {
        __typename: "FitWeightedScoreBreakdown",
        size?: string | null,
        scoreBreakdown?:  Array< {
          __typename: "FitPointWeightedScore",
          fitPoint?: number | null,
          value?: number | null,
          weightedScore?: number | null,
          normalizedScore?: number | null,
        } | null > | null,
      } | null > | null,
    } | null > | null,
    input?: string | null,
  } | null,
};

export type ScannerUserByPhoneQueryVariables = {
  phoneNumber: string,
};

export type ScannerUserByPhoneQuery = {
  scannerUserByPhone?:  {
    __typename: "ScannerUserResponse",
    phoneNumber?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    id?: string | null,
  } | null,
};

export type GetRecommendationsRequestsQueryVariables = {
  customerId: string;
};

export type GetRecommendationsRequestsQuery = {
  getRecommendationsRequests?: Array<{
  __typename: "RecommendationsRequests",
    brandId: string;
    brandName: string;
    id: string;
    productId: string;
  }> | null;
};

export type GetBodyModelUrlQueryVariables = {
  sizeStreamAccountId: string;
};

export type GetBodyModelUrlQuery = {
  getBodyModelUrl?: {
    __typename: "GetBodyModelUrlResponse";
    url?: string | null;
  } | null;
};

export type ScanExistsQueryVariables = {
  id: string,
  tokenInput: SessionTokenInput,
};

export type ScanExistsQuery = {
  scanExists?:  {
    __typename: "ScanExistsResponse",
    exists?: boolean | null,
    scan?: string | null,
  } | null,
};

export type GetScanCoreMeasuresQueryVariables = {
  id: string,
  tokenInput: SessionTokenInput,
};

export type GetScanCoreMeasuresQuery = {
  getScanCoreMeasures?:  {
    __typename: "ScanCoreMeasuresResponse",
    measures?: string | null,
  } | null,
};

export type ScannerUserByIDQueryVariables = {
  id: string,
};

export type ScannerUserByIDQuery = {
  scannerUserByID?:  {
    __typename: "ScannerUserResponse",
    phoneNumber?: string | null,
    nameFirst?: string | null,
    nameLast?: string | null,
    id?: string | null,
  } | null,
};

export type AccountPhoneVerificationQueryVariables = {
  phoneNumber: string,
};

export type AccountPhoneVerificationQuery = {
  accountPhoneVerification?:  {
    __typename: "PhoneVerificationResponse",
    phoneNumberRecordExists?: boolean | null,
  } | null,
};

export type VerifyOTPQueryVariables = {
  phoneNumber: string,
  otp: string,
  source: string,
};

export type VerifyOTPQuery = {
  verifyOTP?:  {
    __typename: "PinpointResponse",
    success?: boolean | null,
  } | null,
};

export type VerifySessionTokenQueryVariables = {
  phoneNumber: string,
  sessionToken: string,
};

export type VerifySessionTokenQuery = {
  verifySessionToken?:  {
    __typename: "VerifySessionTokenResponse",
    valid: boolean,
  } | null,
};

export type AnalyzeFitRecommendationQueryVariables = {
  customerId: string,
  productId: string,
};

export type AnalyzeFitRecommendationQuery = {
  analyzeFitRecommendation?:  {
    __typename: "FitRecommendationAnalysisResponse",
    output?: string | null,
  } | null,
};

export type GenerateFitSessionComparisonQueryVariables = {
  customerIds: Array< string | null >,
  productIds: Array< string | null >,
  useManualMeasurements?: boolean | null,
};

export type GenerateFitSessionComparisonQuery = {
  generateFitSessionComparison?:  {
    __typename: "FitRecommendationAnalysisResponse",
    output?: string | null,
  } | null,
};

export type GenerateFitPreferenceComparisonQueryVariables = {
  customerIds: Array< string | null >,
  productIds: Array< string | null >,
  useManualMeasurements?: boolean | null,
};

export type GenerateFitPreferenceComparisonQuery = {
  generateFitPreferenceComparison?:  {
    __typename: "FitRecommendationAnalysisResponse",
    output?: string | null,
  } | null,
};

export type OnJobCompletedSubscriptionVariables = {
  id: string,
};

export type OnJobCompletedSubscription = {
  onJobCompleted?:  {
    __typename: "Result",
    id: string,
    status: ResultStatus,
  } | null,
};
