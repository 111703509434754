export const userWithRoleOnly = /* GraphQL */ `
  query UserWithRole($id: ID, $email: String) {
    user(id: $id, email: $email) {
      id
      userName
      nameFirst
      nameLast
      email
      phone
      memberships {
        id
        role {
          name
          description
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

export const productListing = /* GraphQL */ `
  query ProductsListing($filter: FilterInput, $limit: Int, $offset: Int) {
    products(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      sku
      description
      baseUnitPrice
      brand {
        id
        name
      }
      gender {
        id
        name
        description
      }
      productType {
        id
        name
        description
      }
      productStatus {
        id
        name
        description
      }
      clients {
        id
        name
        createdAt
      }
    }
  }
`;

export const productWithImagesList = /* GraphQL */ `
  query ProductsListingWImages($filter: FilterInput, $limit: Int, $offset: Int) {
    products(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      sku
      description
      baseUnitPrice
      brand {
        id
        name
      }
      gender {
        id
        name
        description
      }
      productType {
        id
        name
        description
      }
      productStatus {
        id
        name
        description
      }
      clients {
        id
        name
        createdAt
      }
      images {
        id
        imageKey
        imageUrl
      }
    }
  }
`;

export const productCustom = /* GraphQL */ `
  query ProductCustom($id: ID!) {
    product(id: $id) {
      id
      name
      sku
      description
      baseUnitPrice
      brand {
        id
        name
      }
      gender {
        id
        name
      }
      productType {
        id
        name
        description
      }
      productStatus {
        id
        name
        description
      }
      clients {
        id
        name
        createdAt
      }
      sizes {
        productId
        sizeId
      }
      benchmarkMeasurements {
        id
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
        measurement {
          id
          name
          description
        }
        size {
          id
          shortName
          longName
        }
        product {
          id
        }
      }
      coreMeasurements {
        id
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
        measurement {
          id
          name
          description
        }
        size {
          id
          shortName
          longName
        }
        product {
          id
        }
      }
      idealSize {
        id
        shortName
        longName
      }
      loosePreferenceSize {
        id
        shortName
        longName
      }
      tightPreferenceSize {
        id
        shortName
        longName
      }
      fitParameters {
        id
        idealSizeAdjustment
        looseSizeAdjustment
        tightSizeAdjustment
        isFitPoint
        fitWeighting
        measurement {
          id
          name
          description
        }
        product {
          id
        }
      }
      fitForm {
        id
        name
        fitFormTypeId
        gender {
          id
          name
        }
      }
      images {
        id
        imageKey
        imageUrl
        product {
          id
        }
        productImageType {
          id
        }
        color {
          id
        }
      }
    }
  }
`;


export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserCustom($id: ID!, $input: UserInput) {
    updateUser(id: $id, input: $input) {
      id
      userName
      nameFirst
      nameLast
      email
      phone
      memberships {
        id
        organization {
          id
          name
          createdAt
        }
        role {
          name
          description
        }
      }
    }
  }
`;

export const createProductCustom = /* GraphQL */ `
  mutation CreateProductCustom($input: ProductInput) {
    createProduct(input: $input) {
      id
      name
    }
  }
`;

export const updateProductCustom = /* GraphQL */ `
  mutation UpdateProductCustom($id: ID!, $input: ProductInput) {
    updateProduct(id: $id, input: $input) {
      id
      name
      sku
      description
      baseUnitPrice
    }
  }
`;

export const createProductSizeCustom = /* GraphQL */ `
  mutation CreateProductSizeCustom($input: ProductSizeInput) {
    createProductSize(input: $input) {
      productId
      sizeId
    }
  }
`;
export const cloneProductCustom = /* GraphQL */ `
  mutation CloneProductCustom($id: ID!) {
    cloneProduct(id: $id) {
      id
    }
  }
`;

export const createProductMeasurementCustom = /* GraphQL */ `
  mutation CreateProductMeasurementCustom($input: ProductMeasurementInput) {
    createProductMeasurement(input: $input) {
      id
      isBenchmarkMeasurement
      value
      gradeRule
      isHalfMeasurement
    }
  }
`;

export const updateProductMeasurementCustom = /* GraphQL */ `
  mutation UpdateProductMeasurementCustom($id: ID!, $input: ProductMeasurementInput) {
    updateProductMeasurement(id: $id, input: $input) {
      id
      isBenchmarkMeasurement
      value
      gradeRule
      isHalfMeasurement
    }
  }
`;


export const createProductFitParameterCustom = /* GraphQL */ `
  mutation CreateProductFitParameterCustom($input: ProductFitParameterInput) {
    createProductFitParameter(input: $input) {
      id
      idealSizeAdjustment
      looseSizeAdjustment
      tightSizeAdjustment
      isFitPoint
      fitWeighting
    }
  }
`;

export const updateProductFitParameterCustom = /* GraphQL */ `
  mutation UpdateProductFitParameterCustom(
    $id: ID!
    $input: ProductFitParameterInput
  ) {
    updateProductFitParameter(id: $id, input: $input) {
      id
      idealSizeAdjustment
      looseSizeAdjustment
      tightSizeAdjustment
      isFitPoint
      fitWeighting
    }
  }
`;


export const createOrganizationProductCustom = /* GraphQL */ `
  mutation CreateOrganizationProductCustom($input: OrganizationProductInput) {
    createOrganizationProduct(input: $input) {
      productId
      organizationId
      organization {
        id
        name
      }
    }
  }
`;

export const updateProductImageCustom = /* GraphQL */ `
  mutation UpdateProductImageCustom($id: ID!, $input: ProductImageInput) {
    updateProductImage(id: $id, input: $input) {
      id
    }
  }
`;

export const createProductImageCustom = /* GraphQL */ `
  mutation CreateProductImageCustom($input: ProductImageInput) {
    createProductImage(input: $input) {
      id
    }
  }
`;

export const customersCustom = /* GraphQL */ `
  query CustomersList($filter: FilterInput, $limit: Int) {
    customers(filter: $filter, limit: $limit) {
      id
      nameFirst
      nameLast
      email
      phoneNumber
      postalCode
      sizeStreamAccountId
      gender {
        id
        name
        description
      }
      createdAt
      bodyMeasurementsComplete
      fitSessionCompleted
      organization {
        id
        name
      }
      country {
        id
        code
        description
      }
      age
      height
      weight
      retailerIds
    }
  }
`;

export const customerCustom = /* GraphQL */ `
  query CustomerDetails($id: ID!) {
    customer(id: $id) {
      id
      nameFirst
      nameLast
      email
      phoneNumber
      postalCode
      sizeStreamAccountId
      gender {
        id
        name
        description
      }
      createdAt
      bodyMeasurementsComplete
      fitSessionCompleted
      organization {
        id
        name
      }
      country {
        id
        code
        description
      }
      age
      height
      weight
      retailerIds
    }
  }
`;

export const createCustomerCustom = /* GraphQL */ `
  mutation CreateCustomerCustom($input: CustomerInput) {
    createCustomer(input: $input) {
      id
      nameFirst
      nameLast
      email
      phoneNumber
      postalCode
      sizeStreamAccountId
      gender {
        id
        name
        description
      }
      createdAt
      bodyMeasurementsComplete
      fitSessionCompleted
      organization {
        id
        name
      }
      country {
        id
        code
        description
      }
      age
      height
      weight
      retailerIds

    }
  }
`;

export const updateCustomerCustom = /* GraphQL */ `
  mutation UpdateCustomerCustom($id: ID!, $input: CustomerInput) {
    updateCustomer(id: $id, input: $input) {
      id
      nameFirst
      nameLast
      email
      phoneNumber
      postalCode
      sizeStreamAccountId
      gender {
        id
        name
        description
      }
      createdAt
      bodyMeasurementsComplete
      fitSessionCompleted
      organization {
        id
        name
      }
      country {
        id
        code
        description
      }
      age
      height
      weight
      retailerIds

    }
  }
`;

export const organizationsCustom = /* GraphQL */ `
  query OrganizationsCustom($filter: FilterInput, $limit: Int, $offset: Int) {
    organizations(filter: $filter, limit: $limit, offset: $offset) {
      id
      name
      contactName
      contactPhone
      contactEmail
      notes
      organizationType {
        id
        name
        description
      }
      customerCount
      productCount
      userCount
      createdAt
      updatedAt
    }
  }
`;

export const customerMeasurementsCustom = /* GraphQL */ `
  query CustomerMeasurementsCustom(
    $customerId: ID!
    $filter: FilterInput
    $limit: Int
    $offset: Int
  ) {
    customerMeasurements(
      customerId: $customerId
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      customer {
        id
      }
      measurement {
        id
        name
        description
      }
      value
    }
  }
`;
