<div class="container-fluid">
  <div class="logo-wrapper">
    <img
      style="width: 37px; height: 51px"
      src="../assets/brand/P_orange.svg"
    />
  </div>

  <a
    routerLink="/customers"
    routerLinkActive="active"
    nbTooltip="Users"
    nbTooltipPlacement="right"
  >
    <img
      style="width: 22px;height:28px"
      src="../assets/icons/customers.svg"
      alt="Customers"
    />
    <!-- <span class="ml-3">Customers</span> -->
  </a>

  <a
    routerLink="/products"
    routerLinkActive="active"
    nbTooltip="Products/SKUs"
    nbTooltipPlacement="right"
  >
    <img
      style="width: 20px;height:22px"
      src="../assets/icons/products.svg"
      alt="Products/SKUs"
    />
    <!-- <span class="ml-3">Products/SKUs</span> -->
  </a>

</div>

