/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($input: OrganizationInput) {
    createOrganization(input: $input) {
      id
      name
      contactName
      contactPhone
      contactEmail
      notes
      organizationType {
        id
        name
        description
      }
      customerCount
      productCount
      userCount
      createdAt
      updatedAt
      createdBy {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
      updatedBy {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization($id: ID!, $input: OrganizationInput) {
    updateOrganization(id: $id, input: $input) {
      id
      name
      contactName
      contactPhone
      contactEmail
      notes
      organizationType {
        id
        name
        description
      }
      customerCount
      productCount
      userCount
      createdAt
      updatedAt
      createdBy {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
      updatedBy {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization($id: ID!) {
    deleteOrganization(id: $id) {
      id
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: ProductInput) {
    createProduct(input: $input) {
      id
      name
      sku
      description
      baseUnitPrice
      brand {
        id
        name
        imageUrl
      }
      gender {
        id
        name
        description
      }
      productType {
        id
        name
        description
      }
      productStatus {
        id
        name
        description
      }
      clients {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
      sizes {
        productId
        sizeId
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
      }
      benchmarkMeasurements {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
      }
      coreMeasurements {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
      }
      fitForm {
        id
        name
        gender {
          id
          name
          description
        }
        fitFormTypeId
        fitFormType {
          id
          name
          description
        }
      }
      idealSize {
        id
        shortName
        longName
        rank
      }
      loosePreferenceSize {
        id
        shortName
        longName
        rank
      }
      tightPreferenceSize {
        id
        shortName
        longName
        rank
      }
      fitParameters {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        idealSizeAdjustment
        looseSizeAdjustment
        tightSizeAdjustment
        isFitPoint
        fitWeighting
      }
      images {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        productImageType {
          id
          name
          description
        }
        imageKey
        imageUrl
        color {
          id
          name
          description
          code
        }
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($id: ID!, $input: ProductInput) {
    updateProduct(id: $id, input: $input) {
      id
      name
      sku
      description
      baseUnitPrice
      brand {
        id
        name
        imageUrl
      }
      gender {
        id
        name
        description
      }
      productType {
        id
        name
        description
      }
      productStatus {
        id
        name
        description
      }
      clients {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
      sizes {
        productId
        sizeId
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
      }
      benchmarkMeasurements {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
      }
      coreMeasurements {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
      }
      fitForm {
        id
        name
        gender {
          id
          name
          description
        }
        fitFormTypeId
        fitFormType {
          id
          name
          description
        }
      }
      idealSize {
        id
        shortName
        longName
        rank
      }
      loosePreferenceSize {
        id
        shortName
        longName
        rank
      }
      tightPreferenceSize {
        id
        shortName
        longName
        rank
      }
      fitParameters {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        idealSizeAdjustment
        looseSizeAdjustment
        tightSizeAdjustment
        isFitPoint
        fitWeighting
      }
      images {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        productImageType {
          id
          name
          description
        }
        imageKey
        imageUrl
        color {
          id
          name
          description
          code
        }
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`;
export const cloneProduct = /* GraphQL */ `
  mutation CloneProduct($id: ID!) {
    cloneProduct(id: $id) {
      id
      name
      sku
      description
      baseUnitPrice
      brand {
        id
        name
        imageUrl
      }
      gender {
        id
        name
        description
      }
      productType {
        id
        name
        description
      }
      productStatus {
        id
        name
        description
      }
      clients {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
      sizes {
        productId
        sizeId
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
      }
      benchmarkMeasurements {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
      }
      coreMeasurements {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        size {
          id
          shortName
          longName
          rank
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        isBenchmarkMeasurement
        value
        gradeRule
        isHalfMeasurement
      }
      fitForm {
        id
        name
        gender {
          id
          name
          description
        }
        fitFormTypeId
        fitFormType {
          id
          name
          description
        }
      }
      idealSize {
        id
        shortName
        longName
        rank
      }
      loosePreferenceSize {
        id
        shortName
        longName
        rank
      }
      tightPreferenceSize {
        id
        shortName
        longName
        rank
      }
      fitParameters {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        measurement {
          id
          name
          description
          measurementType {
            id
            name
            description
          }
        }
        idealSizeAdjustment
        looseSizeAdjustment
        tightSizeAdjustment
        isFitPoint
        fitWeighting
      }
      images {
        id
        product {
          id
          name
          sku
          description
          baseUnitPrice
          brand {
            id
            name
            imageUrl
          }
          gender {
            id
            name
            description
          }
          productType {
            id
            name
            description
          }
          productStatus {
            id
            name
            description
          }
          clients {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          sizes {
            productId
            sizeId
          }
          benchmarkMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          coreMeasurements {
            id
            isBenchmarkMeasurement
            value
            gradeRule
            isHalfMeasurement
          }
          fitForm {
            id
            name
            fitFormTypeId
          }
          idealSize {
            id
            shortName
            longName
            rank
          }
          loosePreferenceSize {
            id
            shortName
            longName
            rank
          }
          tightPreferenceSize {
            id
            shortName
            longName
            rank
          }
          fitParameters {
            id
            idealSizeAdjustment
            looseSizeAdjustment
            tightSizeAdjustment
            isFitPoint
            fitWeighting
          }
          images {
            id
            imageKey
            imageUrl
          }
        }
        productImageType {
          id
          name
          description
        }
        imageKey
        imageUrl
        color {
          id
          name
          description
          code
        }
      }
    }
  }
`;
export const createProductSize = /* GraphQL */ `
  mutation CreateProductSize($input: ProductSizeInput) {
    createProductSize(input: $input) {
      productId
      sizeId
      product {
        id
        name
        sku
        description
        baseUnitPrice
        brand {
          id
          name
          imageUrl
        }
        gender {
          id
          name
          description
        }
        productType {
          id
          name
          description
        }
        productStatus {
          id
          name
          description
        }
        clients {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        sizes {
          productId
          sizeId
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
        }
        benchmarkMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        coreMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        fitForm {
          id
          name
          gender {
            id
            name
            description
          }
          fitFormTypeId
          fitFormType {
            id
            name
            description
          }
        }
        idealSize {
          id
          shortName
          longName
          rank
        }
        loosePreferenceSize {
          id
          shortName
          longName
          rank
        }
        tightPreferenceSize {
          id
          shortName
          longName
          rank
        }
        fitParameters {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          measurement {
            id
            name
            description
          }
          idealSizeAdjustment
          looseSizeAdjustment
          tightSizeAdjustment
          isFitPoint
          fitWeighting
        }
        images {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          productImageType {
            id
            name
            description
          }
          imageKey
          imageUrl
          color {
            id
            name
            description
            code
          }
        }
      }
      size {
        id
        shortName
        longName
        rank
      }
    }
  }
`;
export const deleteProductSize = /* GraphQL */ `
  mutation DeleteProductSize($productId: ID!, $sizeId: ID!) {
    deleteProductSize(productId: $productId, sizeId: $sizeId) {
      productId
      sizeId
    }
  }
`;
export const createProductImage = /* GraphQL */ `
  mutation CreateProductImage($input: ProductImageInput) {
    createProductImage(input: $input) {
      id
      product {
        id
        name
        sku
        description
        baseUnitPrice
        brand {
          id
          name
          imageUrl
        }
        gender {
          id
          name
          description
        }
        productType {
          id
          name
          description
        }
        productStatus {
          id
          name
          description
        }
        clients {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        sizes {
          productId
          sizeId
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
        }
        benchmarkMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        coreMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        fitForm {
          id
          name
          gender {
            id
            name
            description
          }
          fitFormTypeId
          fitFormType {
            id
            name
            description
          }
        }
        idealSize {
          id
          shortName
          longName
          rank
        }
        loosePreferenceSize {
          id
          shortName
          longName
          rank
        }
        tightPreferenceSize {
          id
          shortName
          longName
          rank
        }
        fitParameters {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          measurement {
            id
            name
            description
          }
          idealSizeAdjustment
          looseSizeAdjustment
          tightSizeAdjustment
          isFitPoint
          fitWeighting
        }
        images {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          productImageType {
            id
            name
            description
          }
          imageKey
          imageUrl
          color {
            id
            name
            description
            code
          }
        }
      }
      productImageType {
        id
        name
        description
      }
      imageKey
      imageUrl
      color {
        id
        name
        description
        code
      }
    }
  }
`;
export const updateProductImage = /* GraphQL */ `
  mutation UpdateProductImage($id: ID!, $input: ProductImageInput) {
    updateProductImage(id: $id, input: $input) {
      id
      product {
        id
        name
        sku
        description
        baseUnitPrice
        brand {
          id
          name
          imageUrl
        }
        gender {
          id
          name
          description
        }
        productType {
          id
          name
          description
        }
        productStatus {
          id
          name
          description
        }
        clients {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        sizes {
          productId
          sizeId
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
        }
        benchmarkMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        coreMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        fitForm {
          id
          name
          gender {
            id
            name
            description
          }
          fitFormTypeId
          fitFormType {
            id
            name
            description
          }
        }
        idealSize {
          id
          shortName
          longName
          rank
        }
        loosePreferenceSize {
          id
          shortName
          longName
          rank
        }
        tightPreferenceSize {
          id
          shortName
          longName
          rank
        }
        fitParameters {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          measurement {
            id
            name
            description
          }
          idealSizeAdjustment
          looseSizeAdjustment
          tightSizeAdjustment
          isFitPoint
          fitWeighting
        }
        images {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          productImageType {
            id
            name
            description
          }
          imageKey
          imageUrl
          color {
            id
            name
            description
            code
          }
        }
      }
      productImageType {
        id
        name
        description
      }
      imageKey
      imageUrl
      color {
        id
        name
        description
        code
      }
    }
  }
`;
export const deleteProductImage = /* GraphQL */ `
  mutation DeleteProductImage($id: ID!) {
    deleteProductImage(id: $id) {
      id
    }
  }
`;
export const manageProductImageFile = /* GraphQL */ `
  mutation ManageProductImageFile($productId: ID!, $imageKey: String!) {
    manageProductImageFile(productId: $productId, imageKey: $imageKey) {
      downloadUrl
      uploadUrl
      fileKey
    }
  }
`;
export const createProductMeasurement = /* GraphQL */ `
  mutation CreateProductMeasurement($input: ProductMeasurementInput) {
    createProductMeasurement(input: $input) {
      id
      product {
        id
        name
        sku
        description
        baseUnitPrice
        brand {
          id
          name
          imageUrl
        }
        gender {
          id
          name
          description
        }
        productType {
          id
          name
          description
        }
        productStatus {
          id
          name
          description
        }
        clients {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        sizes {
          productId
          sizeId
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
        }
        benchmarkMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        coreMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        fitForm {
          id
          name
          gender {
            id
            name
            description
          }
          fitFormTypeId
          fitFormType {
            id
            name
            description
          }
        }
        idealSize {
          id
          shortName
          longName
          rank
        }
        loosePreferenceSize {
          id
          shortName
          longName
          rank
        }
        tightPreferenceSize {
          id
          shortName
          longName
          rank
        }
        fitParameters {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          measurement {
            id
            name
            description
          }
          idealSizeAdjustment
          looseSizeAdjustment
          tightSizeAdjustment
          isFitPoint
          fitWeighting
        }
        images {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          productImageType {
            id
            name
            description
          }
          imageKey
          imageUrl
          color {
            id
            name
            description
            code
          }
        }
      }
      size {
        id
        shortName
        longName
        rank
      }
      measurement {
        id
        name
        description
        measurementType {
          id
          name
          description
        }
      }
      isBenchmarkMeasurement
      value
      gradeRule
      isHalfMeasurement
    }
  }
`;
export const updateProductMeasurement = /* GraphQL */ `
  mutation UpdateProductMeasurement($id: ID!, $input: ProductMeasurementInput) {
    updateProductMeasurement(id: $id, input: $input) {
      id
      product {
        id
        name
        sku
        description
        baseUnitPrice
        brand {
          id
          name
          imageUrl
        }
        gender {
          id
          name
          description
        }
        productType {
          id
          name
          description
        }
        productStatus {
          id
          name
          description
        }
        clients {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        sizes {
          productId
          sizeId
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
        }
        benchmarkMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        coreMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        fitForm {
          id
          name
          gender {
            id
            name
            description
          }
          fitFormTypeId
          fitFormType {
            id
            name
            description
          }
        }
        idealSize {
          id
          shortName
          longName
          rank
        }
        loosePreferenceSize {
          id
          shortName
          longName
          rank
        }
        tightPreferenceSize {
          id
          shortName
          longName
          rank
        }
        fitParameters {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          measurement {
            id
            name
            description
          }
          idealSizeAdjustment
          looseSizeAdjustment
          tightSizeAdjustment
          isFitPoint
          fitWeighting
        }
        images {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          productImageType {
            id
            name
            description
          }
          imageKey
          imageUrl
          color {
            id
            name
            description
            code
          }
        }
      }
      size {
        id
        shortName
        longName
        rank
      }
      measurement {
        id
        name
        description
        measurementType {
          id
          name
          description
        }
      }
      isBenchmarkMeasurement
      value
      gradeRule
      isHalfMeasurement
    }
  }
`;
export const deleteProductMeasurement = /* GraphQL */ `
  mutation DeleteProductMeasurement($id: ID!) {
    deleteProductMeasurement(id: $id) {
      id
    }
  }
`;
export const createProductFitParameter = /* GraphQL */ `
  mutation CreateProductFitParameter($input: ProductFitParameterInput) {
    createProductFitParameter(input: $input) {
      id
      product {
        id
        name
        sku
        description
        baseUnitPrice
        brand {
          id
          name
          imageUrl
        }
        gender {
          id
          name
          description
        }
        productType {
          id
          name
          description
        }
        productStatus {
          id
          name
          description
        }
        clients {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        sizes {
          productId
          sizeId
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
        }
        benchmarkMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        coreMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        fitForm {
          id
          name
          gender {
            id
            name
            description
          }
          fitFormTypeId
          fitFormType {
            id
            name
            description
          }
        }
        idealSize {
          id
          shortName
          longName
          rank
        }
        loosePreferenceSize {
          id
          shortName
          longName
          rank
        }
        tightPreferenceSize {
          id
          shortName
          longName
          rank
        }
        fitParameters {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          measurement {
            id
            name
            description
          }
          idealSizeAdjustment
          looseSizeAdjustment
          tightSizeAdjustment
          isFitPoint
          fitWeighting
        }
        images {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          productImageType {
            id
            name
            description
          }
          imageKey
          imageUrl
          color {
            id
            name
            description
            code
          }
        }
      }
      measurement {
        id
        name
        description
        measurementType {
          id
          name
          description
        }
      }
      idealSizeAdjustment
      looseSizeAdjustment
      tightSizeAdjustment
      isFitPoint
      fitWeighting
    }
  }
`;
export const updateProductFitParameter = /* GraphQL */ `
  mutation UpdateProductFitParameter(
    $id: ID!
    $input: ProductFitParameterInput
  ) {
    updateProductFitParameter(id: $id, input: $input) {
      id
      product {
        id
        name
        sku
        description
        baseUnitPrice
        brand {
          id
          name
          imageUrl
        }
        gender {
          id
          name
          description
        }
        productType {
          id
          name
          description
        }
        productStatus {
          id
          name
          description
        }
        clients {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        sizes {
          productId
          sizeId
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
        }
        benchmarkMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        coreMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        fitForm {
          id
          name
          gender {
            id
            name
            description
          }
          fitFormTypeId
          fitFormType {
            id
            name
            description
          }
        }
        idealSize {
          id
          shortName
          longName
          rank
        }
        loosePreferenceSize {
          id
          shortName
          longName
          rank
        }
        tightPreferenceSize {
          id
          shortName
          longName
          rank
        }
        fitParameters {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          measurement {
            id
            name
            description
          }
          idealSizeAdjustment
          looseSizeAdjustment
          tightSizeAdjustment
          isFitPoint
          fitWeighting
        }
        images {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          productImageType {
            id
            name
            description
          }
          imageKey
          imageUrl
          color {
            id
            name
            description
            code
          }
        }
      }
      measurement {
        id
        name
        description
        measurementType {
          id
          name
          description
        }
      }
      idealSizeAdjustment
      looseSizeAdjustment
      tightSizeAdjustment
      isFitPoint
      fitWeighting
    }
  }
`;
export const deleteProductFitParameter = /* GraphQL */ `
  mutation DeleteProductFitParameter($id: ID!) {
    deleteProductFitParameter(id: $id) {
      id
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand($input: BrandInput) {
    createBrand(input: $input) {
      id
      name
      imageUrl
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand($id: ID!, $input: BrandInput) {
    updateBrand(id: $id, input: $input) {
      id
      name
      imageUrl
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand($id: ID!) {
    deleteBrand(id: $id) {
      id
    }
  }
`;
export const createProductType = /* GraphQL */ `
  mutation CreateProductType($input: ProductTypeInput) {
    createProductType(input: $input) {
      id
      name
      description
    }
  }
`;
export const updateProductType = /* GraphQL */ `
  mutation UpdateProductType($id: ID!, $input: ProductTypeInput) {
    updateProductType(id: $id, input: $input) {
      id
      name
      description
    }
  }
`;
export const deleteProductType = /* GraphQL */ `
  mutation DeleteProductType($id: ID!) {
    deleteProductType(id: $id) {
      id
    }
  }
`;
export const createOrganizationProduct = /* GraphQL */ `
  mutation CreateOrganizationProduct($input: OrganizationProductInput) {
    createOrganizationProduct(input: $input) {
      productId
      organizationId
      product {
        id
        name
        sku
        description
        baseUnitPrice
        brand {
          id
          name
          imageUrl
        }
        gender {
          id
          name
          description
        }
        productType {
          id
          name
          description
        }
        productStatus {
          id
          name
          description
        }
        clients {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        sizes {
          productId
          sizeId
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
        }
        benchmarkMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        coreMeasurements {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          size {
            id
            shortName
            longName
            rank
          }
          measurement {
            id
            name
            description
          }
          isBenchmarkMeasurement
          value
          gradeRule
          isHalfMeasurement
        }
        fitForm {
          id
          name
          gender {
            id
            name
            description
          }
          fitFormTypeId
          fitFormType {
            id
            name
            description
          }
        }
        idealSize {
          id
          shortName
          longName
          rank
        }
        loosePreferenceSize {
          id
          shortName
          longName
          rank
        }
        tightPreferenceSize {
          id
          shortName
          longName
          rank
        }
        fitParameters {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          measurement {
            id
            name
            description
          }
          idealSizeAdjustment
          looseSizeAdjustment
          tightSizeAdjustment
          isFitPoint
          fitWeighting
        }
        images {
          id
          product {
            id
            name
            sku
            description
            baseUnitPrice
          }
          productImageType {
            id
            name
            description
          }
          imageKey
          imageUrl
          color {
            id
            name
            description
            code
          }
        }
      }
      organization {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
    }
  }
`;
export const deleteOrganizationProduct = /* GraphQL */ `
  mutation DeleteOrganizationProduct($organizationId: ID!, $productId: ID!) {
    deleteOrganizationProduct(
      organizationId: $organizationId
      productId: $productId
    ) {
      organizationId
      productId
    }
  }
`;
export const createColor = /* GraphQL */ `
  mutation CreateColor($input: ColorInput) {
    createColor(input: $input) {
      id
      name
      description
      code
    }
  }
`;
export const updateColor = /* GraphQL */ `
  mutation UpdateColor($id: ID!, $input: ColorInput) {
    updateColor(id: $id, input: $input) {
      id
      name
      description
      code
    }
  }
`;
export const deleteColor = /* GraphQL */ `
  mutation DeleteColor($id: ID!) {
    deleteColor(id: $id) {
      id
    }
  }
`;
export const createProductImageType = /* GraphQL */ `
  mutation CreateProductImageType($input: ProductImageTypeInput) {
    createProductImageType(input: $input) {
      id
      name
      description
    }
  }
`;
export const updateProductImageType = /* GraphQL */ `
  mutation UpdateProductImageType($id: ID!, $input: ProductImageTypeInput) {
    updateProductImageType(id: $id, input: $input) {
      id
      name
      description
    }
  }
`;
export const deleteProductImageType = /* GraphQL */ `
  mutation DeleteProductImageType($id: ID!) {
    deleteProductImageType(id: $id) {
      id
    }
  }
`;
export const createFitSessionResult = /* GraphQL */ `
  mutation CreateFitSessionResult($input: FitSessionResultInput) {
    createFitSessionResult(input: $input) {
      id
      customerId
      productId
      sizeId
      measurementId
      fitTechnicianNormalizedScore
      fitTechnicianInputDate
      isValid
    }
  }
`;
export const updateFitSessionResult = /* GraphQL */ `
  mutation UpdateFitSessionResult($id: ID!, $input: FitSessionResultInput) {
    updateFitSessionResult(id: $id, input: $input) {
      id
      customerId
      productId
      sizeId
      measurementId
      fitTechnicianNormalizedScore
      fitTechnicianInputDate
      isValid
    }
  }
`;
export const deleteFitSessionResult = /* GraphQL */ `
  mutation DeleteFitSessionResult($id: ID!) {
    deleteFitSessionResult(id: $id) {
      id
      customerId
      productId
      sizeId
      measurementId
      fitTechnicianNormalizedScore
      fitTechnicianInputDate
      isValid
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CustomerInput) {
    createCustomer(input: $input) {
      id
      nameFirst
      nameLast
      email
      phoneNumber
      postalCode
      gender {
        id
        name
        description
      }
      createdAt
      bodyMeasurementsComplete
      fitSessionCompleted
      country {
        id
        code
        description
        continent
      }
      user {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
      organization {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
      sizeStreamAccountId
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($id: ID!, $input: CustomerInput) {
    updateCustomer(id: $id, input: $input) {
      id
      nameFirst
      nameLast
      email
      phoneNumber
      postalCode
      gender {
        id
        name
        description
      }
      createdAt
      bodyMeasurementsComplete
      fitSessionCompleted
      country {
        id
        code
        description
        continent
      }
      user {
        id
        userName
        nameFirst
        nameLast
        email
        phone
        memberships {
          id
          user {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          organization {
            id
            name
            contactName
            contactPhone
            contactEmail
            notes
            customerCount
            productCount
            userCount
            createdAt
            updatedAt
          }
          role {
            id
            name
            description
          }
          bodyMeasurementsComplete
          fitSessionComplete
          createdAt
        }
      }
      organization {
        id
        name
        contactName
        contactPhone
        contactEmail
        notes
        organizationType {
          id
          name
          description
        }
        customerCount
        productCount
        userCount
        createdAt
        updatedAt
        createdBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        updatedBy {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
      }
      sizeStreamAccountId
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      id
    }
  }
`;
export const manageCustomerFile = /* GraphQL */ `
  mutation ManageCustomerFile($customerId: ID!, $fileKey: String!) {
    manageCustomerFile(customerId: $customerId, fileKey: $fileKey) {
      downloadUrl
      uploadUrl
      fileKey
    }
  }
`;
export const manageUploadUsersFile = /* GraphQL */ `
  mutation manageUploadUsersFile($fileKey: String!) {
    manageUploadUsersFile(fileKey: $fileKey) {
      downloadUrl
      uploadUrl
      fileKey
    }
  }
`;
export const importTg3dCustomerMeasurements = /* GraphQL */ `
  mutation ImportTg3dCustomerMeasurements($customerId: ID!, $fileKey: String!) {
    importTg3dCustomerMeasurements(customerId: $customerId, fileKey: $fileKey) {
      measurementsUpdated
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($id: ID!, $input: UserInput) {
    updateUser(id: $id, input: $input) {
      id
      userName
      nameFirst
      nameLast
      email
      phone
      memberships {
        id
        user {
          id
          userName
          nameFirst
          nameLast
          email
          phone
          memberships {
            id
            bodyMeasurementsComplete
            fitSessionComplete
            createdAt
          }
        }
        organization {
          id
          name
          contactName
          contactPhone
          contactEmail
          notes
          organizationType {
            id
            name
            description
          }
          customerCount
          productCount
          userCount
          createdAt
          updatedAt
          createdBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
          updatedBy {
            id
            userName
            nameFirst
            nameLast
            email
            phone
          }
        }
        role {
          id
          name
          description
        }
        bodyMeasurementsComplete
        fitSessionComplete
        createdAt
      }
    }
  }
`;
export const initiateJob = /* GraphQL */ `
  mutation InitiateJob($jobKey: String!, $payload: AWSJSON) {
    initiateJob(jobKey: $jobKey, payload: $payload) {
      id
      status
    }
  }
`;
export const publishResult = /* GraphQL */ `
  mutation PublishResult($id: ID!, $status: ResultStatus, $output: AWSJSON) {
    publishResult(id: $id, status: $status, output: $output) {
      id
      status
    }
  }
`;
export const createScannerUser = /* GraphQL */ `
  mutation CreateScannerUser(
    $input: ScannerUserInput!
    $tokenInput: SessionTokenInput!
  ) {
    createScannerUser(input: $input, tokenInput: $tokenInput) {
      phoneNumber
      nameFirst
      nameLast
      id
    }
  }
`;
export const sendDynamicLink = /* GraphQL */ `
  mutation SendDynamicLink(
    $phoneNumber: AWSPhone!
    $tokenInput: SessionTokenInput!
  ) {
    sendDynamicLink(phoneNumber: $phoneNumber, tokenInput: $tokenInput) {
      success
    }
  }
`;
export const sendOTP = /* GraphQL */ `
  mutation SendOTP($phoneNumber: AWSPhone!, $source: String!) {
    sendOTP(phoneNumber: $phoneNumber, source: $source) {
      success
    }
  }
`;
export const createSessionToken = /* GraphQL */ `
  mutation CreateSessionToken($phoneNumber: AWSPhone!) {
    createSessionToken(phoneNumber: $phoneNumber) {
      sessionId
    }
  }
`;
export const createSessionFromOTP = /* GraphQL */ `
  mutation CreateSessionFromOTP(
    $phoneNumber: AWSPhone!
    $otp: String!
    $source: String!
  ) {
    createSessionFromOTP(
      phoneNumber: $phoneNumber
      otp: $otp
      source: $source
    ) {
      success
      token
    }
  }
`;
export const saveSizestreamMeasures = /* GraphQL */ `
  mutation SaveSizestreamMeasures(
    $sizeStreamAccountId: ID!
    $rawCoreJson: String!
    $rawPassenCustomJson: String!
  ) {
    saveSizestreamMeasures(
      sizeStreamAccountId: $sizeStreamAccountId
      rawCoreJson: $rawCoreJson
      rawPassenCustomJson: $rawPassenCustomJson
    ) {
      measurementsUpdated
    }
  }
`;
export const updateCustomerSizestreamMeasurements = /* GraphQL */ `
  mutation UpdateCustomerSizestreamMeasurements($sizeStreamAccountId: ID!) {
    updateCustomerSizestreamMeasurements(
      sizeStreamAccountId: $sizeStreamAccountId
    ) {
      measurementsUpdated
    }
  }
`;
export const updateCustomerMeasurementOnFitSessionResults = /* GraphQL */ `
  mutation UpdateCustomerMeasurementOnFitSessionResults(
    $input: FitSessionMeasurementUpdate
  ) {
    updateCustomerMeasurementOnFitSessionResults(input: $input) {
      updatedValue
      success
      message
    }
  }
`;
