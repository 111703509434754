import { Injectable } from '@angular/core';

import API, { graphqlOperation } from '@aws-amplify/api';
import { customersCustom,
         customerCustom,
         updateCustomerCustom,
         createCustomerCustom,
         customerMeasurementsCustom
        } from '../../graphql/custom';

import { CustomersQuery,
         CustomerQuery,
         UpdateCustomerMutation,
         CreateCustomerMutation,
         FilterInput,
         CustomersQueryVariables,
         Condition,
         Conjunction,
         FilterCondition,
         ManageCustomerFileMutationVariables,
         ManageCustomerFileMutation,
         ManageUploadUsersFileMutation,
         ManageUploadUsersFileMutationVariables,
         ImportTg3dCustomerMeasurementsMutationVariables,
         ImportTg3dCustomerMeasurementsMutation,
         CustomerMeasurementsQueryVariables,
         CustomerMeasurementsQuery,
         FitSessionResultsQuery,
         UpdateFitSessionResultMutation,
         CreateFitSessionResultMutation,
         UpdateCustomerSizestreamMeasurementsMutationVariables,
         UpdateCustomerSizestreamMeasurementsMutation,
         GetRecommendationsRequestsQuery,
         GetRecommendationsRequestsQueryVariables,
         GetBodyModelUrlQuery,
         GetBodyModelUrlQueryVariables
        } from '../../API';

import { GenderModel,
         OrganizationModel,
         CountryModel,
         MeasurementModel
  } from './lookupmodel.service'

import { createFitSessionResult, importTg3dCustomerMeasurements, manageCustomerFile, manageUploadUsersFile, updateCustomerSizestreamMeasurements, updateFitSessionResult } from 'src/graphql/mutations';
import { fitSessionResults,
         getRecommendationsRequests,
         getBodyModelUrl
         } from 'src/graphql/queries';

import { AuthService } from '../services/auth.service';

@Injectable()
export class CustomerModelService {
  customerItemsList:CustomerModel[] = [];
  activeCustomer:CustomerModel;

  fitSessionResultsList:FitSessionResultModel[] = [];
  constructor(private authService: AuthService) { }

  async getBodyModelUrl(sizeStreamAccountId: string): Promise<string | null> {
    try {
      const variables: GetBodyModelUrlQueryVariables = {
        sizeStreamAccountId: sizeStreamAccountId,
      };
      const response = (await API.graphql(
        graphqlOperation(getBodyModelUrl, variables)
      )) as { data: GetBodyModelUrlQuery };

      if (!response.data || !response.data.getBodyModelUrl) {
        throw new Error('No URL found for the given sizeStreamAccountId');
      }

      return response.data.getBodyModelUrl.url;
    } catch (err) {
      console.error('Error fetching body model URL:', err);
      throw err;
    }
  }

  async loadUniqueBrandNames(customerId: string): Promise<string[]> {
    try {
      const variables: GetRecommendationsRequestsQueryVariables = {
        customerId: customerId
      };

      const response = (await API.graphql(graphqlOperation(getRecommendationsRequests, variables))) as {
        data: GetRecommendationsRequestsQuery;
      };

      if (!response.data || !response.data.getRecommendationsRequests || response.data.getRecommendationsRequests.length === 0) {
        console.log(`No recommendations found for customer_id: ${customerId}`);
        return [];
      }

      const recomRequests = response.data.getRecommendationsRequests;

      const formattedRequests = recomRequests.map(rec => ({
        brandId: rec.brandId,
        brandName: rec.brandName,
        productId: rec.productId,
      }));

      const uniqueBrands = Array.from(new Set(formattedRequests.map(rec => rec.brandName)));

      return uniqueBrands;
    } catch (err) {
      console.error('Error loading recommendation requests:', err);
      return [];
    }
  }

  async loadUniqueBrandIds(customerId: string): Promise<string[]> {
    try {
      const variables: GetRecommendationsRequestsQueryVariables = {
        customerId: customerId
      };

      const response = (await API.graphql(graphqlOperation(getRecommendationsRequests, variables))) as {
        data: GetRecommendationsRequestsQuery;
      };

      if (!response.data || !response.data.getRecommendationsRequests || response.data.getRecommendationsRequests.length === 0) {
        console.log(`No recommendations found for customer_id: ${customerId}`);
        return [];
      }

      const recomRequests = response.data.getRecommendationsRequests;

      const formattedRequests = recomRequests.map(rec => ({
        brandId: rec.brandId,
        brandName: rec.brandName,
        productId: rec.productId,
      }));

      const uniqueBrands = Array.from(new Set(formattedRequests.map(rec => rec.brandId)));

      return uniqueBrands;
    } catch (err) {
      console.error('Error loading recommendation requests:', err);
      return [];
    }
  }


  async loadFitSessionResultsList(customerId, productId){

    this.fitSessionResultsList = [];

    const conditions: FilterCondition[] = [
      {
        field: "customerId",
        operator: Condition.EQUAL,
        value: customerId
      },
      {
        field: "productId",
        operator: Condition.EQUAL,
        value: productId
      }];

    let filterDict: CustomersQueryVariables = {
      "limit": 500
      // TODO actually use pagination
    };

    filterDict.filter = {
      conjunction: Conjunction.AND,
      conditions: conditions
    };


    try {
      const response = (await API.graphql(graphqlOperation(fitSessionResults, filterDict))) as {
        data: FitSessionResultsQuery;
      };
      // console.log('Customers',response);
      for(let item of response.data.fitSessionResults) {
        let c = new FitSessionResultModel();
        c.load(item);
        this.fitSessionResultsList.push(c);
      }
    }
    catch(err) {
      console.log('error loading fit session results list',err);
      throw(err)
    }

    return this.fitSessionResultsList;
  }

  async loadCustomerList(forcereload = false, orgIds = []) {
    if (this.customerItemsList.length === 0 || forcereload === true) {
      this.customerItemsList = [];

      let filterDict: CustomersQueryVariables = {
        limit: 500
      };

      try {
        const response = (await API.graphql(graphqlOperation(customersCustom, filterDict))) as {
          data: CustomersQuery;
        };

        const allCustomers = response.data.customers.map(item => {
          let customer = new CustomerModel();
          customer.load(item);
          return customer;
        });

        if (!this.authService.activeUser?.isAdmin()) {
          orgIds = orgIds || this.authService.activeUser.organizationIds();

          this.customerItemsList = allCustomers.filter(customer =>
            orgIds.includes(customer.organizationId?.toString()) ||
            customer.retailerIds?.some(retailerId => orgIds.includes(retailerId))
          );
          console.log("I am here", this.customerItemsList)
        } else {
          this.customerItemsList = allCustomers;
        }
      } catch (err) {
        console.log("Error loading customer list", err);
        throw err;
      }
    }
    return this.customerItemsList;
  }



  async loadCustomersByName(customerName: string) {
    let filterDict = {
      "filter": {
        "conjunction": "OR",
        "conditions": [{ "field": "nameFirst", "operator": "LIKE", "value": `%${customerName.toLowerCase()}%` }, { "field": "nameLast", "operator": "LIKE", "value": `%${customerName}%` }]
      },
      "limit": 500
    };

    try {
      const response = (await API.graphql(graphqlOperation(customersCustom, filterDict))) as {
        data: CustomersQuery;
      };
      this.customerItemsList = [];
      for(let item of response.data.customers) {
        let c = new CustomerModel();
        c.load(item);
        this.customerItemsList.push(c);
      }
    }
    catch(err) {
      console.log('error filtering customer list', err);
      throw(err)
    }
  }

  updateItemsList(customer:CustomerModel) {
    let found = false;
    let index = this.customerItemsList.findIndex((el)=>{return el.id==customer.id});
    index >= 0 ? this.customerItemsList[index] = customer : this.customerItemsList.push(customer);
  }


  async getCustomerFileUploadDetails(fileKey: string) {
    if (this.activeCustomer) {
      try {
        const queryVariables: ManageCustomerFileMutationVariables = {
          customerId: this.activeCustomer.id,
          fileKey: fileKey
        }
        const response = (await API.graphql(graphqlOperation(manageCustomerFile, queryVariables)) as {
          data: ManageCustomerFileMutation;
        });

        const fileResponse = response.data.manageCustomerFile;
        return {
          url: fileResponse.uploadUrl,
          fileKey: fileResponse.fileKey
        };
      }
      catch(err) {
        var errorMsg = err.errors != undefined ? err.errors[0].message : err;
        console.log('error getting product image signed urls.',err);
        throw(errorMsg)
      }
    }
  }

  async getUploadUsersFileUploadDetails(fileKey: string) {
      try {
        const queryVariables: ManageUploadUsersFileMutationVariables = {
          fileKey: fileKey
        }
        const response = (await API.graphql(graphqlOperation(manageUploadUsersFile, queryVariables)) as {
          data: ManageUploadUsersFileMutation;
        });

        const fileResponse = response.data.manageUploadUsersFile;
        return {
          url: fileResponse.uploadUrl,
          fileKey: fileResponse.fileKey
        };
      }
      catch(err) {
        var errorMsg = err.errors != undefined ? err.errors[0].message : err;
        console.log('error getting product image signed urls.',err);
        throw(errorMsg)
      }
  }

  async importTg3dCustomerMeasurements(fileKey: string) {
    if (this.activeCustomer) {
      try {
        const queryVariables: ImportTg3dCustomerMeasurementsMutationVariables = {
          customerId: this.activeCustomer.id,
          fileKey: fileKey
        }
        const response = (await API.graphql(graphqlOperation(importTg3dCustomerMeasurements, queryVariables)) as {
          data: ImportTg3dCustomerMeasurementsMutation;
        });

        const fileResponse = response.data.importTg3dCustomerMeasurements;
        console.log(fileResponse);
      }
      catch(err) {
        var errorMsg = err.errors != undefined ? err.errors[0].message : err;
        console.log('error getting product image signed urls.',err);
        throw(errorMsg)
      }
    }
  }

  async loadCustomerMeasurements() {
    if (this.activeCustomer) {
      try {
        const queryVariables: CustomerMeasurementsQueryVariables = {
          customerId: this.activeCustomer.id,
          limit: 250
        }
        const response = (await API.graphql(graphqlOperation(customerMeasurementsCustom, queryVariables)) as {
          data: CustomerMeasurementsQuery;
        });

        const result = response.data.customerMeasurements;
        this.activeCustomer.measurements = result.map(measurement => {
          return {
            measurement: new MeasurementModel().load(measurement.measurement),
            value: measurement.value
          }
        });
      }
      catch(err) {
        var errorMsg = err.errors != undefined ? err.errors[0].message : err;
        console.log('error getting product image signed urls.',err);
        throw(errorMsg)
      }
    }
  }
  async updateCustomerMeasurements() {
  console.log(this.activeCustomer)
    if (this.activeCustomer) {
      try {
        const queryVariables: UpdateCustomerSizestreamMeasurementsMutationVariables = {
          sizeStreamAccountId: this.activeCustomer.sizeStreamAccountId,
        }
        const response = (await API.graphql(graphqlOperation(updateCustomerSizestreamMeasurements, queryVariables)) as {
          data: UpdateCustomerSizestreamMeasurementsMutation;
        });

        const result = response.data.updateCustomerSizestreamMeasurements.measurementsUpdated;

        return {
          measurementsUpdated: result
        }
      }
      catch(err) {
        var errorMsg = err.errors != undefined ? err.errors[0].message : err;
        console.log('error updating customer measurements.',err);
        throw(errorMsg)
      }
    }
  }

}

export class CustomerModel {
  id:string;
  nameFirst:string;
  nameLast:string;
  phoneNumber:string;
  email:string;
  postalCode:string;
  bodyMeasurementsComplete:boolean;
  fitSessionCompleted:boolean;
  createdAt:string;
  organizationId:number;
  genderId:number;
  city:string;
  countryId:number;
  sizeStreamAccountId: string;

  // New properties
  age: number;
  height: number;
  weight: number;
  retailerIds: string[];

  client: OrganizationModel;
  gender: GenderModel;
  country: CountryModel;
  measurements: CustomerMeasurementModel[];

  constructor() { }

  load(item: {}) {
    this.id = item['id'] != undefined ? item['id'] : "";
    this.nameFirst = item['nameFirst'] != undefined ? item['nameFirst'] : "";
    this.nameLast = item['nameLast'] != undefined ? item['nameLast'] : "";
    this.phoneNumber = item['phoneNumber'] != undefined ? item['phoneNumber'] : undefined;
    this.email = item['email'] != undefined ? item['email'] : undefined;
    this.bodyMeasurementsComplete = item['bodyMeasurementsComplete'] != undefined ? item['bodyMeasurementsComplete'] : undefined;
    this.fitSessionCompleted = item['fitSessionCompleted'] != undefined ? item['fitSessionCompleted'] : undefined;
    this.createdAt = item['createdAt'] != undefined ? item['createdAt'] : undefined;
    this.postalCode = item['postalCode'] != undefined ? item['postalCode'] : undefined;
    this.city = item['city'] != undefined ? item['city'] : undefined;
    this.sizeStreamAccountId = item['sizeStreamAccountId'] != undefined ? item['sizeStreamAccountId'] : undefined;

    // Load new properties
    this.age = item['age'] != undefined ? item['age'] : undefined;
    this.height = item['height'] != undefined ? item['height'] : undefined;
    this.weight = item['weight'] != undefined ? item['weight'] : undefined;
    this.retailerIds = item['retailerIds'] != undefined ? item['retailerIds'] : [];


    if(item['gender'] != undefined && item['gender'].id != undefined) {
      this.gender = new GenderModel().load(item['gender']);
      this.genderId = this.gender.id;
    }

    if(item['organization'] != undefined && item['organization'].id != undefined) {
      this.client = new OrganizationModel().load(item['organization']);
      this.organizationId = this.client.id;
    }

    if(item['country'] != undefined && item['country'].id != undefined) {
      this.country = new CountryModel().load(item['country']);
      this.countryId = this.country.id;
    }
    console.log(item)
  }

  async get(id:string) {
    try{
      this.id = id;

      const response = (await API.graphql(graphqlOperation(customerCustom,{id:this.id}))) as {
        data: CustomerQuery
      };

      console.log('get',response);

      if(response.data == undefined || response.data.customer == undefined) {
        throw new Error('Error getting customer. Contact your administrator.');
      }
      this.load(response.data.customer);
      return this;
    }
    catch(err) {
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error getting customer',err);
      throw(errorMsg)
    }
  }

  async update() {
    if(this.id == undefined || this.id.length == 0) {
      return this.create();
    }

    try{
      const response = (await API.graphql(graphqlOperation(updateCustomerCustom,{id:this.id,input:this.getUpdateVars()}))) as {
        data: UpdateCustomerMutation;
      };

      if(response.data == undefined || response.data.updateCustomer == undefined) {
        throw new Error('Error updating customer. Contact your administrator.');
      }
      // console.log('customer update',response);
      this.load(response.data.updateCustomer);
      return this;
    }
    catch(err) {
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating customer',err);
      throw(errorMsg)
    }
  }

  async create() {
    let updateVars = this.getUpdateVars();

    try{
      const response = (await API.graphql(graphqlOperation(createCustomerCustom,{input:updateVars}))) as {
        data: CreateCustomerMutation;
      };

      if(response.data == undefined || response.data.createCustomer == undefined) {
        throw new Error('Error creating customer. Contact your administrator.');
      }
      // console.log('customer create',response);
      this.load(response.data.createCustomer);
      return this;
    }
    catch(err) {
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error creating customer',err);
      throw(errorMsg)
    }
  }

  getUpdateVars() {
    var returnValue = {};
    this.nameFirst != undefined ? returnValue['nameFirst'] = this.nameFirst : "";
    this.nameLast != undefined ? returnValue['nameLast'] = this.nameLast : "";
    this.phoneNumber != undefined ? returnValue['phoneNumber'] = this.phoneNumber : "";
    this.email != undefined ? returnValue['email'] = this.email : "";
    this.postalCode != undefined ? returnValue['postalCode'] = this.postalCode : "";
    this.bodyMeasurementsComplete != undefined ? returnValue['bodyMeasurementsComplete'] = this.bodyMeasurementsComplete : "";
    this.fitSessionCompleted != undefined ? returnValue['fitSessionCompleted'] = this.fitSessionCompleted : "";
    this.organizationId != undefined ? returnValue['organizationId'] = this.organizationId : "";
    this.genderId != undefined ? returnValue['genderId'] = this.genderId : "";
    this.city != undefined ? returnValue['city'] = this.city : "";
    this.countryId != undefined ? returnValue['countryId'] = this.countryId : "";
    this.age != undefined ? returnValue['age'] = this.age : "";
    this.height != undefined ? returnValue['height'] = this.height : "";
    this.weight != undefined ? returnValue['weight'] = this.weight : "";
    return returnValue;
  }

  getFullName() {
    var returnValue = "";
    returnValue = this.nameFirst != undefined ? this.nameFirst + " " : "";
    returnValue = returnValue + (this.nameLast != undefined ? this.nameLast : "");
    return returnValue;
  }

}

export class CustomerMeasurementModel {
  measurement: MeasurementModel;
  value: number;
}
export class FitSessionResultModel {
  id: string;
  customerId: string | null;
  productId: string | null;
  sizeId: string | null;
  measurementId: string | null;
  fitTechnicianNormalizedScore: number | null;
  fitTechnicianInputDate: string | null;
  isValid: boolean | null;



  constructor() { }

  load(item:{}) {
    this.id = item['id'] != undefined ? item['id'] : "";
    this.customerId = item['customerId'] != undefined ? item['customerId'] : "";
    this.productId = item['productId'] != undefined ? item['productId'] : "";
    this.sizeId = item['sizeId'] != undefined ? item['sizeId'] : undefined;
    this.measurementId = item['measurementId'] != undefined ? item['measurementId'] : undefined;
    this.fitTechnicianNormalizedScore = item['fitTechnicianNormalizedScore'] != undefined ? item['fitTechnicianNormalizedScore'] : undefined;
    this.fitTechnicianInputDate = item['fitTechnicianInputDate'] != undefined ? item['fitTechnicianInputDate'] : undefined;
    this.isValid = item['isValid'] != undefined ? item['isValid'] : undefined;
  }

  async update() {
    if(this.id == undefined || this.id.length == 0) {
      return this.create();
    }

    try{
      const response = (await API.graphql(graphqlOperation(updateFitSessionResult,{id:this.id,input:this.getUpdateVars()}))) as {
        data: UpdateFitSessionResultMutation;
      };

      if(response.data == undefined || response.data.updateFitSessionResult == undefined) {
        throw new Error('Error updating fit session result. Contact your administrator.');
      }
      // console.log('customer update',response);
      this.load(response.data.updateFitSessionResult);
      return this;
    }
    catch(err) {
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating fit session result',err);
      throw(errorMsg)
    }
  }

  async create() {

    let updateVars = this.getUpdateVars();

    try{
      const response = (await API.graphql(graphqlOperation(createFitSessionResult,{input:updateVars}))) as {
        data: CreateFitSessionResultMutation;
      };

      if(response.data == undefined || response.data.createFitSessionResult == undefined) {
        throw new Error('Error creating fit session result. Contact your administrator.');
      }
      // console.log('customer create',response);
      this.load(response.data.createFitSessionResult);
      return this;
    }
    catch(err) {
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error creating customer',err);
      throw(errorMsg)
    }
  }

  getUpdateVars() {
    // TODO define the return type of this (and similar) functions
    var returnValue = {};

    this.customerId != undefined ? returnValue['customerId'] = this.customerId : "";
    this.productId != undefined ? returnValue['productId'] = this.productId : "";
    this.sizeId != undefined ? returnValue['sizeId'] = this.sizeId : "";
    this.measurementId != undefined ? returnValue['measurementId'] = this.measurementId : "";
    this.fitTechnicianNormalizedScore != undefined ? returnValue['fitTechnicianNormalizedScore'] = this.fitTechnicianNormalizedScore : "";
    this.isValid != undefined ? returnValue['isValid'] = this.isValid : "";
    return returnValue;
  }

}
