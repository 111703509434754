<div class="container-fluid">
  <div class="row mb-3 mt-3 no-gutters">
    <a [routerLink]="['/products']" class="linkText">&lt; All Products</a>
  </div>
  <div class="row mb-3 mt-3 no-gutters">
    <nav class="entity-home-nav">
      <a
        [routerLink]="['/product/', product.id]"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact: true}"
        class="linkText tab"
      >General Details</a>
    <div *ngIf="isAdmin"> 
      <a
        *ngIf="!isNew; else nonLinkTab1"
        [routerLink]="['/product/', product.id, 'measurements']"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact: true}"
        class="linkText tab"
      >Product Measurements</a>
      <a
        *ngIf="!isNew; else nonLinkTab2"
        [routerLink]="['/product/', product.id, 'fit-parameters']"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact: true}"
        class="linkText tab"
      >Fit Parameters</a>
      <a
        *ngIf="!isNew; else nonLinkTab3"
        [routerLink]="['/product/', product.id, 'client-assignment']"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact: true}"
        class="linkText tab"
      >Client Assignment</a>
      <a
        *ngIf="!isNew; else nonLinkTab3"
        [routerLink]="['/product/', product.id, 'product-assessment']"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact: true}"
        class="linkText tab"
      >Product Fit Point Assesment</a>
      <a
        *ngIf="!isNew; else nonLinkTab3"
        [routerLink]="['/product/', product.id, 'product-fit-recommendation-assesment']"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact: true}"
        class="linkText tab"
      >Product Fit Recommendation Assesment</a>
    </div>
    </nav>
  </div>
  <div class="row mb-3 mt-4 no-gutters">
    <h2 *ngIf="!isNew; else newProductTitle" class="titleText">{{product.name}} <code>(SKU: {{product.sku}})</code></h2>
  </div>
  <router-outlet></router-outlet>
</div>

<ng-template #newProductTitle>
  <h2>New Product</h2>
</ng-template>
<ng-template #nonLinkTab1>
  <span class="linkText tab disabled">Product Measurements</span>
</ng-template>
<ng-template #nonLinkTab2>
  <span class="linkText tab disabled">Fit Parameters</span>
</ng-template>
<ng-template #nonLinkTab3>
  <span class="linkText tab disabled">Client Assignment</span>
</ng-template>
