import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerModel } from 'src/app/model/customermodel.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-customer-home',
  templateUrl: './customer-home.component.html',
  styleUrls: ['./customer-home.component.scss']
})
export class CustomerHomeComponent implements OnInit {

  customer: CustomerModel;
  isAdmin = false;

  constructor(private route: ActivatedRoute, private authService: AuthService) {}

  ngOnInit() {
    this.route.data
      .subscribe((data: {
        customer: CustomerModel,
      }) => {
        this.customer = data.customer;
        this.isAdmin = this.authService.activeUser?.isAdmin();
      });
  }

}
