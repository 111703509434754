import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { from, Observable, of } from "rxjs";
import { map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { BrandModel, FitFormsModel, LookupModelService, MeasurementModel, ProductTypeModel, SizeModel } from "../model/lookupmodel.service";
import { OrganizationModel } from "../model/portaluser.model";
import { ProductModel, ProductmodelService } from "../model/productmodel.service";

@Injectable({
  providedIn: 'root'
})
export class ProductsListResolverService implements Resolve<ProductModel[]> {

  constructor(
    private productModelService: ProductmodelService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ProductModel[]> | Observable<never> {
    return from(this.productModelService.loadCurrentList());
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProductResolverService implements Resolve<ProductModel> {

  constructor(
    private productModelService: ProductmodelService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ProductModel> | Observable<never> {
    const dummyProduct = new ProductModel();

    const productId = state.url.split('/')[2];

    return from(dummyProduct.get(productId)).pipe(
      tap((activeProduct) => this.productModelService.activeProduct = activeProduct)
    );
  }
}

@Injectable({providedIn: 'root'})
export class ProductNewResolverService implements Resolve<ProductModel> {
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): ProductModel {
    return new ProductModel();
  }
}

@Injectable({providedIn: 'root'})
export class BrandListResolver implements Resolve<BrandModel[]> {
  constructor(private lookupModelService: LookupModelService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<BrandModel[]> | Observable<never> {
     return from(this.lookupModelService.getBrands());
  }
}

@Injectable({providedIn: 'root'})
export class ProductTypesResover implements Resolve<ProductTypeModel[]> {
  constructor(private lookupModelService: LookupModelService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ProductTypeModel[]> | Observable<never> {
     return from(this.lookupModelService.getProductTypes());
  }
}

@Injectable({providedIn: 'root'})
export class SizeListResolver implements Resolve<SizeModel[]> {
  constructor(private lookupModelService: LookupModelService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SizeModel[]> | Observable<never> {
     return from(this.lookupModelService.getSizes());
  }
}

@Injectable({providedIn: 'root'})
export class FitFormsResolver implements Resolve<FitFormsModel[]> {
  constructor(private lookupModelService: LookupModelService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<FitFormsModel[]> | Observable<never> {
     return from(this.lookupModelService.getFitForms());
  }
}

@Injectable({providedIn: 'root'})
export class BenchmarkMeasurementListResolver implements Resolve<MeasurementModel[]> {
  constructor(private lookupModelService: LookupModelService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<MeasurementModel[]> | Observable<never> {
     return from(this.lookupModelService.getMeasurements());
  }
}

@Injectable({providedIn: 'root'})
export class ClientListResolver implements Resolve<OrganizationModel[]> {
  constructor(private lookupModelService: LookupModelService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OrganizationModel[]> | Observable<never> {
     return from(this.lookupModelService.getOrganizations());
  }
}

@Injectable({providedIn: 'root'})
export class ProductSizeListResolver implements Resolve<SizeModel[]> {
  constructor(
    private lookupModelService: LookupModelService,
    private productModelService: ProductmodelService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SizeModel[]> | Observable<never> {
    return from(this.lookupModelService.getSizes()).pipe(
      withLatestFrom(of(this.productModelService.activeProduct)),
      map(([sizeList, product]) => {
        const productSizeList:SizeModel[] = [];
        for(let item of sizeList ){
          if(product.sizeIds.includes(item.id)) {
            productSizeList.push(item);
          }
        }

        return productSizeList;
      })
    )
  }
}
