<div *ngIf="displayMode==1"> <!-- forgot password details -->

    <div class="row ml-5 mt-5 d-sm-block">
        <div class="col-md-12">
            <img style="width: 172px;height:46px" src="../../assets/brand/PassenLogo_HorizontalBlack.png">
        </div>
    </div>

    <div class="row ml-5 mt-4 mb-4">
        <div class="col-md-12">
            <h2 class="titleText">Passen Cloud Password Reset</h2>
        </div>
    </div>

    <div class="row ml-5 mt-4">
        <div class="col-md-12">
          <span class="summaryText">Please enter your email.</span>
        </div>
    </div>

    <form #f="ngForm">

        <div class="row ml-5 mb-2 mt-4">
            <div class="col-md-7">
              <input type="email"
                     class="form-control"
                     id="email"
                     name="email"
                     #emailentry="ngModel"
                     [(ngModel)]="email"
                     [ngClass]="{'inputError':!emailentry.valid && emailentry.touched}"
                     required email>
              <div class="errorText" *ngIf="!emailentry.valid && emailentry.touched">Please provide a valid email address.</div>
            </div>
            <div class="col"></div>
        </div>

        <div class="row ml-5 mt-4">
            <div class="col-md-6">
                <button [nbSpinner]="processing" nbSpinnerStatus="control" nbButton status="primary" shape="round" [disabled]="!f.valid || processing" (click)="onForgotPassword()">
                  Send Reset Instructions
                </button>
            </div>
        </div>

        <div class="row ml-5 mt-2">
            <div class="col-md-8">
                <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
            </div>
        </div>

    </form>

    <div class="row ml-5 mt-4">
        <div class="col-md-7">
          <span class="summaryText">You will receive an email with instructions for resetting your password.</span>
        </div>
    </div>

    <div class="row ml-5 mt-3">
        <div class="col-md-7 centernarrow">
            <div class="Line"><span>or</span></div>
        </div>
    </div>

    <div class="row ml-5 mt-3 mb-5">
        <div class="col-md-6 centernarrow">
            <a routerLink="/login" class="linkstyles">Log In</a>
        </div>
    </div>

</div> <!-- forgot password details -->

<div *ngIf="displayMode==2"> <!-- reset password details -->

    <div class="row ml-5 mt-5 d-sm-block d-none">
        <div class="col-md-12">
            <img style="width: 172px;height:46px" src="../../assets/brand/PassenLogo_HorizontalBlack.png">
        </div>
    </div>

    <div class="row ml-5 mt-4 mb-4">
          <div class="col-md-12">
            <span class="titleText centernarrow">Passen Cloud Password Reset</span>
          </div>
    </div>

    <div class="row ml-5 mt-4">
        <div class="col-md-12">
          <span class="summaryText">Provide the verification code sent to your email.</span>
        </div>
    </div>

    <form #f="ngForm">

        <div class="row ml-5 mt-2">
            <div class="col-md-6">
            <span class="labelText">VERIFICATION CODE</span>
            </div>
        </div>

        <div class="row ml-5 mb-2">
            <div class="col-md-7">
                <input type="text"
                    class="form-control"
                    id="verificationcode"
                    name="verificationcode"
                    #verificationcodeentry="ngModel"
                    [(ngModel)]="verificationcode"
                    [ngClass]="{'inputError':!verificationcodeentry.valid && verificationcodeentry.touched}"
                    required>
                <div class="errorText" *ngIf="!verificationcodeentry.valid && verificationcodeentry.touched">Please provide verification code</div>
            </div>
            <div class="col"></div>
        </div>

        <div class="row ml-5 mt-2">
            <div class="col-md-6">
            <span class="labelText">NEW PASSWORD</span>
            </div>
        </div>

        <div class="row ml-5 mb-2">
            <div class="col-md-7">
                <input type="password"
                    class="form-control"
                    id="newpassword"
                    name="newpassword"
                    [(ngModel)]="newpassword"
                    [ngClass]="{'inputError':!newpasswordentry.valid && newpasswordentry.touched}"
                    #newpasswordentry="ngModel"
                    required>
                <div class="errorText" *ngIf="!newpasswordentry.valid && newpasswordentry.touched">Please provide a new password</div>
            </div>
            <div class="col"></div>
        </div>

        <div class="row ml-5 mt-2">
            <div class="col-md-6 ml-5">
            <span class="labelText">8 character minimum</span>
            </div>
        </div>

        <div class="row ml-5">
            <div class="col-md-6 ml-5">
            <span class="labelText">1 upper case letter</span>
            </div>
        </div>

        <div class="row ml-5">
            <div class="col-md-6 ml-5">
            <span class="labelText">1 lower case letter</span>
            </div>
        </div>

        <div class="row ml-5">
            <div class="col-md-6 ml-5">
            <span class="labelText">1 special character: !@#$%^&*()</span>
            </div>
        </div>

        <div class="row ml-5 mt-3">
            <div class="col-md-6">
            <span class="labelText">RE-ENTER NEW PASSWORD</span>
            </div>
        </div>

        <div class="row ml-5 mb-2">
            <div class="col-md-7">
                <input type="password"
                    class="form-control"
                    id="confirmpassword"
                    name="confirmpassword"
                    [(ngModel)]="confirmpassword"
                    [ngClass]="{'inputError':!confirmpasswordentry.valid && confirmpasswordentry.touched}"
                    #confirmpasswordentry="ngModel"
                    required>
               <div class="errorText" *ngIf="!confirmpasswordentry.valid && confirmpasswordentry.touched">Please confirm your password.</div>
            </div>
            <div class="col"></div>
        </div>

        <div class="row ml-5 mt-4">
            <div class="col-md-6">
                <button [nbSpinner]="processing" nbSpinnerStatus="control" nbButton status="primary" shape="round" [disabled]="!f.valid || processing" (click)="onResetPassword()">
                  Reset Password
                </button>
            </div>
        </div>

        <div class="row ml-5 mt-2">
            <div class="col-md-8">
                <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
            </div>
        </div>

    </form>

    <div class="row ml-5 mt-3 mb-5">
        <div class="col-md-6 centernarrow">
            <a routerLink="/login" class="linkstyles">Log In</a>
        </div>
    </div>

</div> <!-- reset password details -->
