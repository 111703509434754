<ng-template #productDialogTpl let-ref="dialogRef">
  <nb-card>
    <nb-card-body class="fit-history-product-dialog">
      <div class="caption">
        <button
          class="unstyled-btn"
          (click)="showPrevProduct()"
          [attr.disabled]="prevProductBtnDisabled || null"
        >
          &lt; Prev
        </button>
        <button
          class="unstyled-btn"
          (click)="showNextProduct()"
          [attr.disabled]="nextProductBtnDisabled || null"
        >
          Next &gt;
        </button>
        <button class="close-btn" (click)="ref.close()" aria-label="Close Dialog">
          Close
        </button>
      </div>
      <h2 class="titleText mt-2">Customer: {{customer.nameFirst + ' ' + customer.nameLast}}</h2>
      <div class="garment-info">
        <table>
          <thead>
            <tr class="bottomBorder">
              <th class="labelText" width="40%"><div>Name</div></th>
              <th class="labelText"><div>Type</div></th>
              <th class="labelText"><div>Gender</div></th>
              <th class="labelText"><div>Manufacturer SKU</div></th>
              <th class="labelText"><div>Customer Fit Preference</div></th>
            </tr>
          </thead>
          <tbody class="mr-2">
            <tr class="">
              <td><div>{{ product.name | titlecase }}</div></td>
              <td><div>{{ product.type | titlecase }}</div></td>
              <td><div>{{ product.gender?.name | titlecase }}</div></td>
              <td><div>{{ product.sku }}</div></td>
              <td>
                <div>
                  <nb-select
                    class="form__select full-width"
                    [(ngModel)]="selectedFitPreference"
                    (ngModelChange)="onSelectFitPreference($event)"
                    placeholder="Select">
                    <nb-option *ngFor="let item of fitPreferenceOptions" [value]="item.id">{{ item.name }}</nb-option>
                  </nb-select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="garment-details" [nbSpinner]="loadingRecs$ | async">
        <ng-container *ngIf="(dialogVm$ | async) as vm">
          <div class="fit-sliders">

            <!-- Existing size chooser buttons -->
            <ng-container *ngIf="vm.recommendations.scoreBreakdown.length; else noSizeAvailable">
              <label class="labelText">Recommended Size(s)</label>

               <div *ngIf="vm.noSizeAvailable" class="no-size-message">
              Unfortunately, you don't have a size that fits for you
            </div>

              <div class="size-chooser">
                <button
                  class="unstyled-btn"
                  *ngFor="let item of vm.recommendations.scoreBreakdown"
                  [ngClass]="{
                    'recommended': item.size === vm.recommendations.recommendation.size,
                    'chosen': item.size === vm.chosenSize,
                    'valid': item.weightedScore > 0
                  }"
                  (click)="chooseSize(item.size)"
                >
                  {{item.displaySize}}
                </button>
              </div>

              <ng-container *ngIf="vm.chosenBreakdown">
                <ng-container *ngFor="let sliderData of vm.chosenBreakdown.scoreBreakdown">
                  <app-slider
                    [labelLow]="calculateLabelLow(sliderData.measurement)"
                    [labelHigh]="calculateLabelHigh(sliderData.measurement)"
                    [label]="getSliderLabel(sliderData.measurement.description)"
                    [value]="sliderData.score"
                  ></app-slider>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          <div class="garment-image-container">
            <label class="labelText">Product Photo</label>

            <img class="garment-image" *ngIf="product.images?.length; else noImage"
              [src]="product.images[0].imageUrl"
            />
          </div>
          <div class="fit-feedback-container" *ngIf="true">
            <label class="labelText">Fit Review</label>

            <div class="labelText" *ngIf="false">Select customer preferred size</div>

            <nb-select
              *ngIf="false"
              class="form__select full-width"
              placeholder="Select"
              [(ngModel)]="selectedCustomerSize"
              (ngModelChange)="onSelectCustomerPreferredSize($event)">
                <nb-option *ngFor="let item of vm.recommendations.scoreBreakdown" [value]="item.size">{{ item.displaySize }}</nb-option>
            </nb-select>

            <div class="labelText mt-2" *ngIf="false">Recalibrate Body Measurements by Feedback</div>

            <button
            *ngIf="false"
                    shape="round"
                    class="mt-1"
                    size="medium"
                    status="primary"
                    nbButton outline (click)="updateBodyMeasurements()">
                Update Body Measurements
            </button>

            <div class="feedback-size-container mt-2">
              <div class="labelText">Fit Point Assessment By Size:</div>

              <nb-select
                class="form__select ml-3"
                placeholder="Select"
                [(ngModel)]="selectedFitFeedbackSize"
                (ngModelChange)="onSelectFitFeedbackSize($event, vm.recommendations.scoreBreakdown)">
                  <nb-option *ngFor="let item of vm.recommendations.scoreBreakdown" [value]="item.size">{{ item.displaySize }}</nb-option>
              </nb-select>
            </div>

            <div *ngIf="selectedFitFeedbackSize">
              <strong>Size:</strong> {{ selectedFitFeedbackSizeName }}
            </div>

            <div *ngFor="let item of fitPointBreakdown" class="feedback-size-container mt-1">
              <div class="labelText fit-point-title">{{ item.name }}</div>

              <nb-select
                class="form__select full-width"
                [(ngModel)]="item.value"
                (ngModelChange)="onUpdateFitFeedbackScore($event, item)"
                placeholder="Select">
                  <nb-option *ngFor="let item of fitFeedbackOptions" [value]="item.id">{{ item.name }}</nb-option>
              </nb-select>
            </div>
          </div>
        </ng-container>

      </div>
      <!-- <pre>
        {{product | json}}
      </pre> -->
    </nb-card-body>
  </nb-card>
</ng-template>
