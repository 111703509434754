<ng-container *ngIf="needsLayout; else noLayout">

  <nb-layout [nbSpinner]="processing$ | async" nbSpinnerSize="large">
      <nb-layout-header subheader style="border: solid 1px rgba(50, 62, 72, 0.11)">
          <app-header style="width:100%"></app-header>
      </nb-layout-header>

      <nb-sidebar class="sidebar" [containerFixed]="true" state="compacted">
          <app-sidebarmenu ></app-sidebarmenu>
      </nb-sidebar>

      <nb-layout-column class="maincontent home-layout">
        <router-outlet></router-outlet>
      </nb-layout-column>

      <nb-layout-footer subheader *ngIf="false">
      </nb-layout-footer>
  </nb-layout>
</ng-container>

<ng-template #noLayout>
  <nb-layout class="noLayout" [nbSpinner]="processing$ | async" nbSpinnerSize="large">
    <nb-layout-column class="maincontent">
      <router-outlet></router-outlet>
    </nb-layout-column>
    <nb-layout-column>
      <img class="float-right hero-image" src="../../assets/images/group-2@3x.png">
    </nb-layout-column>
  </nb-layout>
</ng-template>
