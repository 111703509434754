import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-chooser',
  templateUrl: './file-chooser.component.html',
  styleUrls: ['./file-chooser.component.scss']
})
export class FileChooserComponent implements OnInit {

  @Input() multipleFiles: boolean = false;
  @Input() acceptFileTypes: string;
  @Output() messageEvent = new EventEmitter<any>();

  fileList: FileList;

  @Output()
  fileListChanged = new EventEmitter<FileList>();

  public accept: string;

  constructor() { }

  ngOnInit(): void {
    if (this.acceptFileTypes === 'image') {
      this.accept = 'image/*';
    } else if (this.acceptFileTypes) {
      this.accept = this.acceptFileTypes.split(',').map((x) => `.${x}`).join(',');
    } else {
      this.accept = '*';
    }
  }

  public onFileDropped($event) {
   this.fileList = $event;
  }

  fileInputChanged() {
    console.log("changed")
  }

}
