<div class="row mb-3 no-gutters">
  <h3 class="titleText">Fit Details</h3>
</div>

<div class="messagebox" *ngIf="showFitDetailsMsg">
  <div class="row mb-3 ml-3">
      <div class="col-md-11 mt-3 mb-3">
          <div class="messageboxtext">
              We are selecting what the ideal, loose, tight preferences are for this specific
              fit form based on how the product sits on it.
          </div>
      </div>
      <div class="col-md-1 mt-3 mb-3">
          <div class="float-right mr-3">
              <a (click)="removeFitDetailsMsg()">
                  <img style="width: 24px;height:24px" src="../../assets/icons/27-icon-close-black.png">
              </a>
          </div>
      </div>
  </div>

  <div class="row ml-3 mb-3">
      <div class="col-md-6 mb-3">
          <nb-checkbox type="checkbox"
                      [(ngModel)]="doNotShowFitDetailsMsg"
                      style="vertical-align:middle">
          </nb-checkbox>
          <span class="checkboxLabelText ml-3">Don't show this again</span>
      </div>
  </div>
</div>

<div class="row mb-3 mt-3">
  <div class="col-md-4">
      <div class="labelText mb-1">CHOOSE THE FIT FORM APPROPRIATE FOR THIS PRODUCT</div>
      <nb-select  *ngIf="fitForms.length > 0"
                  class="fitselectionstyle mb-4"
                  placeholder="Select"
                  [(ngModel)]="product.fit_form_id"
                  >
          <nb-option *ngFor="let item of fitForms" [value]="item.id">{{ item.name }}</nb-option>
      </nb-select>
  </div>
  <div class="col"></div>
</div>

<div class="row mb-3 mt-3">
  <div class="col-md-4">
      <div class="infotext">i</div>
      <span class="labelText ml-3">To choose the sizes, try the product on the fit form.</span>
  </div>
  <div class="col"></div>
</div>

<div class="row mb-3">
  <div class="col-md-4">
      <div class="labelText mb-1">IDEAL SIZE</div>
      <nb-select  *ngIf="productSizeList.length > 0"
                  class="selectionstyle mb-4"
                  placeholder="Select"
                  (ngModelChange)="onUpdateIdealSize($event)"
                  [(ngModel)]="product.ideal_size_id">
          <nb-option *ngFor="let item of productSizeList" [value]="item.id">{{ item.shortName }}</nb-option>
      </nb-select>
  </div>
  <div class="col"></div>
</div>

<div class="row mb-3 mt-3">
  <div class="col-md-4">
      <div class="labelText mb-1">LOOSE PREFERENCE SIZE</div>
      <nb-select  *ngIf="productSizeList.length > 0"
                  class="selectionstyle mb-4"
                  placeholder="Select"
                  (ngModelChange)="onUpdateLoosePreferenceSize($event)"
                  [(ngModel)]="product.loose_preference_size_id">
          <nb-option *ngFor="let item of productSizeList" [value]="item.id">{{ item.shortName }}</nb-option>
      </nb-select>
  </div>
  <div class="col"></div>
</div>

<div class="row mb-3 mt-3">
  <div class="col-md-4">
      <div class="labelText mb-1">TIGHT PREFERENCE SIZE</div>
      <nb-select  *ngIf="productSizeList.length > 0"
                  class="selectionstyle mb-4"
                  placeholder="Select"
                  (ngModelChange)="onUpdateTightPreferenceSize($event)"
                  [(ngModel)]="product.tight_preference_size_id">
          <nb-option *ngFor="let item of productSizeList" [value]="item.id">{{ item.shortName }}</nb-option>
      </nb-select>
  </div>
  <div class="col"></div>
</div>

<div class="row mb-3 no-gutters">
  <h3 class="titleText">Fit Parameters</h3>
</div>

<div class="container-fluid">
  <div class="row mb-1">
    <div class="col-md-12">
        <div class="table-responsive" *ngIf="product.productFitParameters.length > 0">
            <table>
              <thead>
                <tr>
                    <th colspan="3"></th>
                    <th colspan="3" class="labelText bottomBorder">FIT MODEL EASE ADJUSTMENT
                      <button
                              class="infotext ml-3"
                              nbPopoverTrigger="hover"
                              nbPopoverPlacement="top"
                              [nbPopover]="infoMsgRef"
                              style="margin-bottom:10px">i</button>
                      <ng-template #infoMsgRef>
                          <nb-card class="cardinfo">
                              <nb-card-body>
                                  <span>
                                      This is the distance we would take the product in to achieve
                                      what a tight/ideal/loose fit would be (without distortion) on
                                      the chosen fit form.
                                  </span>
                              </nb-card-body>
                          </nb-card>
                      </ng-template>
                    </th>
                </tr>
                <tr class="bottomBorder">
                  <th class="labelText"><div style="width:250px">MEASUREMENT NAME</div></th>
                  <th class="labelText"><div style="width:100px">FIT POINT</div></th>
                  <th class="labelText"><div>FIT WEIGHTING</div></th>
                  <th class="labelText"><div>IDEAL (SIZE)</div></th>
                  <th class="labelText"><div>LOOSE (SIZE)</div></th>
                  <th class="labelText"><div>TIGHT (SIZE)</div></th>
                </tr>
              </thead>
              <tbody class="mr-2">
                <tr class="bottomBorder" *ngFor="let item of product.productFitParameters;let i=index">

                    <td class="labelText"><div>{{ item.measurementName }}</div></td>
                    <td>
                        <nb-toggle [(ngModel)]="product.productFitParameters[i].is_fit_point"></nb-toggle>
                    </td>
                    <td>
                          <input type="number"
                                  nbInput
                                  (ngModelChange)="onUpdateFitWeighting($event,i)"
                                  [(ngModel)]="product.productFitParameters[i].fit_weighting">
                          <span class="percent-icon"><img style="width: 24px;height:24px;vertical-align:middle" src="../../assets/icons/27-icon-percent.png"></span>
                    </td>
                    <td class="labelText">
                      <input type="number"
                              nbInput
                              [(ngModel)]="product.productFitParameters[i].ideal_size_adjustment">
                      <span class="ml-1" *ngIf="item.idealSizeShortName.length > 0">({{item.idealSizeShortName}})</span>
                    </td>
                    <td class="labelText">
                      <input type="number"
                              nbInput
                              [(ngModel)]="product.productFitParameters[i].loose_size_adjustment">
                      <span class="ml-1" *ngIf="item.looseSizeShortName.length > 0">({{item.looseSizeShortName}})</span>
                    </td>
                    <td class="labelText">
                      <input type="number"
                              nbInput
                              [(ngModel)]="product.productFitParameters[i].tight_size_adjustment">
                      <span class="ml-1" *ngIf="item.tightSizeShortName.length > 0">({{item.tightSizeShortName}})</span>
                    </td>
              </tr>
              </tbody>
            </table>
        </div>
  </div>
</div> <!-- container-fluid for responsive table -->

<!-- <div class="row mb-3 mt-5" *ngIf="isNewMode()">
  <div class="col-md-12">
      <button [nbSpinner]="processing" nbSpinnerStatus="primary" type="button" class="nextbutton" [disabled]="!requiredFields()" (click)=onNext()>NEXT</button>
      <a [routerLink]="" class="linkstyles ml-3" *ngIf="requiredFields() && !processing" (click)="saveForLater()" style="font-size:16px">SAVE FOR LATER</a>
      <span class="labelText ml-3" *ngIf="!requiredFields()" style="font-size:16px">SAVE FOR LATER</span>
      <span class="labelText ml-3" *ngIf="requiredFields() && processing" style="font-size:16px">SAVE FOR LATER</span>
  </div>
</div> -->

<div class="row mt-4 no-gutters">
  <button
    class="mb-2 save-btn"
    [nbSpinner]="processing"
    nbSpinnerStatus="primary"
    shape="round"
    [disabled]="!requiredFields()"
    nbButton
    (click)="onSave()"
  >
    SAVE
  </button>
</div>
