import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { GenderListResolver } from "../customers/customers-resolver.service";
import { ProductsListComponent } from "../products/product-list/product-list.component";
import { AuthGuard } from "../services/auth-guard.service";
import { ProductAssessmentComponent } from "./product-assessment/product-assessment.component";
import { ProductClientAssignmentComponent } from "./product-client-assignment/product-client-assignment.component";
import { ProductDetailComponent } from "./product-detail/product-detail.component";
import { ProductFitParametersComponent } from "./product-fit-parameters/product-fit-parameters.component";
import { ProductFitRecommendationAssessmentComponent } from "./product-fit-recommendation-assesment/product-fit-recommendation-assessment.component";
import { ProductHomeComponent } from "./product-home/product-home.component";
import { ProductMeasurementsComponent } from "./product-measurements/product-measurements.component";
import { BenchmarkMeasurementListResolver, BrandListResolver, ClientListResolver, FitFormsResolver, ProductNewResolverService, ProductResolverService, ProductSizeListResolver, ProductsListResolverService, ProductTypesResover, SizeListResolver } from "./products-resolver.sevice";

const routes: Routes = [
  {
    path: 'products',
    component: ProductsListComponent,
    resolve: { products: ProductsListResolverService },
    canActivate: [AuthGuard]
  },
  {
    path: 'new-product',
    component: ProductHomeComponent,
    resolve: {
      product: ProductNewResolverService,
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductDetailComponent,
        resolve: {
          product: ProductNewResolverService,
          brands: BrandListResolver,
          productTypes: ProductTypesResover,
          genderList: GenderListResolver, //TODO: move this to common resovers
          sizeList: SizeListResolver
        }
      }
    ]
  },
  {
    path: 'product/:id',
    component: ProductHomeComponent,
    resolve: {
      product: ProductResolverService
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProductDetailComponent,
        resolve: {
          product: ProductResolverService,
          brands: BrandListResolver,
          productTypes: ProductTypesResover,
          genderList: GenderListResolver, //TODO: move this to common resovers
          sizeList: SizeListResolver
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'measurements',
        component: ProductMeasurementsComponent,
        resolve: {
          product: ProductResolverService,
          productSizeList: ProductSizeListResolver,
          benchmarkMeasurementList: BenchmarkMeasurementListResolver
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'fit-parameters',
        component: ProductFitParametersComponent,
        resolve: {
          product: ProductResolverService,
          productSizeList: ProductSizeListResolver,
          fitForms: FitFormsResolver
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'client-assignment',
        component: ProductClientAssignmentComponent,
        resolve: {
          product: ProductResolverService,
          clientList: ClientListResolver,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'product-assessment',
        component: ProductAssessmentComponent,
        resolve: {
          product: ProductResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'product-fit-recommendation-assesment',
        component: ProductFitRecommendationAssessmentComponent,
        resolve: {
          product: ProductResolverService,
        },
        canActivate: [AuthGuard],
      },
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductsRoutingModule { }
