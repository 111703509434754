<div class="container-fluid">
    <div class="row mb-3 mt-3 no-gutters">
        <h2 class="titleText">Products</h2>
        <a [routerLink]="['/new-product']" class="linkText add-entity">Add Product</a>
    </div>

    <!-- Search Input -->
    <div class="row mb-3">
        <div class="col-md-3">
            <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    [(ngModel)]="searchTerm"
                    (input)="filterProducts()"
                />
                <div class="input-group-append">
                    <span class="input-group-text">
                        <i class="fa fa-search"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row bottomBorder">
        <div class="col-check mt-3 mb-3 d-md-block d-none">
            <div class="d-flex justify-content-center">
                <nb-checkbox
                    type="checkbox"
                    name="checkAllCurrentProducts"
                    id="checkAllCurrentProducts"
                    [disabled]="multiSelectDisabled ? true : null"
                >
                </nb-checkbox>
            </div>
        </div>

        <div class="col-md-2 mt-3 mb-3">
            <button class="headerstyles" (click)="onSort('sku')">
                Base SKU
                <span *ngIf="sortColumn === 'sku' && !sortDirection">&nbsp;&#9650;</span>
                <span *ngIf="sortColumn === 'sku' && sortDirection">&nbsp;&#9660;</span>
            </button>
        </div>

        <div class="col-md-2 mt-3 mb-3">
            <button class="headerstyles" (click)="onSort('brand')">
                Brand
                <span *ngIf="sortColumn === 'brand' && !sortDirection">&nbsp;&#9650;</span>
                <span *ngIf="sortColumn === 'brand' && sortDirection">&nbsp;&#9660;</span>
            </button>
        </div>

        <div class="col-md-1 mt-3 mb-3">
            <button class="headerstyles" (click)="onSort('productType')">
                Type
                <span *ngIf="sortColumn === 'productType' && !sortDirection">&nbsp;&#9650;</span>
                <span *ngIf="sortColumn === 'productType' && sortDirection">&nbsp;&#9660;</span>
            </button>
        </div>

        <div class="col-md-1 mt-3 mb-3">
            <button class="headerstyles" (click)="onSort('gender')">
                Gender
                <span *ngIf="sortColumn === 'gender' && !sortDirection">&nbsp;&#9650;</span>
                <span *ngIf="sortColumn === 'gender' && sortDirection">&nbsp;&#9660;</span>
            </button>
        </div>

        <div class="col-md-2 mt-3 mb-3">
            <button class="headerstyles" (click)="onSort('name')">
                Name
                <span *ngIf="sortColumn === 'name' && !sortDirection">&nbsp;&#9650;</span>
                <span *ngIf="sortColumn === 'name' && sortDirection">&nbsp;&#9660;</span>
            </button>
        </div>

        <div class="col-md-2 mt-3 mb-3">
            <button class="headerstyles" (click)="onSort('client')">
                Client
                <span *ngIf="sortColumn === 'client' && !sortDirection">&nbsp;&#9650;</span>
                <span *ngIf="sortColumn === 'client' && sortDirection">&nbsp;&#9660;</span>
            </button>
        </div>

        <div class="col-md-1 mt-3 mb-3">
            <button class="headerstyles" (click)="onSort('status')">
                Status
                <span *ngIf="sortColumn === 'status' && !sortDirection">&nbsp;&#9650;</span>
                <span *ngIf="sortColumn === 'status' && sortDirection">&nbsp;&#9660;</span>
            </button>
        </div>
    </div>

    <div
        class="row bottomBorder"
        style="height: 53px"
        *ngFor="let product of filteredProducts; let i = index"
    >
      <div class="col-check d-flex justify-content-center align-items-center">
        <nb-checkbox
          type="checkbox"
          id="checkboxProduct-{{product.id}}"
          (checkedChange)="select($event, product.id)"
          [disabled]="multiSelectDisabled ? true : null"
        ></nb-checkbox>
      </div>

      <div class="col-md-2 d-flex align-items-center">
        <a [routerLink]="['/product', product.id]" class="tableText" >
          <span> {{ product.sku }} </span>
        </a>
      </div>

      <div class="col-md-2 d-flex align-items-center">
        <span class="tableText"> {{ product.brand ? product.brand.name : '' }} </span>
      </div>

      <div class="col-md-1 d-flex align-items-center">
        <span class="tableText"> {{ product.productType ? product.productType.description : '' }} </span>
      </div>

      <div class="col-md-1 d-flex align-items-center">
        <span class="tableText"> {{ product.gender ? product.gender.description : '' }} </span>
      </div>

      <div class="col-md-2 d-flex align-items-center">
        <span class="tableText"> {{ product.name }} </span>
      </div>

      <div class="col-md-2 d-flex align-items-center">
        <span class="tableText"> {{ product.clientListing() }} </span>
      </div>

      <div class="col-md-1 d-flex align-items-center">
        <span
          [ngClass]="getStatusButtonStyle(product)"
        >
          {{ product.productStatus ? this.product.productStatus.description : '' }}
        </span>
      </div>

    </div>

    <div class="row mb-3 mt-3 no-gutters">

    </div>
</div>
