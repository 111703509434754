import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MeasurementModel, SizeModel } from 'src/app/model/lookupmodel.service';
import { ProductModel, ProductmodelService } from 'src/app/model/productmodel.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-product-fit-recommendation-assesment',
  templateUrl: './product-fit-recommendation-assessment.component.html',
  styleUrls: ['./product-fit-recommendation-assessment.component.scss']
})
export class ProductFitRecommendationAssessmentComponent implements OnInit {

  public processing: boolean = false;
  product: ProductModel;
  productSizeList: SizeModel[];
  benchmarkMeasurementList: MeasurementModel[];
  coreMeasurementList: MeasurementModel[];


  public analysisRows: any[] = [];
  public analysisColumns: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private productModel: ProductmodelService) { }

  ngOnInit(): void {
    this.processing = false;
    this.route.data.subscribe(
      (data: {
        product: ProductModel;
      }) => {
        this.product = data.product;
      }
    );
  }


  isProcessing() {
    return this.processing
  }

  async run_analysis() {

    this.processing = true;
    console.log(this.processing)
    try {
      // TODO change these hardcoded values and base on user input
      await this.loadFitAnalysisComparison(["3404", "3415", "3428", "3409", "3403", "3413", "3397", "3390", "3405", "3402", "3407", "3412", "3396", "3406", "3399", "3414", "3400"], [this.product.id])
    } catch (err) {
      console.error(err);
    } finally {

    }
  }

  private async loadFitAnalysisComparison(customerIds: Array<string>, productIds: Array<string>) {
    this.analysisColumns = [];
    this.analysisRows = [];
    this.productModel.generateFitRecommendationComparison(customerIds, productIds)
      .then(r => {
        const parsed_json = JSON.parse(JSON.parse(r.output['output']))
        this.analysisColumns = parsed_json['Columns']
        console.log(parsed_json)
        for (const key in parsed_json) {
          if (key != 'Columns') {
            // turning object into array ordered by column - there is probably a better way of doing this using angular
            const current_row = [];
            for (const column_name of this.analysisColumns) {
              const val = (parsed_json[key][column_name]);
              current_row.push(val);
            }
            this.analysisRows.push(current_row);
          }
          console.log(this.analysisRows)
        }
        this.processing = false;
      })
      .catch(err => console.log(err))
  }


}