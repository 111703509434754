<div class="container-fluid">
    <div class="row mb-3 mt-3 no-gutters align-items-center justify-content-between">
      <h2 class="titleText">Users</h2>
      <div class="user-actions">
       <input type="file" #fileInput style="display: none;" accept=".csv" (change)="onFileSelected($event)"/>

    <!-- Upload Users Link -->
    <a href="javascript:void(0);" class="linkText add-entity"
       [class.disabled]="uploadInProgress"
       (click)="fileInput.click()">
      {{ uploadInProgress ? 'Uploading...' : 'Upload Users' }}
    </a>
        <a [routerLink]="['/new-customer']" class="linkText add-entity">Add User</a>
      </div>
    </div>

    <!-- Search Input -->
    <div class="row mb-3">
        <div class="col-md-3">
            <div class="input-group">
                <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    [(ngModel)]="searchTerm"
                    (input)="filterCustomers()"
                />
                <div class="input-group-append">
                    <span class="input-group-text">
                        <i class="fa fa-search"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- Table Header -->
    <div class="row bottomBorder">
      <div class="col-check mt-3 mb-3 d-flex justify-content-center">
        <nb-checkbox
          type="checkbox"
          name="checkAllCurrentCustomers"
          id="checkAllCurrentCustomers"
          (checkedChange)="selectAll()"
          [disabled]="multiSelectDisabled ? true : null"
        ></nb-checkbox>
      </div>

      <div class="col-md-2 mt-3 mb-3">
        <button class="headerstyles" (click)="onSort('nameFirst')">
          FIRST NAME
          <span *ngIf="sortColumn === 'nameFirst' && !sortDirection">&nbsp;&#9650;</span>
          <span *ngIf="sortColumn === 'nameFirst' && sortDirection">&nbsp;&#9660;</span>
        </button>
      </div>

      <div class="col-md-2 mt-3 mb-3">
        <button class="headerstyles" (click)="onSort('nameLast')">
          LAST NAME
          <span *ngIf="sortColumn === 'nameLast' && !sortDirection">&nbsp;&#9650;</span>
          <span *ngIf="sortColumn === 'nameLast' && sortDirection">&nbsp;&#9660;</span>
        </button>
      </div>

      <div class="col-md-1 mt-3 mb-3">
        <button class="headerstyles" (click)="onSort('gender')">
          GENDER
          <span *ngIf="sortColumn === 'gender' && !sortDirection">&nbsp;&#9650;</span>
          <span *ngIf="sortColumn === 'gender' && sortDirection">&nbsp;&#9660;</span>
        </button>
      </div>

      <div class="col-md-2 mt-3 mb-3">
        <button class="headerstyles" (click)="onSort('createdAt')">
          DATE CREATED
          <span *ngIf="sortColumn === 'createdAt' && !sortDirection">&nbsp;&#9650;</span>
          <span *ngIf="sortColumn === 'createdAt' && sortDirection">&nbsp;&#9660;</span>
        </button>
      </div>

      <div class="col-md-2 mt-3 mb-3">
        <button class="headerstyles" (click)="onSort('bodyMeasurementsComplete')">
          MEASUREMENT SESSION
          <span *ngIf="sortColumn === 'bodyMeasurementsComplete' && !sortDirection">&nbsp;&#9650;</span>
          <span *ngIf="sortColumn === 'bodyMeasurementsComplete' && sortDirection">&nbsp;&#9660;</span>
        </button>
      </div>

      <div class="col-md-1 mt-3 mb-3">
        <button class="headerstyles" (click)="onSort('fitSessionCompleted')">
          FIT SESSION
          <span *ngIf="sortColumn === 'fitSessionCompleted' && !sortDirection">&nbsp;&#9650;</span>
          <span *ngIf="sortColumn === 'fitSessionCompleted' && sortDirection">&nbsp;&#9660;</span>
        </button>
      </div>
    </div>

    <!-- Table Rows -->
    <div class="row bottomBorder" *ngFor="let customer of filteredCustomers">
        <div class="col-check mt-3 mb-3 d-flex justify-content-center align-items-center">
          <nb-checkbox
            type="checkbox"
            id="checkboxCustomer-{{customer.id}}"
            (checkedChange)="onCheckCustomer($event, customer.id)"
            [disabled]="multiSelectDisabled ? true : null"
          ></nb-checkbox>
        </div>

        <div class="col-md-2 mt-3 mb-3 d-flex align-items-center">
          <a [routerLink]="['/customer', customer.id]" class="tableText">
            <span> {{ customer.nameFirst ? customer.nameFirst : '' }} </span>
          </a>
        </div>

        <div class="col-md-2 mt-3 mb-3 d-flex align-items-center">
          <span class="tableText"> {{ customer.nameLast ? customer.nameLast : '' }} </span>
        </div>

        <div class="col-md-1 mt-3 mb-3 d-flex align-items-center">
          <span class="tableText"> {{ customer.gender ? customer.gender.description : '' }} </span>
        </div>

        <div class="col-md-2 mt-3 mb-3 d-flex align-items-center">
          <span class="tableText"> {{ getFormatedDate(customer.createdAt) }} </span>
        </div>

        <div class="col-md-2 mt-3 mb-3 d-flex justify-content-center align-items-center">
          <img
            *ngIf="!!customer.bodyMeasurementsComplete"
            style="width: 24px;height:24px"
            src="../../../../assets/icons/27-icon-checkmark-circle.png" />
        </div>

        <div class="col-md-1 mt-3 mb-3 d-flex justify-content-center align-items-center">
          <img
            *ngIf="!!customer.fitSessionCompleted"
            style="width: 24px;height:24px"
            src="../../../../assets/icons/27-icon-coat-hanger.png" />
        </div>
    </div>
</div>
