
<div class="col-md-12">
  <button class="mb-4 save-btn"
          [nbSpinner]="processing"
          nbSpinnerStatus="primary"
          shape="round"
          nbButton
          [disabled]="isProcessing()"
          (click)="run_analysis()">
      Run Product Fit Recommendation Assesment
  </button>
</div>
<div class="col"></div>




<div class="fit-analysis">
  <table *ngIf="analysisColumns.length > 0 && analysisRows.length > 0">
    <tr>
      <th class="labelText"  *ngFor="let column of analysisColumns"> {{column}} </th>
    </tr>
    <tr *ngFor="let row of analysisRows">
      <td *ngFor="let val of row">
        {{ val }}
      </td>
    </tr>
  </table>
</div>