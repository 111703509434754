import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FileChooserComponent } from 'src/app/file-chooser/file-chooser.component';
import { CustomerModelService, CustomerModel } from 'src/app/model/customermodel.service';
import { FileTransferService } from 'src/app/services/filetransfer.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';



@Component({
  selector: 'app-customer-measurements',
  templateUrl: './customer-measurements.component.html',
  styleUrls: ['./customer-measurements.component.scss']
})
export class CustomerMeasurementsComponent implements OnInit, AfterViewInit {

  @ViewChild('fileUpload') fileUpload: FileChooserComponent;
  @ViewChild('3dModelContainer', { static: false }) modelContainer: ElementRef;


  constructor(
    public customerModelService: CustomerModelService,
    private route: ActivatedRoute,
    private fileTransferService: FileTransferService
  ) { }

  public customer: CustomerModel;
    // Three.js-related variables
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  renderer: THREE.WebGLRenderer;
  controls: OrbitControls;
  light: THREE.DirectionalLight;
  object: THREE.Object3D;

  // Loading indicator
  loading: boolean = true;
  public uploadInProgress: boolean = false;
  public updateInProgress: boolean = false;
  public fileList: FileList;

  ngOnInit(): void {
    this.route.data.subscribe((data: { customer: CustomerModel }) => {
        this.customer = data.customer;
        console.log("kkk", this.customer)});
    this.customerModelService.loadCustomerMeasurements();
  }


  ngAfterViewInit() {
    if (this.customer?.sizeStreamAccountId) {
      this.initializeScene();

      setTimeout(() => {
        this.loading = false;
        this.load3DModel(this.customer.sizeStreamAccountId);
      }, 1000);
    }
  }

    initializeScene() {
    const container = this.modelContainer?.nativeElement;
    if (!container) {
      console.error('3D Model container not found.');
      return;
    }

    // Initialize Three.js scene, camera, and renderer
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 10000);
    this.camera.position.set(0, 850, 3000);  // Adjust the camera for large model
    this.camera.lookAt(0, 850, 0);  // Look at the model's approximate center

    // Initialize renderer with white background
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setSize(container.clientWidth, container.clientHeight);
    this.renderer.setClearColor(0xffffff);  // Set background to white
    this.renderer.shadowMap.enabled = true;

    // Append the renderer's canvas to the container
    container.appendChild(this.renderer.domElement);

    // Directional light for basic lighting
    this.light = new THREE.DirectionalLight(0xffffff, 1);
    this.light.position.set(1, 1, 1);
    this.light.castShadow = true;
    this.scene.add(this.light);

    // Ambient light for global illumination
    const ambientLight = new THREE.AmbientLight(0xffffff, 1.5);  // Increase intensity for visibility
    this.scene.add(ambientLight);

    // Initialize OrbitControls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.target.set(0, 0.9, 0);  // Set controls target to object's approximate center

    // Start rendering loop
    this.animate();
  }

  async load3DModel(sizeStreamAccountId: string) {
    try {
      const modelUrl = await this.customerModelService.getBodyModelUrl(sizeStreamAccountId);
      if (!modelUrl) {
        console.error('No URL returned for the body model');
        return;
      }

      const loader = new OBJLoader();
      loader.load(
        modelUrl,
        (object) => {
          console.log('Object successfully loaded:', object);
          this.object = object;
          this.addModelToScene(object);
        },
        (xhr) => {
          console.log(`${(xhr.loaded / xhr.total * 100)}% loaded`);  // Log loading progress
        },
        (error) => {
          console.error('An error occurred while loading the OBJ model', error);
        }
      );
    } catch (error) {
      console.error('Error loading 3D model:', error);
    }
  }

  addModelToScene(object: THREE.Object3D) {
    const basicMaterial = new THREE.MeshBasicMaterial({ color: 0xaaaaaa });

    object.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material = basicMaterial;  // Temporarily apply the basic material
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });

    const bbox = new THREE.Box3().setFromObject(object);
    const center = bbox.getCenter(new THREE.Vector3());
    const size = bbox.getSize(new THREE.Vector3());

    // Center the object
    object.position.set(-center.x, -center.y, -center.z);

    // Scale the object to fit the scene
    const maxAxis = Math.max(size.x, size.y, size.z);
    const scaleFactor = 1 / maxAxis * 2000;  // Adjust scale factor to fit the large model
    object.scale.set(scaleFactor, scaleFactor, scaleFactor);

    object.rotation.set(0, Math.PI / 2, 0);  // Rotate for better viewing

    this.scene.add(object);
  }

  animate() {
    const renderLoop = () => {
      if (this.object) {
        this.object.rotation.y += 0.01;  // Rotate the model continuously around Y-axis
      }
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(renderLoop);  // Continue rendering frames
    };
    renderLoop();
  }

  async uploadMeasurements(): Promise<void> {
    if (!this.fileUpload.fileList) return;
    this.uploadInProgress = true;

    for (let i = 0; i < this.fileUpload.fileList.length; i++) {
      let file = this.fileUpload.fileList[i];
      const uploadDetails = await this.customerModelService.getCustomerFileUploadDetails(file.name);

      this.fileTransferService.uploadSignedUrl(file, uploadDetails.url)
        .subscribe(
          async (data) => {
            await this.customerModelService.importTg3dCustomerMeasurements(uploadDetails.fileKey);
            await this.customerModelService.loadCustomerMeasurements();
            this.uploadInProgress = false;
          },
          (err) => {
            this.uploadInProgress = false;
            throw(err);
          }
      );

    }
  }

  async updateMeasurements(): Promise<void> {

    this.updateInProgress = true;
    await this.customerModelService.updateCustomerMeasurements();
    await this.customerModelService.loadCustomerMeasurements();
    this.updateInProgress = false;

  }
}
