import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth-guard.service';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomerFitSessionProductComponent } from './customer-fit-session-product/customer-fit-session-product.component';
import { CustomerFitSessionComponent } from './customer-fit-session/customer-fit-session.component';
import { CustomerHomeComponent } from './customer-home/customer-home.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerMeasurementsComponent } from './customer-measurements/customer-measurements.component';
import {FitAnalysisComponent} from './customer-fit-analysis/fit-analysis.component';
import { ClientListResolver, CountryListResolver, CustomerNewResolverService, CustomerResolverService, CustomersListResolverService, GenderListResolver, ProductListResolverService, ProductResolveService } from './customers-resolver.service';
import { CustomerFitRecommendationAssessmentComponent } from './customer-fit-recommendation-assesment/customer-fit-recommendation-assesment.component';

const routes: Routes = [
  {
    path: 'customers',
    component: CustomerListComponent,
    resolve: { customers: CustomersListResolverService },
    canActivate: [AuthGuard]
  },
  {
    path: 'new-customer',
    component: CustomerDetailComponent,
    resolve: {
      customer: CustomerNewResolverService,
      genderList: GenderListResolver,
      clientList: ClientListResolver,
      countryList: CountryListResolver,
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'customer/:id',
    component: CustomerHomeComponent,
    resolve: {
      customer: CustomerResolverService
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CustomerDetailComponent,
        resolve: {
          customer: CustomerResolverService,
          genderList: GenderListResolver,
          clientList: ClientListResolver,
          countryList: CountryListResolver,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'fit-session',
        component: CustomerFitSessionComponent,
        resolve: {
          genderList: GenderListResolver,
          customer: CustomerResolverService,
          productList: ProductListResolverService
        },
        canActivate: [AuthGuard],
        children: [
          {
            path: 'product/:product_id',
            component: CustomerFitSessionProductComponent,
            resolve: {
              customer: CustomerResolverService,
              product: ProductResolveService,
              productList: ProductListResolverService,
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'measurements',
        component: CustomerMeasurementsComponent,
        resolve: { customer: CustomerResolverService },
        canActivate: [AuthGuard],
      },
      {
        path: 'fit-analysis',
        component: FitAnalysisComponent,
        resolve: {
          customer: CustomerResolverService,
          productList: ProductListResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'fit-recommendation-assesment',
        component: CustomerFitRecommendationAssessmentComponent,
        resolve: {
          customer: CustomerResolverService,
          productList: ProductListResolverService,
        },
        canActivate: [AuthGuard],
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomersRoutingModule { }
