import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { FileChooserComponent } from 'src/app/file-chooser/file-chooser.component';
import { FileTransferService } from 'src/app/services/filetransfer.service';
import { CustomerModelService, CustomerModel} from 'src/app/model/customermodel.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {

  @ViewChild('fileUpload') fileUpload: FileChooserComponent;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;


  customers: CustomerModel[] = [];
  filteredCustomers: CustomerModel[] = [];
  multiSelectDisabled: boolean = true;
  searchTerm: string = '';
  sortColumn: string = '';
  sortDirection: boolean = false; // false for ascending, true for descending

  constructor(
    private route: ActivatedRoute,
    private fileTransferService: FileTransferService,
    public customerModelService: CustomerModelService,
  ) {}

  public uploadInProgress: boolean = false;

  ngOnInit() {
    // Load customers and sort by created date
    this.route.data.subscribe((data: { customers: CustomerModel[] }) => {
      this.customers = data.customers.sort((customer1: CustomerModel, customer2: CustomerModel) => {
        return moment(customer2.createdAt).unix() - moment(customer1.createdAt).unix();
      });
      this.filteredCustomers = this.customers;
    });
  }

  filterCustomers() {
    if (this.searchTerm) {
      const searchTermLower = this.searchTerm.toLowerCase();
      this.filteredCustomers = this.customers.filter(customer =>
        this.customerMatchesSearchTerm(customer, searchTermLower)
      );
    } else {
      this.filteredCustomers = this.customers;
    }
  }

  customerMatchesSearchTerm(customer: CustomerModel, searchTerm: string): boolean {
    const searchableFields = [
      customer.nameFirst,
      customer.nameLast,
      customer.gender ? customer.gender.description : '',
      this.getFormatedDate(customer.createdAt),
      customer.bodyMeasurementsComplete ? 'Completed' : '',
      customer.fitSessionCompleted ? 'Completed' : ''
    ];

    return searchableFields.some(field =>
      field.toString().toLowerCase().includes(searchTerm)
    );
  }

  onSort(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortColumn = column;
      this.sortDirection = false;
    }

    this.filteredCustomers.sort((a, b) => {
      let comparison = 0;

      if (column === 'gender') {
        comparison = (a.gender ? a.gender.description : '').localeCompare(b.gender ? b.gender.description : '');
      } else if (column === 'bodyMeasurementsComplete') {
        comparison = (a.bodyMeasurementsComplete ? 'Completed' : '').localeCompare(b.bodyMeasurementsComplete ? 'Completed' : '');
      } else if (column === 'fitSessionCompleted') {
        comparison = (a.fitSessionCompleted ? 'Completed' : '').localeCompare(b.fitSessionCompleted ? 'Completed' : '');
      } else if (column === 'createdAt') {
        comparison = moment(a.createdAt).unix() - moment(b.createdAt).unix();
      } else {
        comparison = String(a[column]).localeCompare(String(b[column]));
      }

      return this.sortDirection ? comparison * -1 : comparison;
    });
  }

  getFormatedDate(date) {
    let returnDate = moment(date, 'ddd MMM D YYYY HH:mm:ss ZZ');
    if (returnDate.isValid()) {
      return moment(date, 'ddd MMM D YYYY HH:mm:ss ZZ').format('ll');
    }

    returnDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
    if (returnDate.isValid()) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('ll');
    }
    return '';
  }

  selectAll() {}

  select() {}

   async onFileSelected(event: any): Promise<void> {
    const file: File = event.target.files[0];

    if (!file) return; // No file selected

    // **Validate file type**
    if (!file.name.endsWith('.csv') || file.type !== 'text/csv') {
      alert('Please select a valid CSV file.');
      return;
    }

    this.uploadInProgress = true;

    try {
      // **Step 1: Get signed upload URL**
      const uploadDetails = await this.customerModelService.getUploadUsersFileUploadDetails(file.name);

      // **Step 2: Upload file to S3**
      this.fileTransferService.uploadSignedUrl(file, uploadDetails.url)
        .subscribe(
          () => {
            console.log(`User file ${file.name} uploaded successfully.`);
            alert('Upload successful!');
          },
          (err) => {
            console.error(`Upload failed for ${file.name}:`, err);
            alert('Upload failed.');
          }
        );
    } catch (err) {
      console.error('Error uploading file:', err);
      alert('Error uploading file.');
    } finally {
      this.uploadInProgress = false;
      this.fileInput.nativeElement.value = '';
    }
  }

  onCheckCustomer() {}
}
