import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerModel } from 'src/app/model/customermodel.service';
import { GenderModel } from 'src/app/model/lookupmodel.service';
import { ProductModel } from 'src/app/model/productmodel.service';

@Component({
  selector: 'app-customer-fit-session',
  templateUrl: './customer-fit-session.component.html',
  styleUrls: ['./customer-fit-session.component.scss']
})
export class CustomerFitSessionComponent implements OnInit {

  customer: CustomerModel;
  productList: ProductModel[];
  filteredProductList: ProductModel[];
  genderList: GenderModel[];

  showClosestSizeMatch: boolean = false;

  selectedGenderId = -1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.route.data
      .subscribe((data: {
        customer: CustomerModel,
        productList: ProductModel[],
        genderList: GenderModel[]
      }) => {
        this.customer = data.customer;

        this.genderList = data.genderList.filter((item) => !!item.name);
        const allGendersFilter = new GenderModel();
        Object.assign(allGendersFilter, {id: -1, name: 'all', description: 'All'});
        this.genderList.unshift(allGendersFilter);

        this.productList = data.productList;
        this.filteredProductList = data.productList;
      });

    this.route.queryParams
      .subscribe(({genderId}) => {
        if (genderId) {
          this.filteredProductList = this.productList.filter(p => p.gender.id === genderId);
          this.selectedGenderId = genderId
        } else {
          this.filteredProductList = this.productList;
          this.selectedGenderId = -1
        }
      })
  }

  filterByGender(genderId) {
    if (genderId !== -1) {
      this.router.navigate(['.'], {
        queryParams: {genderId: genderId},
        relativeTo: this.route
      });
    } else {
      this.router.navigate(['.'], {
        relativeTo: this.route
      });
    }
  }

}
