import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import 'rxjs/add/operator/retryWhen';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class FileTransferService {
  retryCount: number = 20;

  constructor(private http: HttpClient) {
  }

  uploadSignedUrl(file,signedURL) {
    let headers = new HttpHeaders();
    let response = undefined;
    headers  = headers.append('Content-Type', file.type);
    // headers  = headers.append('Content-Type', "binary/octet-stream");

    return this.http.put(signedURL, file, {headers} )
      .map(
        (response) => {
          return response;
        }
      )
      .retryWhen(err => {
          let retries = 0;
          return err
            .delay(1000)
            .map((error: any) => {
              if (error.status  == 429 && retries++ <= this.retryCount) {
                return error;
              }
              else {
                console.log(error);
                throw error;
              }
            });
      })
  }

  downloadSignedUrl(filename,url) {
    let headers = new HttpHeaders();
    headers  = headers.append('Content-Type', 'application/json');
    return this.http.get(url, {headers,observe: 'response',responseType:'blob' as 'json'} )
      .map(
        (response) => {
          var returnValue = {};
          returnValue['blob'] = response['body'];
          returnValue['filename'] = filename;
          console.log('downloadSignedUrl',returnValue);
          return returnValue;
        }
      )
      .retryWhen(err => {
          let retries = 0;
          return err
            .delay(1000)
            .map((error: any) => {
              if (error.status  == 429 && retries++ <= this.retryCount) {
                return error;
              }
              else {
                console.log(error);
                throw error;
              }
            });
      })
  }

}
