<div class="row mb-3 no-gutters">
  <h3 class="titleText">
      Benchmark Measurements
  </h3>
</div>

<div class="messagebox" *ngIf="showBenchmarkProductMeasurementMsg">
  <div class="row mb-3 ml-3">
      <div class="col-md-11 mt-3 mb-3">
          <div class="messageboxtext">
              Benchmark measurements are taken with the product layed flat on the table.
              They can be 1/2 measurements or full measurements. Sizes selected in the
              size dropdown on the General Details tab influence what columns will show
              in the Benchmark Measurements table. Deleting sizes will remove the column
              and data from the Benchmark Measurements table.
          </div>
      </div>
      <div class="col-md-1 mt-3 mb-3">
          <div class="float-right mr-3">
              <a (click)="removeBenchmarkMeasurementMsg()">
                  <img style="width: 24px;height:24px" src="../../assets/icons/27-icon-close-black.png">
              </a>
          </div>
      </div>
  </div>

  <div class="row ml-3 mb-3">
      <div class="col-md-6 mb-3">
          <nb-checkbox type="checkbox"

                      [(ngModel)]="doNotShowBenchmarkMeasurementsMsg"
                      style="vertical-align:middle">
          </nb-checkbox>
          <span class="checkboxLabelText ml-3">Don't show this again</span>
      </div>
      <div class="col"></div>
  </div>
</div>


<div class="row mb-1">
  <div class="col-md-12">
      <div class="table-responsive" *ngIf="productSizeList.length > 0">
          <table>
            <thead>
              <tr>
                  <th colspan="3"></th>
                  <th class="labelText bottomBorder" [attr.colspan]="productSizeList.length">FLAT MEASUREMENTS (CM)</th>
              </tr>
              <tr class="bottomBorder">
                <th class="labelText"><div>MEASUREMENT NAME</div></th>
                <th class="labelText"><div>DELETE ROW</div></th>
                <th class="labelText"><div>USE 1/2 MEASUREMENT</div></th>
                <th class="labelText" *ngFor="let item of productSizeList"><div style="width:120px">{{ item.shortName }}</div></th>
              </tr>
            </thead>
            <tbody class="mr-2">
              <tr class="bottomBorder" *ngFor="let item of product.benchmarkMeasurementsList;let i=index">

                  <td class="labelText">{{ item[0].measurement.description }}</td>
                  <td class="labelText">
                      <a class="linkstyles" (click)="removeBenchmark(i)">
                          <img style="width: 24px;height:24px" src="../../../../assets/icons/27-icon-trash-2.png">
                      </a>
                  </td>
                  <td class="labelText">
                      <nb-toggle
                        [(ngModel)]="product.benchmarkMeasurementsList[i][0].isHalfMeasurement"
                        (checkedChange)="setBenchmarkHalfMeasurement($event,i)"></nb-toggle>
                  </td>
                  <td *ngFor="let measurement of item;let j=index">
                      <div style="width:150px">
                          <input type="number"
                                 nbInput
                                 [(ngModel)]="product.benchmarkMeasurementsList[i][j].value">
                      </div>
                  </td>
              </tr>
            </tbody>
          </table>
      </div>
  </div>
</div>


<div class="row mb-3 mt-3">
  <div class="col-md-12">
      <nb-select  *ngIf="benchmarkMeasurementList.length > 0"
                  class="selectionstyle mb-4"
                  placeholder="Select"
                  [(ngModel)]="selectedBenchmarkItem">
          <nb-option *ngFor="let item of benchmarkMeasurementList" [value]="item.id">{{ item.description }}</nb-option>
      </nb-select>
      <span class="ml-3 mb-3" style="vertical-align:middle">
          <button
            class="ml-3 addmeasurementbutton"
            [disabled]="cantAddBenchmark()"
            (click)="addBenchmark()"
          >ADD MEASUREMENT</button>
      </span>
  </div>
</div>

<div class="row mb-3 no-gutters">
  <h3 class="titleText">
      Core Product Measurements
  </h3>
</div>

<div class="messagebox" *ngIf="showCoreProductMeasurementMsg">
  <div class="row mb-3 ml-3">
      <div class="col-md-11 mt-3 mb-3">
          <div class="messageboxtext">
              Core product measurements are taken with the product placed on the fit form.
              The garments are matched to the fit point on each size fit form. Then the
              garment is take off, and measured. They can be 1/2 measurements or full measurements.
              They depend on the fit points identified on each size fit form.
          </div>
      </div>
      <div class="col-md-1 mt-3 mb-3">
          <div class="float-right mr-3">
              <a [routerLink]="" (click)="removeCoreMeasurementMsg()">
                  <img style="width: 24px;height:24px" src="../../assets/icons/27-icon-close-black.png">
              </a>
          </div>
      </div>
  </div>

  <div class="row ml-3 mb-3">
      <div class="col-md-6 mb-3">
          <nb-checkbox type="checkbox"

                      [(ngModel)]="doNotShowCoreMeasurementsMsg"
                      style="vertical-align:middle">
          </nb-checkbox>
          <span class="checkboxLabelText ml-3">Don't show this again</span>
      </div>
  </div>
</div>


  <div class="row mb-1">
    <div class="col-md-12">
        <div class="table-responsive" *ngIf="productSizeList.length > 0">
            <table>
              <thead>
                <tr>
                    <th colspan="3"></th>
                    <th class="labelText bottomBorder" [attr.colspan]="productSizeList.length">FLAT MEASUREMENTS (CM)</th>
                </tr>
                <tr class="bottomBorder">
                  <th class="labelText"><div>MEASUREMENT NAME</div></th>
                  <th class="labelText"><div>DELETE ROW</div></th>
                  <th class="labelText"><div>USE 1/2 MEASUREMENT</div></th>
                  <ng-container *ngFor="let item of productSizeList;let i=index">
                      <th class="labelText"><div style="width:120px">{{ item.shortName }}</div></th>
                      <th class="labelText" *ngIf="(i+1) != productSizeList.length"><div style="width:10px;padding-right:15px">+</div></th>
                      <th class="labelText" *ngIf="(i+1) != productSizeList.length"><div style="width:120px">GRADE RULE</div></th>
                      <th class="labelText" *ngIf="(i+1) != productSizeList.length"><div style="width:10px;padding-right:15px">=</div></th>
                  </ng-container>
                </tr>
              </thead>
              <tbody class="mr-2">
                <tr class="bottomBorder" *ngFor="let item of product.coreMeasurementsList;let i=index">

                    <td class="labelText">{{ item[0].measurement.description }}</td>
                    <td class="labelText">
                        <a class="linkstyles" (click)="removeCore(i)">
                            <img style="width: 24px;height:24px" src="../../../../assets/icons/27-icon-trash-2.png">
                        </a>
                    </td>
                    <td class="labelText">
                        <nb-toggle
                          [(ngModel)]="product.coreMeasurementsList[i][0].isHalfMeasurement"
                          (checkedChange)="setCoreHalfMeasurement($event,i)"></nb-toggle>
                    </td>
                    <ng-container *ngFor="let measurement of item;let j=index">
                      <td>
                          <div style="width:150px">
                              <input type="number"
                                      nbInput
                                      (change)="onCoreCalcValue(i,j)"
                                      [(ngModel)]="product.coreMeasurementsList[i][j].value">
                          </div>
                      </td>
                      <td class="labelText" *ngIf="(j+1) != item.length"><div style="width:10px;padding-right:15px">+</div></td>
                      <td *ngIf="(j+1) != item.length">
                          <div style="width:150px">
                              <input type="number"
                                      nbInput
                                      (change)="onCoreCalcGradeRule(i,j)"
                                      [(ngModel)]="product.coreMeasurementsList[i][j].gradeRule">
                          </div>
                      </td>
                      <td class="labelText" *ngIf="(j+1) != item.length"><div style="width:10px;padding-right:15px"> = </div></td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
  </div>

<div class="row mb-3 mt-3">
  <div class="col-md-12">
      <nb-select *ngIf="coreMeasurementList.length > 0"
                 class="selectionstyle mb-4"
                 placeholder="Select"
                 [(ngModel)]="selectedCoreItem">
          <nb-option *ngFor="let item of coreMeasurementList" [value]="item.id">{{ item.description }}</nb-option>
      </nb-select>
      <span class="ml-3 mb-3" style="vertical-align:middle">
          <button
            type="button"
            class="ml-3 addmeasurementbutton"
            [disabled]="cantAddCore()"
            (click)="addCore()">ADD MEASUREMENT</button>
      </span>
  </div>
  <div class="col"></div>
</div>

<!-- <div class="row mb-3" >
  <div class="col-md-12">
      <button [nbSpinner]="processing" nbSpinnerStatus="primary" type="button" class="nextbutton" [disabled]="!requiredFields()" (click)=onNext()>NEXT</button>
      <a [routerLink]="" class="linkstyles ml-3" *ngIf="requiredFields() && !processing" (click)="saveForLater()" style="font-size:16px">SAVE FOR LATER</a>
      <span class="labelText ml-3" *ngIf="!requiredFields()" style="font-size:16px">SAVE FOR LATER</span>
      <span class="labelText ml-3" *ngIf="requiredFields() && processing" style="font-size:16px">SAVE FOR LATER</span>
  </div>
  <div class="col"></div>
</div> -->

<div class="row mb-3">
  <div class="col-md-12">
      <button class="mb-4 save-btn"
              [nbSpinner]="processing"
              nbSpinnerStatus="primary"
              shape="round"
              [disabled]="!requiredFields()"
              nbButton
              (click)="onSave()">
          SAVE
      </button>
  </div>
  <div class="col"></div>
</div>
