<div class="container-fluid">
  <div class="row mb-3 mt-3 no-gutters">
    <a [routerLink]="['/customers']" class="linkText">&lt; All Customers</a>
  </div>
  <div class="row mb-3 mt-3 no-gutters">
    <nav class="entity-home-nav">
      <a
        [routerLink]="['/customer/', customer.id]"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact: true}"
        class="linkText tab"
      >Customer Details</a>
      <a
        [routerLink]="['/customer/', customer.id, 'measurements']"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact: true}"
        class="linkText tab"
      >Measurement Profile</a>
      <a
        [routerLink]="['/customer/', customer.id, 'fit-session']"
        [queryParams]="{ genderId: customer.gender.id}"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact: true}"
        class="linkText tab"
      >Fit Session</a>
    <div *ngIf="isAdmin">
      <a
      [routerLink]="['/customer/', customer.id, 'fit-analysis']"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{exact: true}"
      class="linkText tab"
    >Fit Analysis</a>
      <a
      [routerLink]="['/customer/', customer.id, 'fit-recommendation-assesment']"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{exact: true}"
      class="linkText tab"
    >Fit Recommendation Assesment</a>
    </div>
    </nav>
  </div>
  <div class="row mb-3 mt-4 no-gutters">
    <h2 class="titleText">Customer: {{customer.nameFirst + ' ' + customer.nameLast}}</h2>
  </div>
  <router-outlet></router-outlet>
</div>
