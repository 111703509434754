import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  title = 'passen-cloud'

  processing$ = this.router.events.pipe(
    filter(e => (
      e instanceof NavigationStart
      || e instanceof NavigationEnd
      || e instanceof NavigationCancel
      || e instanceof NavigationError
    )),
    map(e => {
      if (e instanceof NavigationStart) {
        return true;
      }
      return false;
    })
  );

  get needsLayout() {
    return ([
      '/login',
      '/reset-password'
    ].indexOf(this.router.url) === -1)
  }
}
