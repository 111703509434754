import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  processing:boolean = false;
  errormsg:string = "";
  firstname:string;
  lastname:string;
  email:string;
  rolestring:string;
  orgstring:string;

  displayState:number = 1;
  displayStates = {
    "cancelchangepassword":1,
    "signinsuccessful":1
  };

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private authService:AuthService) { }

  ngOnInit(): void {
    this.processing = false;
    this.errormsg = "";
    this.displayState = 1;
    this.firstname = this.authService.activeUser.nameFirst;
    this.lastname = this.authService.activeUser.nameLast;
    this.email = this.authService.activeUser.email;
    this.rolestring = this.authService.activeUser.getStringOfRoles();
    this.orgstring = this.authService.activeUser.getStringOfOrganizations();
  }

  async onUpdate(){
    this.processing = true;
    this.authService.activeUser.nameFirst = this.firstname;
    this.authService.activeUser.nameLast = this.lastname;
    try {      
      await this.authService.activeUser.update();
      this.processing = false;
      this.sendUpdateMessage();
    }
    catch(err){
      this.processing = false;
      this.errormsg = err;
    }    
  }

  sendUpdateMessage(){
    var eventMsg = {}
    eventMsg['message'] = 'profileupdatecomplete';
    this.messageEvent.emit(eventMsg);
  }

  onChangePassword(){
    this.displayState = 2;
  }

  receiveMessage($event) {
    var msg = $event['message'];
    this.displayState = this.displayStates[msg];
  } 
  
  onCancel(){
    var eventMsg = {}
    eventMsg['message'] = 'profilecancel';
    this.messageEvent.emit(eventMsg);
  }

}
