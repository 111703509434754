import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  errorMsg = "";
  processing = false;

  showPassword:boolean = false;
  showPasswordText:string = "";
  changePassword = false;

  /* Form entry */
  email:string = "";
  password:string = "";
  newpassword:string = "";
  confirmpassword:string = "";
  portalUser = undefined;

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private authService: AuthService, private router: Router, ){}

  ngOnInit(): void {
    this.showPasswordText = "SHOW";
    this.changePassword = false;
  }

  onToggleShowPassword(){
    this.showPassword = !this.showPassword;
    if(this.showPassword===true){
      this.showPasswordText = "HIDE";
    }
    else {
      this.showPasswordText = "SHOW";
    }
  }

  onSignIn(){
    this.processing = true;
    this.errorMsg = "";
    this.authService.signinUser(this.email, this.password).then(response => {
        this.processing = false;
        this.processLoginResponse(response);
    }, error => {
        this.processing = false;
        this.processLoginException(error);
        return;
    });
  }

  onSignInNewPassword(){
    this.errorMsg = "";
    if(this.newpassword != this.confirmpassword){
      this.errorMsg = "New password and confirmation password do not match";
      return;
    }

    this.processing = true;
    this.authService.completeNewPassword(this.email, this.password, this.newpassword).then(response => {
        this.processing = false;
        this.signinSuccessful();
      }, error => {
        this.processing = false;
        this.processLoginException(error);
        return;
    });
  }

  async processLoginResponse(response){
    if(response.challengeName == "NEW_PASSWORD_REQUIRED"){
      this.changePassword = true;
    }
    else {
      // this.signinSuccessful();
      this.router.navigateByUrl('');
    }
  }

  processLoginException(exception){
    if(exception.code == 'PasswordResetRequiredException'){
      this.resetPassword();
    }
    else {
      this.errorMsg = exception.message==undefined ? "" : exception.message;
    }
  }

  onForgotPasswordClicked(){
    var eventMsg = {}
    eventMsg['message'] = 'forgotpassword';
    this.messageEvent.emit(eventMsg);
  }

  resetPassword(){
    var eventMsg = {}
    eventMsg['message'] = 'resetpassword';
    eventMsg['email'] = this.email;
    this.messageEvent.emit(eventMsg);
  }

  async signinSuccessful(){
    await this.authService.getAuthenticatedUser();
    var eventMsg = {}
    eventMsg['message'] = 'signinsuccessful';
    eventMsg['portaluser'] = this.authService.activeUser;
    this.messageEvent.emit(eventMsg);
  }

}
