import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomersRoutingModule } from './customers-routing.module';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomerMeasurementsComponent } from './customer-measurements/customer-measurements.component';
import { FitAnalysisComponent } from './customer-fit-analysis/fit-analysis.component';
import { CustomerFitSessionComponent } from './customer-fit-session/customer-fit-session.component';
import { FormsModule } from '@angular/forms';
import { NbAccordionModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbIconModule, NbInputModule, NbSelectModule, NbSpinnerModule, NbThemeModule } from '@nebular/theme';
import { CustomerHomeComponent } from './customer-home/customer-home.component';
import { CustomerFitSessionProductComponent } from './customer-fit-session-product/customer-fit-session-product.component';
import { SliderComponent } from '../slider/slider.component';
import { FileChooserComponent } from '../file-chooser/file-chooser.component';
import { FileInputValueAccessor } from '../file-chooser/file-input-accessor';
import { DndDirective } from '../dnd/dnd.directive';
import { CustomerFitRecommendationAssessmentComponent } from './customer-fit-recommendation-assesment/customer-fit-recommendation-assesment.component';


@NgModule({
  declarations: [
    CustomerListComponent,
    CustomerDetailComponent,
    CustomerMeasurementsComponent,
    FitAnalysisComponent,
    CustomerFitSessionComponent,
    CustomerHomeComponent,
    CustomerFitSessionProductComponent,
    SliderComponent,
    FileChooserComponent,
    FileInputValueAccessor,
    DndDirective,
    CustomerFitRecommendationAssessmentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CustomersRoutingModule,

    NbThemeModule.forRoot(),
    NbCheckboxModule,
    NbInputModule,
    NbSelectModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    NbSpinnerModule,
    NbAccordionModule
  ]
})
export class CustomersModule { }
