<div class="container">
  <!-- Left side for measurements and upload functionality -->
  <div class="form-column">
    <div class="labelText">Upload Measurements</div>
    <div class="upload-measurements">
      <app-file-chooser [acceptFileTypes]="'csv'" [multipleFiles]="false" #fileUpload></app-file-chooser>
      <button shape="round" class="mt-3"
        size="medium"
        status="primary"
        [nbSpinner]="uploadInProgress"
        nbButton outline (click)="uploadMeasurements()">
        Upload
      </button>
    </div>

    <div class="upload-measurements">
      <button shape="round" class="mt-3"
        size="medium"
        status="primary"
        [nbSpinner]="updateInProgress"
        nbButton outline (click)="updateMeasurements()">
        Update Measurements
      </button>
    </div>

    <div class="customer-measurements" *ngIf="customerModelService.activeCustomer?.measurements">
      <div class="labelText">Current Measurements</div>
      <p class="mt-5" *ngIf="(customerModelService && customerModelService.activeCustomer && customerModelService.activeCustomer.measurements).length === 0">
        No measurements found. Please get measured or upload measurements above.
      </p>
      <table *ngIf="(customerModelService?.activeCustomer?.measurements || []).length > 0">
        <tr>
          <th class="labelText">Measurement</th>
          <th class="labelText">Value</th>
        </tr>
        <tr *ngFor="let measurement of (customerModelService?.activeCustomer?.measurements || [])">
          <td>{{ measurement?.measurement?.description }}</td>
          <td>{{ measurement?.value }}</td>
        </tr>
      </table>
    </div>
  </div>

  <!-- Right side for the 3D model container -->
  <div class="model-column">
    <div #3dModelContainer style="width: 100%; height: 500px; position: relative; background-color: #fff;">
      <div id="loader" *ngIf="loading" class="loading-container">
        Loading...
      </div>
    </div>
  </div>
</div>
