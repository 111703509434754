import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { HomeComponent } from './home/home.component';
import { AccountComponent } from './account/account.component';
// import { ProductshomeComponent } from './products/productshome/productshome.component';
// import { CustomershomeComponent } from './customers/customershome/customershome.component';
// import { ClientshomeComponent } from './clients/clientshome/clientshome.component';
import { AuthGuard, AuthAdminGuard, UnAuthGuard } from './services/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// import { ProductshomeComponent } from './products/productshome/productshome.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
// import { CustomereditComponent } from './customers/customeredit/customeredit.component';
// import { CustomeraccountdetailsComponent } from './customers/customeraccountdetails/customeraccountdetails.component';


const appRoutes: Routes = [
  { path: '', redirectTo: '/customers', pathMatch: 'full' },

  // { path: 'products', component: ProductshomeComponent, canActivate: [AuthGuard] },

  // { path: 'customers', component: CustomershomeComponent, canActivate: [AuthGuard] },
  // { path: 'customer/new', component: CustomeraccountdetailsComponent, canActivate: [AuthGuard] },
  // { path: 'customer/:id', component: CustomershomeComponent, canActivate: [AuthGuard] },
  // { path: 'customer/:id/fit-session', component: CustomershomeComponent, canActivate: [AuthGuard] },
  // { path: 'customer/:id/measurements', component: CustomershomeComponent, canActivate: [AuthGuard] },

  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [UnAuthGuard] },
  { path: 'reset-password', component: ForgotpasswordComponent, canActivate: [UnAuthGuard] },

  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

