import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomerModel, CustomerModelService } from '../model/customermodel.service';
import { CountryModel, GenderModel, LookupModelService } from '../model/lookupmodel.service';
import { OrganizationModel } from '../model/portaluser.model';
import { ProductModel, ProductmodelService } from '../model/productmodel.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersListResolverService implements Resolve<CustomerModel[]> {

  constructor(
    private customerModelService: CustomerModelService,
    private authService: AuthService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CustomerModel[]> | Observable<never> {
    let orgIds = []
    // filter customer list if active user is not admin
    if (!this.authService.activeUser?.isAdmin()) {
      orgIds = this.authService.activeUser.organizationIds();
    }

    return from(this.customerModelService.loadCustomerList(true, orgIds));
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomerResolverService implements Resolve<CustomerModel> {

  constructor(
    private customerModelService: CustomerModelService,
    private authService: AuthService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CustomerModel> | Observable<never> {

    let orgIds = []
    if (!this.authService.activeUser?.isAdmin()) {
      orgIds = this.authService.activeUser.organizationIds();
    }
    const customerList$ = from(this.customerModelService.loadCustomerList(false, orgIds));

    const customerId = state.url.split('/')[2];

    return customerList$.pipe(
      map(list => list.find(item => (
        item.id === customerId
      ))),
      tap((activeCustomer) => this.customerModelService.activeCustomer = activeCustomer)
    );
  }
}

@Injectable({providedIn: 'root'})
export class CustomerNewResolverService implements Resolve<CustomerModel> {
  constructor() {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) : CustomerModel {
      return new CustomerModel();
  }
}

@Injectable({providedIn: 'root'})
export class GenderListResolver implements Resolve<GenderModel[]> {
  constructor(private lookupModelService: LookupModelService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GenderModel[]> | Observable<never> {
     return from(this.lookupModelService.getGenders());
  }
}

@Injectable({providedIn: 'root'})
export class ClientListResolver implements Resolve<OrganizationModel[]> {
  constructor(private lookupModelService: LookupModelService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<OrganizationModel[]> | Observable<never> {
     return from(this.lookupModelService.getOrganizations());
  }
}

@Injectable({providedIn: 'root'})
export class CountryListResolver implements Resolve<CountryModel[]> {
  constructor(private lookupModelService: LookupModelService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CountryModel[]> | Observable<never> {
     return from(this.lookupModelService.getCountries());
  }
}

@Injectable({providedIn: 'root'})
export class ProductListResolverService implements Resolve<ProductModel[]> {
  constructor (
    private productService: ProductmodelService,
    private customerModelService: CustomerModelService,
    private authService: AuthService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ProductModel[]> | Promise<ProductModel[]> {

    const needsReload = route.url[0].path === 'fit-session';

    return from(this.productService.loadCurrentListWImages(needsReload)).pipe(
      map((products) => {
        let filteredProducts:ProductModel[] = [];
        if (!this.authService.activeUser?.isAdmin()) {
          const orgIds = this.authService.activeUser?.memberships?.map(x => x.organization.id) || [];

          filteredProducts = products.filter(x => x.clients?.find(client => orgIds.includes(client.id)));
        } else {
          filteredProducts = products
        }

        return filteredProducts;
      })
    )
  }
}

@Injectable({providedIn: 'root'})
export class ProductResolveService implements Resolve<ProductModel> {
  constructor(private productService: ProductmodelService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): ProductModel | Observable<ProductModel> | Promise<ProductModel> {
      return from(this.productService.loadCurrentListWImages()).pipe(
        map(productList => {
          return productList.find(p => p.id === route.params.product_id)
        })
      )
  }
}

@Injectable({providedIn: 'root'})
export class RecommendationsResolveService implements Resolve<any> {
  constructor(
    private productService: ProductmodelService,
    private customerModelService: CustomerModelService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const customerId = state.url.split('/')[2]; // customer/:id/fit-session/product/:product_id
    const productId = route.params.product_id;
    return from(
      this.productService
      .loadFitRecommendationsForProduct(customerId, productId)
    )
  }
}
