import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  errorMsg = "";
  processing = false;

  showPassword:boolean = false;
  showPasswordText:string = "";

  email:string = "";
  verificationcode:string = "";
  newpassword:string = "";
  confirmpassword:string = "";

  showForgotPassword:boolean = true;
  showResetPassword:boolean = false;

  displayMode:number = 1;
  displayModes = {
    "forgotpassword":1,
    "resetpassword":2
  };

  @Output() messageEvent = new EventEmitter<any>();
  @Input() resetEmail:string;

  constructor(
    private authService: AuthService,
    private router: Router
  ){}

  ngOnInit(): void {
    if(this.resetEmail != undefined && this.resetEmail.length > 0){
      this.email = this.resetEmail;
      this.onForgotPassword();
    }
    else{
      this.displayMode = 1;
    }
  }

  onCancelForgotPasswordClicked(){
    var eventMsg = {}
    eventMsg['message'] = 'cancelforgotpassword';
    this.messageEvent.emit(eventMsg);
  }

  onForgotPassword(){
    console.log('onForgotPassword',this.email);
    this.errorMsg = "";
    this.processing = true;
    this.authService.forgotPassword(this.email).then(response => {
         this._showResetPassword();
        this.processing = false;
        return;
    }, error => {
        this.processing = false;
        console.log(error);
        this.errorMsg = error.message;
        return;
    });

  }

  _showResetPassword(){
    this.displayMode = this.displayModes["resetpassword"];
  }

  onResetPassword(){
    this.errorMsg = "";

    if(this.newpassword != this.confirmpassword){
      this.errorMsg = "New password and confirmation password do not match";
      return;
    }

    this.processing = true;
    this.authService.confirmPasswordChangeAfterForgotPassword(this.verificationcode,this.newpassword,this.email).then(response => {
        this.errorMsg = "";
        this.processing = false;
        this.onSignIn();
    }, error => {
        console.log(error);
        this.processing = false;
        this.errorMsg = error.message;
        return;
    });

  }

  onSignIn(){
    this.processing = true;
    this.errorMsg = "";
    this.authService.signinUser(this.email, this.newpassword).then(response => {
        this.processing = false;
        this.router.navigateByUrl('');
        // this.signinSuccessful();
    }, error => {
        this.processing = false;
        this.errorMsg = error==undefined ? "" : error.message;
        return;
    });
  }

  signinSuccessful(){
    var eventMsg = {}
    eventMsg['message'] = 'signinsuccessful';
    eventMsg['portaluser'] = this.authService.activeUser;
    this.messageEvent.emit(eventMsg);
  }

}
