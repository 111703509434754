import { Injectable } from '@angular/core';

import API, { graphqlOperation } from '@aws-amplify/api';
import { UserQuery, UpdateUserMutation } from '../../API';
import { userWithRoleOnly,updateUserProfile } from '../../graphql/custom';

@Injectable()
export class PortalUser {
  public id:string="0";
  public userName:string;
  public nameFirst: string;
  public nameLast: string;
  public phone:string;
  public email: string;

  memberships:MembershipModel[] = [];

    constructor() {
      this.id = "0";
    }

    isAdmin(){
      let returnValue = false;
      for(let item of this.memberships){
        if(item.isAdmin()){
          returnValue = true;
          break;
        }
      }
      return returnValue;
    }

    isPartner(){
      let returnValue = false;
      for(let item of this.memberships){
        if(item.isPartner()){
          returnValue = true;
          break;
        }
      }
      return returnValue;
    }

    isCustomer(){
      let returnValue = false;
      for(let item of this.memberships){
        if(item.isCustomer()){
          returnValue = true;
          break;
        }
      }
      return returnValue;
    }

    isSuper(){
      let returnValue = false;
      for(let item of this.memberships){
        if(item.isSuper()){
          returnValue = true;
          break;
        }
      }
      return returnValue;
    }

    async load(email:string,forcereload=false) {
      if(this.id == "0" || forcereload == true) {
        this.memberships = [];
        let queryVars = {};
        queryVars['email'] = email;

        try{
          const response = (await API.graphql(graphqlOperation(userWithRoleOnly,queryVars))) as {
            data: UserQuery;
          };

          if(response.data.user == undefined){
            throw new Error('Error updating Profile. Contact your administrator.');
          }
          this.id = response.data.user.id != undefined ? response.data.user.id : "";
          this.email = response.data.user.email != undefined ? response.data.user.email : "";
          this.userName = response.data.user.userName != undefined ? response.data.user.userName : "";
          this.nameLast = response.data.user.nameLast != undefined ? response.data.user.nameLast : "";
          this.nameFirst = response.data.user.nameFirst != undefined ? response.data.user.nameFirst : "";
          this.phone = response.data.user.phone != undefined ? response.data.user.phone : "";

          if(response.data.user['memberships'] != undefined) {
            for(let itemValue of response.data.user['memberships']){
              this.memberships.push(new MembershipModel().load(itemValue));
            }
          }
        }
        catch(err){
          console.log('error loading user',err);
          throw(err)
        }
      }
      return this;
    }

    async update(){
      let updateVars = {};
      this.memberships = [];
      updateVars['userName'] = this.userName;
      updateVars['nameFirst'] = this.nameFirst;
      updateVars['nameLast'] = this.nameLast;
      // updateVars['phone'] = this.phone;

      try{
        const response = (await API.graphql(graphqlOperation(updateUserProfile,{id:this.id,input:updateVars}))) as {
          data: UpdateUserMutation;
        };

        if(response.data == undefined || response.data.updateUser == undefined){
          throw new Error('Error updating profile. Contact your administrator.');
        }

        this.id = response.data.updateUser.id != undefined ? response.data.updateUser.id : "";
        this.email = response.data.updateUser.email != undefined ? response.data.updateUser.email : "";
        this.userName = response.data.updateUser.userName != undefined ? response.data.updateUser.userName : "";
        this.nameLast = response.data.updateUser.nameLast != undefined ? response.data.updateUser.nameLast : "";
        this.nameFirst = response.data.updateUser.nameFirst != undefined ? response.data.updateUser.nameFirst : "";
        this.phone = response.data.updateUser.phone != undefined ? response.data.updateUser.phone : "";

        if(response.data.updateUser['memberships'] != undefined) {
          for(let itemValue of response.data.updateUser['memberships']){
            this.memberships.push(new MembershipModel().load(itemValue));
          }
        }
      }
      catch(err){
        var errorMsg = err.errors[0].message;
        console.log('error loading user',err);
        throw(errorMsg)
      }
      return this;
    }

    getFullName() {
      if(this.nameFirst.length===0 && this.nameLast.length===0){
        return this.email;
      }

      var separator = (this.nameFirst.length > 0) ? ' ' : ''
      return this.nameFirst + separator + this.nameLast;
    }

    getStringOfRoles() {
      var names = [];
      for(let m of this.memberships){
        m.role != undefined ? names.push(m.role.description) : '';
      }
      return names.join(', ');
    }

    getStringOfOrganizations() {
      var names = [];
      for(let m of this.memberships){
        m.organization != undefined ? names.push(m.organization.name) : '';
      }
      return names.join(', ');
    }

    organizationIds(): number[] {
      return (this.memberships.map(x => x.organization.id) || []);
    }

}

export class MembershipModel {
  public id:number;
  role:RoleModel;
  organization:OrganizationModel;

  isAdmin(){
    return this.role.isAdmin();
  }

  isPartner(){
    return this.role.isPartner();
  }

  isCustomer(){
    return this.role.isCustomer();
  }

  isSuper(){
    return this.role.isSuper();
  }

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      item['role'] != undefined ? this.role = new RoleModel().load(item['role']) : undefined;
      item['organization'] != undefined ? this.organization = new OrganizationModel().load(item['organization']) : undefined;
      return this;
    }
    else {
      return undefined;
    }
  }
}

export class RoleModel {
  public id:string;
  public description:string;
  public name:string;

  public ADMIN       = "admin";
  public PARTNER     = "partner";
  public CUSTOMER    = "customer";
  public SUPER       = "super";

      constructor(){}

      load(item:{}){
        if(Object.keys(item).length > 0){
          this.id = item['id'] != undefined ? item['id'] : "";
          this.description = item['description'] != undefined ? item['description'] : "";
          this.name = item['name'] != undefined ? item['name'] : "";
          return this;
        }
        else {
          return undefined;
        }
      }

      isAdmin(){
        return this.name===this.ADMIN;
      }

      isPartner(){
        return this.name===this.PARTNER;
      }

      isCustomer(){
        return this.name===this.CUSTOMER;
      }

      isSuper(){
        return this.name===this.SUPER;
      }

}

export class RoleTypeModel {
  public id:number;
  public role_name: string;

      constructor(id:number,
                  role_name:string) {
        this.id = id;
        this.role_name = role_name;
      }

}

export class OrganizationModel {
  public id:number;
  public name: string;

  constructor(){}

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : "";
      this.name = item['name'] != undefined ? item['name'] : "";
      return this;
    }
    else {
      return undefined;
    }
  }


}
