import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductStatusModel } from 'src/app/model/lookupmodel.service';
import { ProductModel } from '../../model/productmodel.service';

@Component({
  selector: 'app-productslist',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductsListComponent implements OnInit {

  products: ProductModel[] = [];
  filteredProducts: ProductModel[] = [];
  multiSelectDisabled: boolean = true;
  searchTerm: string = '';
  sortColumn: string = '';
  sortDirection: boolean = false; // false for ascending, true for descending

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.subscribe((data: { products: ProductModel[] }) => {
      this.products = data.products.sort((product1: ProductModel, product2: ProductModel) => {
        return product1.sku.localeCompare(product2.sku, 'en');
      });
      this.filteredProducts = this.products;
    });
  }

  filterProducts() {
    if (this.searchTerm) {
      const searchTermLower = this.searchTerm.toLowerCase();
      this.filteredProducts = this.products.filter(product =>
        this.productMatchesSearchTerm(product, searchTermLower)
      );
    } else {
      this.filteredProducts = this.products;
    }
  }

  productMatchesSearchTerm(product: ProductModel, searchTerm: string): boolean {
    const searchableFields = [
      product.sku,
      product.name,
      product.brand ? product.brand.name : '',
      product.productType ? product.productType.description : '',
      product.gender ? product.gender.description : '',
      product.clientListing(),
      product.productStatus ? product.productStatus.description : ''
    ];

    return searchableFields.some(field =>
      field.toString().toLowerCase().includes(searchTerm)
    );
  }

  onSort(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortColumn = column;
      this.sortDirection = false;
    }

    this.filteredProducts.sort((a, b) => {
      let comparison = 0;

      if (column === 'brand') {
        comparison = (a.brand ? a.brand.name : '').localeCompare(b.brand ? b.brand.name : '');
      } else if (column === 'productType') {
        comparison = (a.productType ? a.productType.description : '').localeCompare(b.productType ? b.productType.description : '');
      } else if (column === 'gender') {
        comparison = (a.gender ? a.gender.description : '').localeCompare(b.gender ? b.gender.description : '');
      } else if (column === 'client') {
        comparison = a.clientListing().localeCompare(b.clientListing());
      } else if (column === 'status') {
        comparison = (a.productStatus ? a.productStatus.description : '').localeCompare(b.productStatus ? b.productStatus.description : '');
      } else {
        comparison = String(a[column]).localeCompare(String(b[column]));
      }

      return this.sortDirection ? comparison * -1 : comparison;
    });
  }

  selectAll() {}

  select() {}

  getStatusButtonStyle(product) {
    let statusName = product.productStatus ? product.productStatus.name : '';
    if (statusName === ProductStatusModel.STATUS_INPROGRESS) {
      return 'status-pill in-progress';
    } else {
      return 'status-pill complete';
    }
  }
}
