import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { BehaviorSubject, Subject, Subscription, combineLatest } from 'rxjs';
import { filter, takeUntil, map, debounceTime } from 'rxjs/operators';
import { CustomerModel, CustomerModelService, FitSessionResultModel } from 'src/app/model/customermodel.service';
import { ProductModel, ProductmodelService, REGULAR_FIT_PREFERENCE } from 'src/app/model/productmodel.service';
import 'rxjs/add/operator/debounceTime';
import { MeasurementModel } from 'src/app/model/lookupmodel.service';
import { FitSessionMeasurementUpdate } from 'src/API';
import { updateFitSessionResult } from 'src/graphql/mutations';

interface FitPointMeasurementBreakdown {
  measurement: MeasurementModel;
  value: number;
}

interface FitPointFeedbackScore {
  name: string;
  measurementId: number;
  sizeId: number;
  value: number;
}

interface SelectOption {
  id: number;
  name: string;
}

@Component({
  selector: 'app-customer-fit-session-product',
  templateUrl: './customer-fit-session-product.component.html',
  styleUrls: ['./customer-fit-session-product.component.scss']
})
export class CustomerFitSessionProductComponent implements OnInit, OnDestroy {

  customer: CustomerModel;
  product: ProductModel;
  productList: ProductModel[];
  filteredProductList: ProductModel[];
  selectedCustomerSize: string;
  selectedFitFeedbackSize: string;
  selectedFitFeedbackSizeName: string;
  fitPointBreakdown: FitPointFeedbackScore[];
  fitFeedbackOptions: SelectOption[] = [
    { id: 0, name: "0 - Unwearably Tight" },
    { id: 0.15, name: "0.15 - Very Tight" },
    { id: 0.35, name: "0.35 - A Little Tight" },
    { id: 0.5, name: "0.5 - Ideal" },
    { id: 0.65, name: "0.65 - A Little Loose" },
    { id: 0.85, name: "0.85 - Very Loose" },
    { id: 1, name: "1 - Unwearably Loose" }
  ];

  fitPreferenceOptions: SelectOption[] = [
    { id: 1, name: "Fitted" },
    { id: 2, name: "Regular" },
    { id: 3, name: "Relaxed" }
  ];
  selectedFitPreference: number = REGULAR_FIT_PREFERENCE;

  fitSessionResults: FitSessionResultModel[] = [];

  @ViewChild('productDialogTpl', { read: TemplateRef })
  dialogTpl: TemplateRef<any>;

  dialogRef: NbDialogRef<any>;

  closeSub = Subscription.EMPTY;

  destroy$ = new Subject<void>();

  currentRecommendations$ = new Subject<any>();
  recommendedSize$ = this.currentRecommendations$
    .pipe(map(r => {
      return r.recommendation.displaySize || 'N/A';
    }));

  chosenSize$ = new BehaviorSubject<string>(null);
  chosenBreakdown$ = combineLatest([this.chosenSize$, this.currentRecommendations$])
    .pipe(
      map(([chosenSize, currentRecommendations]) => {
        let theSize = chosenSize ?? currentRecommendations.recommendation.size;
        if (theSize === "N/A") {
          return currentRecommendations.scoreBreakdown[0];
        } else {
          const chosenSize = currentRecommendations.scoreBreakdown.find(r => r.size === theSize);
          return chosenSize ?? currentRecommendations.scoreBreakdown[0];
        }
      })
    );

  loadingRecs$ = new BehaviorSubject<boolean>(true);

  dialogVm$ = combineLatest([
    this.currentRecommendations$,
    this.recommendedSize$,
    this.chosenSize$,
    this.chosenBreakdown$,
    this.loadingRecs$
  ])
    .pipe(
      map(([currentRecommendations, recommendedSize, chosenSize, chosenBreakdown, loadingRecs]) => ({
        recommendations: currentRecommendations,
        recommendedSize,
        chosenSize,
        chosenBreakdown,
        loadingRecs,
        noSizeAvailable: recommendedSize === 'N/A'
      })),
      debounceTime(100)
    );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productModel: ProductmodelService,
    private customerModel: CustomerModelService,
    private dialogService: NbDialogService,
    private location: Location
  ) {}

  ngOnInit() {
    this.route.data
      .subscribe((data: { customer: CustomerModel, product: ProductModel, productList: ProductModel[] }) => {
        this.customer = data.customer;
        this.product = data.product;
        this.productList = data.productList;

        this.setupDialog();
      });

    this.route.queryParams
      .subscribe(({ genderId }) => {
        if (genderId) {
          this.filteredProductList = this.productList.filter(p => p.gender.id === genderId);
        } else {
          this.filteredProductList = this.productList;
        }
      });

    this.setupNavigateAway();

    this.loadFitRecommendations(this.customer.id, this.product.id, this.selectedFitPreference);

    this.loadFitSessionResults(this.customer.id, this.product.id);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.closeSub.unsubscribe();
  }

  setupNavigateAway() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationStart),
      filter(e => {
        const parts = (e as NavigationStart).url.split('/');
        const [lastPart, _query] = parts[parts.length - 1].split('?');
        return lastPart === 'fit-session';
      }),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.closeSub.unsubscribe();
      this.dialogRef && this.dialogRef.close();
    });
  }

  setupDialog() {
    setTimeout(() => {
      this.dialogRef = this.dialogService.open(this.dialogTpl, {});

      this.closeSub.unsubscribe();

      this.closeSub = this.dialogRef.onClose.subscribe(() => {
        this.router.navigate(['../../'], {
          relativeTo: this.route,
          queryParamsHandling: 'preserve'
        });
      });

    }, 1);
  }

  get prevProductBtnDisabled() {
    const productIdx = this.filteredProductList.indexOf(this.product);
    return productIdx === 0;
  }

  get nextProductBtnDisabled() {
    const productIdx = this.filteredProductList.indexOf(this.product);
    return productIdx === this.filteredProductList.length - 1;
  }

  showPrevProduct() {
    const productIdx = this.filteredProductList.indexOf(this.product);

    if (!this.prevProductBtnDisabled) {
      this.product = this.filteredProductList[productIdx - 1];
      this.replaceUrl();
      this.loadFitRecommendations(this.customer.id, this.product.id, this.selectedFitPreference);
    }

  }

  showNextProduct() {
    const productIdx = this.filteredProductList.indexOf(this.product);

    if (!this.nextProductBtnDisabled) {
      this.product = this.filteredProductList[productIdx + 1];
      this.replaceUrl();
      this.loadFitRecommendations(this.customer.id, this.product.id, this.selectedFitPreference);
    }
  }

  private replaceUrl() {
    const prevUrl = this.router.serializeUrl(
      this.router.createUrlTree(['..'], {
        relativeTo: this.route,
        queryParamsHandling: 'preserve'
      })
    );

    const [path, query] = prevUrl.split('?');
    this.location.replaceState(`${path}/${this.product.id}?${query}`)
  }

  private loadFitRecommendations(customerId, productId, fitPreferenceId) {
    this.loadingRecs$.next(true);
    this.productModel.loadFitRecommendationsForProduct(customerId, productId, fitPreferenceId)
      .then(r => this.currentRecommendations$.next(r))
      .catch(err => console.log(err))
      .finally(() => this.loadingRecs$.next(false));
  }

  private loadFitSessionResults(customerId, productId) {
    this.loadingRecs$.next(true);
    this.customerModel.loadFitSessionResultsList(customerId, productId)
      .then(r => this.fitSessionResults = r)
      .catch(err => console.log(err))
      .finally(() => this.loadingRecs$.next(false));
  }

  chooseSize(size) {
    this.chosenSize$.next(size);
  }

  calculateLabelLow(measurement: MeasurementModel) {
    if (measurement.measurementType?.name === "length") {
      return "short";
    } else {
      return "tight";
    }
  }

  calculateLabelHigh(measurement: MeasurementModel) {
    if (measurement.measurementType?.name === "length") {
      return "long";
    } else {
      return "loose";
    }
  }

  getSliderLabel(description: string): string {
//     if (description === 'Bicep Girth') {
//       return 'Sleeve Length';
//     }
    return description;
  }

  onSelectCustomerPreferredSize(preferredSize: string) {
    console.log("edit preferred size");
    // TODO: Save preferred size.
  }

  onSelectFitFeedbackSize($event = undefined, scoreBreakdown) {
    const breakdown = scoreBreakdown.find(x => x.size === $event);
    if (breakdown) {
      this.selectedFitFeedbackSizeName = breakdown.displaySize;
      this.fitPointBreakdown = this.initializeFitPointBreakdown(breakdown.scoreBreakdown as FitPointMeasurementBreakdown[]);
    }
  }

  initializeFitPointBreakdown(scoreBreakdown: FitPointMeasurementBreakdown[]): FitPointFeedbackScore[] {
    return scoreBreakdown.map(item => {
      const fitSessionResult = this.fitSessionResults.find(x => x.measurementId === item.measurement.id.toString() && x.sizeId === this.selectedFitFeedbackSize) || {} as FitSessionResultModel;
      return {
        name: item.measurement.description,
        measurementId: item.measurement.id,
        sizeId: parseInt(this.selectedFitFeedbackSize),
        value: fitSessionResult.fitTechnicianNormalizedScore
      }
    })
  }

  onSelectFitPreference() {
    this.loadFitRecommendations(this.customer.id, this.product.id, this.selectedFitPreference);
  }

  onUpdateFitFeedbackScore(newValue: number, item: FitPointFeedbackScore) {
    const existingFitSessionResult = this.fitSessionResults.find(x => x.measurementId === item.measurementId.toString() && x.sizeId === this.selectedFitFeedbackSize);
    if (existingFitSessionResult) {
      existingFitSessionResult.fitTechnicianNormalizedScore = newValue;
      existingFitSessionResult.update();
    } else {
      // TODO: Insert.
      let newFitSessionResult = new FitSessionResultModel;
      newFitSessionResult.measurementId = item.measurementId.toString();
      newFitSessionResult.sizeId = item.sizeId.toString();
      newFitSessionResult.fitTechnicianNormalizedScore = item.value;
      newFitSessionResult.customerId = this.customer.id;
      newFitSessionResult.productId = this.product.id
      newFitSessionResult.isValid = true;

      this.fitSessionResults.push(newFitSessionResult);
      newFitSessionResult.create();
    }
  }

  updateBodyMeasurements() {
    // TODO: Call recalibration endpoint.
  }
}
