import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { LookupModelService, OrganizationModel } from 'src/app/model/lookupmodel.service';
import { ProductModel } from 'src/app/model/productmodel.service';

@Component({
  selector: 'app-product-client-assignment',
  templateUrl: './product-client-assignment.component.html',
  styleUrls: ['./product-client-assignment.component.scss']
})
export class ProductClientAssignmentComponent implements OnInit {

  product: ProductModel;
  clientList: OrganizationModel[];
  selectedClientItem: string;
  processing = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private lookupModelService: LookupModelService
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(
      (data: {
          product: ProductModel,
          clientList: OrganizationModel[]
      }) => {
          this.product = data.product;
          this.clientList = data.clientList;
      }
    );
  }

  clientExists() {
    this.product.clients.reduce((acc, client) => {
      return acc || client.id === parseInt(this.selectedClientItem, 10);
    }, false)
  }

  async onSave() {
    this.processing = true;
    try {
        const updatedProduct = await this.product.update();

        // this.router.navigate(['/product', updatedProduct.id, 'measurements']);
    } catch (err) {
        console.error(err);
    } finally {
        this.processing = false;
    }
  }

  selectClientItem(item) {
    this.selectedClientItem = item;
  }

  async addClient() {
    var selectedClient = await this.lookupModelService.getOrganization(this.selectedClientItem);
    var newClient = new OrganizationModel();
    newClient.id = selectedClient.id;
    newClient.name = selectedClient.name;
    newClient.createdAt = moment().toString();
    this.product.clients.push(newClient);
    this.selectedClientItem = undefined;
  }

  onRemoveClient(index) {
    this.product.clients.splice(index,1);
  }

  getFormatedDate(date){
    let returnDate = moment(date,'ddd MMM D YYYY HH:mm:ss ZZ');
    if(returnDate.isValid()){
      return moment(date,'ddd MMM D YYYY HH:mm:ss ZZ').format('ll');
    }

    returnDate = moment(date,'YYYY-MM-DD HH:mm:ss');
    if(returnDate.isValid()){
      return moment(date,'YYYY-MM-DD HH:mm:ss').format('ll');
    }
    return "";
  }

}
