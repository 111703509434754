
<div class="container-fluid">
    <form #f="ngForm" >
        <div class="ml-3 mt-5"> <!-- div container with buffer left 3 -->
            <div class="row mb-3">
                <div class="col-md-6 titleText">
                    Account Profile
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-5">
                    <div class="labelText mb-1">FIRST NAME</div>
                    <input type="text"
                        class="form-control mb-4"
                        id="firstname"
                        name="firstname"
                        [(ngModel)]="firstname"
                        [ngClass]="{'inputError':!firstnameentry.valid && firstnameentry.touched}"
                        #firstnameentry="ngModel"
                        required>
                    <div class="errorText" *ngIf="!firstnameentry.valid && firstnameentry.touched">Please provide a first name</div>

                    <div class="labelText mb-1">LAST NAME</div>
                    <input type="text"
                        class="form-control mb-4"
                        id="lastname"
                        name="lastname"
                        [(ngModel)]="lastname"
                        [ngClass]="{'inputError':!lastnameentry.valid && lastnameentry.touched}"
                        #lastnameentry="ngModel"
                        required>
                    <div class="errorText" *ngIf="!lastnameentry.valid && lastnameentry.touched">Please provide a last name</div>

                    <div class="labelText mb-1">EMAIL</div>
                    <div class="labelText mb-4">{{ email }}</div>

                </div>

                <div class="col-md-4 no-gutters">
                    <div class="labelText mb-1">CLIENT ASSIGNMENT</div>
                    <div class="col-md-4 labelText d-sm-block d-none mb-5" style="font-size:16px">{{ orgstring }}</div>
                    <div class="col-md-4 labelText d-sm-none mb-4" style="font-size:16px">{{ orgstring }}</div>

                    <div class="labelText mb-1">ROLE</div>
                    <div class="col-md-4 labelText mb-4" style="font-size:16px">{{ rolestring }}</div>

                </div>

                <div class="col"></div>

            </div>

            <div class="row mt-1 mb-5" *ngIf="errormsg.length > 0">
                <div class="col-md-6">
                    <span class="errorText">{{ errormsg }}</span>
                </div>
            </div>

            <div class="row mt-4 mb-5">
                <div class="col-md-6">
                    <button type="button" (click)="onUpdate()" class="btn btn-primary submitButton" style="width:100px" [disabled]="!f.valid || processing">UPDATE</button>
                    <button type="button" (click)="onChangePassword()" class="btn btn-primary submitButton ml-2" style="width:178px" [disabled]="!f.valid || processing">CHANGE PASSWORD</button>
                    <span class="ml-2"><a routerLink="/" class="linkstyles">Cancel</a></span>
                </div>
            </div>

        </div>  <!-- end div container with buffer left 3 -->
    </form>
</div>


