import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BrandModel,
    GenderModel,
    ProductTypeModel,
    SizeModel,
} from 'src/app/model/lookupmodel.service';
import {
    ProductImageModel,
    ProductModel,
} from 'src/app/model/productmodel.service';
import { FileTransferService } from 'src/app/services/filetransfer.service';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
    product: ProductModel;
    brands: BrandModel[];
    productTypes: ProductTypeModel[];
    genderList: GenderModel[];
    sizeList: SizeModel[];

    processing: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fileTransferService: FileTransferService
    ) {}

    ngOnInit() {
        this.route.data.subscribe(
            (data: {
                product: ProductModel;
                brands: BrandModel[];
                productTypes: ProductTypeModel[];
                genderList: GenderModel[];
                sizeList: SizeModel[];
            }) => {
                this.product = data.product;
                this.brands = data.brands.sort((a, b) => {
                    let valueA = a.name.toUpperCase();
                    let valueB = b.name.toUpperCase();
                    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
                });
                this.productTypes = data.productTypes;
                this.genderList = data.genderList;
                this.sizeList = data.sizeList;
            }
        );
    }

    onEditBrands() {}

    onFileDropped(files: Array<any>) {
        console.log('Files Dropped', files);
        this.prepareFilesList(files);
    }

    fileBrowseHandler(files: Array<any>) {
        console.log('Files Browsed', files);
        this.prepareFilesList(files);
    }

    async onCloneProduct(productId: string) {
        try {
            console.log('onCloneProduct', productId);

            const new_id = await this.product.clone();
            this.router.navigate(['/product', new_id]);
        } catch (err) {
            console.error(err);
        }
    }

    prepareFilesList(files: Array<any>) {
        console.log('Preparing Files', files);
        for (let item of files) {
            item.progress = 0;
            let productImage = new ProductImageModel();
            productImage.productId = this.product.id;
            productImage.productImageTypeId = '2';
            productImage.setFile(item);
            this.product.files.push(item);
            this.product.images.push(productImage);
        }
    }

    removeFile(index: number) {
        this.product.images.splice(index, 1);
        this.product.files.splice(index, 1);
    }

    requiredFields(): boolean {
        return (
            this.product.brand_id != undefined &&
            this.product.product_type_id != undefined &&
            this.product.name != undefined &&
            this.product.name.length > 0 &&
            this.product.sku != undefined &&
            this.product.sku.length > 0 &&
            this.product.gender_id != undefined &&
            this.product.sizeIds != undefined &&
            this.product.sizeIds.length > 0
        );
    }

    async onSave() {
        this.processing = true;
        try {
            const updatedProduct = await this.product.update();
            await this.saveImages();
            this.router.navigate(['/product', updatedProduct.id, 'measurements']);
        } catch (err) {
            console.error(err);
        } finally {
            this.processing = false;
        }
    }

    async saveImages() {
        for (let item of this.product.images) {
            let myFile = this.product.files.find(
                (el) => el.name === item.imageKey
            );
            if (myFile) {
                let uploadSignedUrl = await item.getUploadUrl();
                await this.uploadImage(myFile, uploadSignedUrl);
                await item.loadSignedUrls();
            }
        }
        this.product.files = [];
    }

    async uploadImage(file, signedurl) {
        this.fileTransferService.uploadSignedUrl(file, signedurl).subscribe(
            (data) => {
                return data;
            },
            (err) => {
                throw err;
            }
        );
    }
}
