import { Injectable } from '@angular/core';

import API, { graphqlOperation } from '@aws-amplify/api';
import { brands,
         productTypes,
         genders,
         sizes,
         fitForms,
         measurements,
         organizations,
         countries } from '../../graphql/queries';

import { BrandsQuery,
         ProductTypesQuery,
         GendersQuery,
         SizesQuery,
         FitFormsQuery,
         MeasurementsQuery,
         OrganizationsQuery,
         CountriesQuery,
         OrganizationsCustomQuery} from '../../API';
import { organizationsCustom } from 'src/graphql/custom';


@Injectable()
export class LookupModelService {
  measurements:MeasurementModel[] = [];
  brands:BrandModel[] = [];
  productTypes:ProductTypeModel[] = [];
  genders:GenderModel[] = [];
  sizes:SizeModel[] = [];
  fitForms:FitFormsModel[] = [];
  organizations:OrganizationModel[] = [];
  countries:CountryModel[] = [];
  organizationTypes:OrganizationTypeModel[] = [];


  async getBrands(forcereload=false){
    if(this.brands.length == 0 || forcereload == true) {
      this.brands = [];
      let filterDict = {
        "filter":{},
        "limit":2000
      };

      try{
        const response = (await API.graphql(graphqlOperation(brands,filterDict))) as {
          data: BrandsQuery;
        };
        for(let item of response.data.brands){
          let brand = new BrandModel();
          brand.load(item);
          this.brands.push(brand);
        }
      }
      catch(err){
        console.log('error loading brands lookup list',err);
        throw(err)
      }
    }
    return this.brands;
  }

  async getProductTypes(forcereload=false){
    if(this.productTypes.length == 0 || forcereload == true) {
      this.productTypes = [];
      let filterDict = {
        "filter":{},
        "limit":50
      };

      try{
        const response = (await API.graphql(graphqlOperation(productTypes,filterDict))) as {
          data: ProductTypesQuery;
        };
        for(let item of response.data.productTypes){
          let productType = new ProductTypeModel();
          productType.load(item);
          this.productTypes.push(productType);
        }
      }
      catch(err){
        console.log('error loading producttypes lookup list',err);
        throw(err)
      }
    }
    return this.productTypes;
  }

  async getGenders(forcereload=false){
    if(this.genders.length == 0 || forcereload == true) {
      this.genders = [];
      let filterDict = {
        "filter":{},
        "limit":10
      };

      try{
        const response = (await API.graphql(graphqlOperation(genders,filterDict))) as {
          data: GendersQuery;
        };
        for(let item of response.data.genders){
          let gender = new GenderModel();
          gender.load(item);
          this.genders.push(gender);
        }
      }
      catch(err){
        console.log('error loading genders lookup list',err);
        throw(err)
      }
    }
    return this.genders;
  }

  async getSizes(forcereload=false){
    if(this.sizes.length == 0 || forcereload == true) {
      this.sizes = [];
      let filterDict = {
        "filter":{},
        "limit":500
      };

      try{
        const response = (await API.graphql(graphqlOperation(sizes,filterDict))) as {
          data: SizesQuery;
        };
        for(let item of response.data.sizes){
          let size = new SizeModel();
          size.load(item);
          this.sizes.push(size);
        }
      }
      catch(err){
        console.log('error loading sizes lookup list',err);
        throw(err)
      }
    }
    return this.sizes;
    // need to cast as size array?
  }

  async getMeasurements(forcereload=false){
    if(this.measurements.length == 0 || forcereload == true) {
      this.measurements = [];
      let filterDict = {
        "filter":{},
        "limit":500
      };

      try{
        const response = (await API.graphql(graphqlOperation(measurements,filterDict))) as {
          data: MeasurementsQuery;
        };
        for(let item of response.data.measurements){
          let measurement = new MeasurementModel();
          measurement.load(item);
          this.measurements.push(measurement);
        }
      }
      catch(err){
        console.log('error loading measurements lookup list',err);
        throw(err)
      }
    }
    return this.measurements.sort((a,b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));
  }

  async getFitForms(forcereload=false){
    if(this.fitForms.length == 0 || forcereload == true) {
      this.fitForms = [];
      let filterDict = {
        "filter":{},
        "limit":500
      };

      try{
        const response = (await API.graphql(graphqlOperation(fitForms,filterDict))) as {
          data: FitFormsQuery;
        };
        for(let item of response.data.fitForms){
          let fitForm = new FitFormsModel();
          fitForm.load(item);
          this.fitForms.push(fitForm);
        }
      }
      catch(err){
        console.log('error loading fit forms lookup list',err);
        throw(err)
      }
    }
    return this.fitForms;
  }

  async getOrganizations(forcereload=false){
    if(this.organizations.length == 0 || forcereload == true) {
      this.organizations = [];
      let filterDict = {
        "filter":{},
        "limit":500
      };

      try{
        const response = (await API.graphql(graphqlOperation(organizationsCustom,filterDict))) as {
          data: OrganizationsCustomQuery;
        };
        for(let item of response.data.organizations){
          let organization = new OrganizationModel();
          organization.load(item);
          this.organizations.push(organization);
        }
      }
      catch(err){
        console.log('error loading organizations lookup list',err);
        throw(err)
      }
    }
    return this.organizations;
  }

  // async getOrganizationTypes(forcereload=false){
  //   if(this.organizationTypes.length == 0 || forcereload == true) {
  //     this.organizationTypes = [];
  //     let filterDict = {
  //       "filter":{},
  //       "limit":25
  //     };

  //     try{
  //       const response = (await API.graphql(graphqlOperation(brands,filterDict))) as {
  //         data: BrandsQuery;
  //       };
  //       for(let item of response.data.brands){
  //         let brand = new BrandModel();
  //         brand.load(item);
  //         this.brands.push(brand);
  //       }
  //     }
  //     catch(err){
  //       console.log('error loading brands lookup list',err);
  //       throw(err)
  //     }
  //   }
  //   return this.brands;
  // }


  async getCountries(forcereload=false){
    if(this.countries.length == 0 || forcereload == true) {
      this.countries = [];
      let filterDict = {
        "filter":{},
        "limit":500
      };

      try{
        const response = (await API.graphql(graphqlOperation(countries,filterDict))) as {
          data: CountriesQuery;
        };
        for(let item of response.data.countries){
          let country = new CountryModel();
          country.load(item);
          this.countries.push(country);
        }
      }
      catch(err){
        console.error('error loading countries lookup list',err);
      }
    }
    return this.countries;
  }


  async getOrganization(id){
    let organizations = await this.getOrganizations();
    return organizations.find((el)=>{return el.id == id});
  }

  async getMeasurement(id){
    let measurements = await this.getMeasurements();
    return measurements.find((el)=>{return el.id == id});
  }

  async getSize(id){
    let sizes = await this.getSizes();
    return sizes.find((el)=>{return el.id == id});
    // note: this is ineffecient
  }

}

export class MeasurementModel {
  id:number;
  name:string;
  description:string;
  measurementType: MeasurementTypeModel;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.name = item['name'] != undefined ? item['name'] : "";
      this.description = item['description'] != undefined ? item['description'] : "";
      this.measurementType = item['measurementType'] != undefined ? new MeasurementTypeModel().load(item['measurementType']) : undefined;

      return this;
    }
    else {
      return undefined;
    }
  }

}

export class MeasurementTypeModel {
  id:number;
  name:string;
  description:string;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.name = item['name'] != undefined ? item['name'] : "";
      this.description = item['description'] != undefined ? item['description'] : "";
      return this;
    }
    else {
      return undefined;
    }
  }

}

export class BrandModel {
  id:number;
  name:string;
  slug:string;
  description:string;
  image_url:string;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.name = item['name'] != undefined ? item['name'] : "";
      this.slug = item['slug'] != undefined ? item['slug'] : "";
      this.description = item['description'] != undefined ? item['description'] : "";
      this.image_url = item['image_url'] != undefined ? item['image_url'] : "";
      return this;
    }
    else {
      return undefined;
    }
  }
}

export class GenderModel {
  id:number;
  name:string;
  description:string;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.name = item['name'] != undefined ? item['name'] : "";
      this.description = item['description'] != undefined ? item['description'] : "";
      return this;
    }
    else {
      return undefined;
    }
  }
}

export class ProductTypeModel {
  id:number;
  name:string;
  description:string;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.name = item['name'] != undefined ? item['name'] : "";
      this.description = item['description'] != undefined ? item['description'] : "";
      return this;
    }
    else {
      return undefined;
    }
  }

}

export class SizeModel {
  id:number;
  shortName:string;
  longName:string;
  rank:string;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.shortName = item['shortName'] != undefined ? item['shortName'] : "";
      this.longName = item['longName'] != undefined ? item['longName'] : "";
      this.rank = item['rank'] != undefined ? item['rank'] : "";
      return this;
    }
    else {
      return undefined;
    }
  }

}

export class ProductStatusModel {
  static readonly STATUS_COMPLETE   = "complete";
  static readonly STATUS_INPROGRESS = "in-progress";
  static readonly STATUS_ARCHIVED   = "archived";
  static readonly STATUS_REVIEW     = "review";

  static readonly STATUS_INPROGRESS_ID:number = 1;
  static readonly STATUS_COMPLETE_ID:number   = 2;
  static readonly STATUS_ARCHIVED_ID:number   = 3;
  static readonly STATUS_REVIEW_ID:number     = 4;

  id:number;
  name:string;
  description:string;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.name = item['name'] != undefined ? item['name'] : "";
      this.description = item['description'] != undefined ? item['description'] : "";
      return this;
    }
    else {
      return undefined;
    }
  }

}

export class FitFormsModel {
  id:number;
  name:string;
  fit_form_type_id:number;
  gender_id:number;

  gender:GenderModel;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.name = item['name'] != undefined ? item['name'] : "";
      this.fit_form_type_id = item['fit_form_type_id'] != undefined ? item['fit_form_type_id'] : 0;
      this.gender_id = item['gender_id'] != undefined ? item['gender_id'] : 0;
      this.gender = item['gender'] != undefined ? new GenderModel().load(item['gender']) : undefined;
      this.gender_id = this.gender != undefined ? this.gender.id : undefined;
      this.fit_form_type_id = item['fitFormTypeId'] != undefined ? item['fitFormTypeId'] : undefined;
      return this;
    }
    else {
      return undefined;
    }
  }

}

export class FormTypesModel {
  id:number;
  name:string;
  description:string;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.name = item['name'] != undefined ? item['name'] : "";
      this.description = item['description'] != undefined ? item['description'] : "";
      return this;
    }
    else {
      return undefined;
    }
  }
}

export class OrganizationModel {
  id:number;
  name:string;
  createdAt:string;
  contactName:string;
  contactPhone:string;
  contactEmail:string;
  notes:string;
  customerCount:number;
  productCount:number;
  userCount:number;

  organizationType:OrganizationTypeModel;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.name = item['name'] != undefined ? item['name'] : "";
      this.createdAt = item['createdAt'] != undefined ? item['createdAt'] : "";
      this.contactName = item['contactName'] != undefined ? item['contactName'] : "";
      this.contactPhone = item['contactPhone'] != undefined ? item['contactPhone'] : "";
      this.contactEmail = item['contactEmail'] != undefined ? item['contactEmail'] : "";
      this.notes = item['notes'] != undefined ? item['notes'] : "";
      this.customerCount = item['customerCount'] != undefined ? item['customerCount'] : 0;
      this.productCount = item['productCount'] != undefined ? item['productCount'] : 0;
      this.userCount = item['userCount'] != undefined ? item['userCount'] : 0;
      this.organizationType = item['organizationType'] != undefined ? new OrganizationTypeModel().load(item['organizationType']) : undefined;
      return this;
    }
    else {
      return undefined;
    }
  }
}

export class CountryModel {
  id:number;
  code:string;
  description:string;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.code = item['code'] != undefined ? item['code'] : "";
      this.description = item['description'] != undefined ? item['description'] : "";
      return this;
    }
    else {
      return undefined;
    }
  }
}

export class OrganizationTypeModel {
  id:number;
  name:string;
  description:string;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.name = item['name'] != undefined ? item['name'] : "";
      this.description = item['description'] != undefined ? item['description'] : "";
      return this;
    }
    else {
      return undefined;
    }
  }
}




