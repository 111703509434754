import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerModel } from 'src/app/model/customermodel.service';
import { ProductModel, ProductmodelService } from 'src/app/model/productmodel.service';



@Component({
  selector: 'app-fit-analysis',
  templateUrl: './fit-analysis.component.html',
  styleUrls: ['./fit-analysis.component.scss']
})
export class FitAnalysisComponent implements OnInit {

  constructor(private route: ActivatedRoute, private productModel: ProductmodelService) { }


  public customer: CustomerModel = null;
  public productList: ProductModel[] = [];
  public analysisRows: any[] = [];
  public analysisColumns: string[] = [];
  public range_error_exists: boolean = false;

  selectedProductId: number = -1;
  

  ngOnInit(): void {
    this.route.data
      .subscribe((data: {
        productList: ProductModel[],
        customer: CustomerModel
      }) => {
        this.productList = data.productList.sort((product1 : ProductModel, product2: ProductModel) => {
          return (product1.name).localeCompare(product2.name, 'en');
        });
        this.customer = data.customer;
      });
  }

  async analyzeFittingResults(): Promise<void> {
    console.log(this.selectedProductId)
    this.loadAnalyzeFitRecommendations(parseInt(this.customer.id), this.selectedProductId)
    
  }

  private loadAnalyzeFitRecommendations(customerId: number, productId: number) {
    this.analysisColumns = [];
    this.analysisRows = [];
    this.productModel.analyzeFitRecommendationsForProduct(customerId, productId)
      .then(r => {
        const parsed_json = JSON.parse(JSON.parse(r.output['output']))
        this.analysisColumns = parsed_json['Columns']
        for (const key in parsed_json) {
          if (key!='Columns'){
            // turning object into array ordered by column - there is probably a better way of doing this using angular
            const current_row = [];
            for (const column_name of this.analysisColumns) {
              const val = (parsed_json[key][column_name]);
              if (val == "Y"){
                this.range_error_exists = true;
              }
              current_row.push(val);
            }
            this.analysisRows.push(current_row);
          }
        }
        })
      .catch(err => console.log(err))
  }


}
