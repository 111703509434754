<div> <!-- login details -->

    <div class="row ml-5 mt-5 d-sm-block d-none">
        <div class="col-md-12">
            <img style="width: 172px;height:46px" src="../../assets/brand/PassenLogo_HorizontalBlack.png">
        </div>
    </div>

    <div class="row ml-5 mt-4 mb-4">
          <div class="col-md-12 centernarrow">
            <h2 class="titleText">Welcome to Passen Cloud</h2>
          </div>
    </div>

    <div class="row ml-5 mt-4" *ngIf="!changePassword">
        <div class="col-md-12 centernarrow">
          <span class="summaryText">Please log in with your email and password.</span>
        </div>
    </div>

    <form #f="ngForm">

        <div class="row ml-5 mt-2" *ngIf="!changePassword">
            <div class="col-md-6">
            <span class="labelText">EMAIL</span>
            </div>
        </div>

        <div class="row ml-5 mb-2" *ngIf="!changePassword">
            <div class="col-md-7">
              <input type="email"
                     class="form-control"
                     id="email"
                     name="email"
                     #emailentry="ngModel"
                     [(ngModel)]="email"
                     [ngClass]="{'inputError':!emailentry.valid && emailentry.touched}"
                     required email>
              <div class="errorText" *ngIf="!emailentry.valid && emailentry.touched">Please provide a valid email address.</div>
            </div>
            <div class="col"></div>
        </div>

        <div class="row ml-5 mt-2" *ngIf="!changePassword">
            <div class="col-md-6">
            <span class="labelText">PASSWORD</span>
            </div>
        </div>

        <div class="row ml-5 mb-2" *ngIf="!changePassword">
            <div class="col-md-7">
                <input type="password"
                    class="form-control"
                    id="password"
                    name="password"
                    data-toggle="password"
                    [type]="showPassword ? 'text' : 'password'"
                    [(ngModel)]="password"
                    [ngClass]="{'inputError':!passwordentry.valid && passwordentry.touched}"
                    #passwordentry="ngModel"
                    required>
                <span class="showpassword-icon" (click)="onToggleShowPassword()">{{showPasswordText}}</span>
                <div class="errorText" *ngIf="!passwordentry.valid && passwordentry.touched">Please provide a password.</div>
         </div>
            <div class="col"></div>
        </div>

        <div class="row ml-5 mt-4" *ngIf="changePassword">
            <div class="col-md-12 centernarrow">
              <span class="summaryText">Your account requires a new password.</span>
            </div>
        </div>

        <div class="row ml-5 mt-2" *ngIf="changePassword">
            <div class="col-md-6">
            <span class="labelText">NEW PASSWORD</span>
            </div>
        </div>

        <div class="row ml-5 mb-2" *ngIf="changePassword">
            <div class="col-md-7">
                <input type="password"
                    class="form-control"
                    id="newpassword"
                    name="newpassword"
                    [(ngModel)]="newpassword"
                    [ngClass]="{'inputError':!newpasswordentry.valid && newpasswordentry.touched}"
                    #newpasswordentry="ngModel"
                    required>
                <div class="errorText" *ngIf="!newpasswordentry.valid && newpasswordentry.touched">Please provide a new password.</div>
            </div>
            <div class="col"></div>
        </div>

        <div class="row ml-5 mt-2" *ngIf="changePassword">
            <div class="col-md-6 ml-5">
            <span class="labelText">8 character minimum</span>
            </div>
        </div>

        <div class="row ml-5" *ngIf="changePassword">
            <div class="col-md-6 ml-5">
            <span class="labelText">1 upper case letter</span>
            </div>
        </div>

        <div class="row ml-5" *ngIf="changePassword">
            <div class="col-md-6 ml-5">
            <span class="labelText">1 lower case letter</span>
            </div>
        </div>

        <div class="row ml-5" *ngIf="changePassword">
            <div class="col-md-6 ml-5">
            <span class="labelText">1 special character: !@#$%^&*()</span>
            </div>
        </div>

        <div class="row ml-5 mt-3" *ngIf="changePassword">
            <div class="col-md-6">
            <span class="labelText">RE-ENTER NEW PASSWORD</span>
            </div>
        </div>

        <div class="row ml-5 mb-2" *ngIf="changePassword">
            <div class="col-md-7">
                <input type="password"
                    class="form-control"
                    id="confirmpassword"
                    name="confirmpassword"
                    [(ngModel)]="confirmpassword"
                    [ngClass]="{'inputError':!confirmpasswordentry.valid && confirmpasswordentry.touched}"
                    #confirmpasswordentry="ngModel"
                    required>
               <div class="errorText" *ngIf="!confirmpasswordentry.valid && confirmpasswordentry.touched">Please confirm your password.</div>
            </div>
            <div class="col"></div>
        </div>


        <div class="row ml-5 mt-4" *ngIf="!changePassword">
            <div class="col-md-6">
                <button [nbSpinner]="processing" nbSpinnerStatus="control" nbButton status="primary" shape="round" [disabled]="!f.valid || processing" (click)="onSignIn()" class="login-button">
                  Log In
                </button>
            </div>
        </div>

        <div class="row ml-5 mt-4" *ngIf="changePassword">
            <div class="col-md-6">
                <button [nbSpinner]="processing" nbSpinnerStatus="control" nbButton status="primary" shape="round" [disabled]="!f.valid || processing" (click)="onSignInNewPassword()">
                  Change Password
                </button>
            </div>
        </div>

        <div class="row ml-5 mt-2">
            <div class="col-md-8 centernarrow">
                <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
            </div>
        </div>

    </form>

    <div class="row ml-5 mb-5 mt-3" *ngIf="!changePassword">
        <div class="col-md-6 centernarrow">
            <a routerLink="/reset-password" class="linkstyles centernarrow">Forgot your password?</a>
        </div>
    </div>

</div> <!-- login details -->
