import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerModel } from 'src/app/model/customermodel.service';
import { MeasurementModel, SizeModel } from 'src/app/model/lookupmodel.service';
import { ProductModel, ProductmodelService } from 'src/app/model/productmodel.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-customer-fit-recommendation-assesment',
  templateUrl: './customer-fit-recommendation-assesment.component.html',
  styleUrls: ['./customer-fit-recommendation-assesment.component.scss']
})
export class CustomerFitRecommendationAssessmentComponent implements OnInit {

  public processing: boolean = false;
  // public product: ProductModel;
  public customer: CustomerModel;



  public analysisRows: any[] = [];
  public analysisColumns: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private productModel: ProductmodelService) { }

  ngOnInit(): void {
    this.processing = false;
    this.route.data.subscribe(
      (data: {
        // product: ProductModel;
        customer: CustomerModel;
      }) => {
        // this.product = data.product;
        this.customer = data.customer;
      }
    );
  }


  isProcessing() {
    return this.processing
  }

  async run_analysis() {

    this.processing = true;
    console.log(this.processing)
    try {
      // TODO change these hardcoded values and base on user input
      await this.loadFitAnalysisComparison([this.customer.id], ["72","66","61","71","70","68","69","91","94","64","95","93","65","96", "101", "102", "103", "104", "105"])
    } catch (err) {
      console.error(err);
    } finally {

    }
  }


  private async loadFitAnalysisComparison(customerIds: Array<string>, productIds: Array<string>) {
    this.analysisColumns = [];
    this.analysisRows = [];
    this.productModel.generateFitRecommendationComparison(customerIds, productIds)
      .then(r => {
        const parsed_json = JSON.parse(JSON.parse(r.output['output']))
        this.analysisColumns = parsed_json['Columns']
        console.log(parsed_json)
        for (const key in parsed_json) {
          if (key != 'Columns') {
            // turning object into array ordered by column - there is probably a better way of doing this using angular
            const current_row = [];
            for (const column_name of this.analysisColumns) {
              const val = (parsed_json[key][column_name]);
              current_row.push(val);
            }
            this.analysisRows.push(current_row);
          }
          console.log(this.analysisRows)
        }
        this.processing = false;
      })
      .catch(err => console.log(err))
  }


}