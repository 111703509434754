import { Injectable } from '@angular/core';

import API, { graphqlOperation } from '@aws-amplify/api';
import { organizationsCustom
        } from '../../graphql/custom';

import { OrganizationsQuery } from '../../API';

import { GenderModel,
         OrganizationModel,
         CountryModel
  } from './lookupmodel.service'

import * as moment from 'moment';

@Injectable()
export class ClientModelService {
  clientItemsList:OrganizationModel[] = [];
  activeClient:OrganizationModel;

  constructor() { }

  async loadClientList(forcereload=false) {
    if(this.clientItemsList.length == 0 || forcereload == true) {
      this.clientItemsList = [];    
      let filterDict = {
        "filter":{},
        "limit":100,
        "offset":0
      };

      try{
        const response = (await API.graphql(graphqlOperation(organizationsCustom,filterDict))) as {
          data: OrganizationsQuery;
        };
        console.log('Clients',response);
        for(let item of response.data.organizations){
          let c = new OrganizationModel();
          c.load(item);
          this.clientItemsList.push(c);
        }
      }
      catch(err){
        console.log('error loading customer list',err);
        throw(err)
      }
    }
    return this.clientItemsList;
  }

  updateItemsList(client:OrganizationModel){
    let found = false;
    let index = this.clientItemsList.findIndex((el)=>{return el.id==client.id});
    index >= 0 ? this.clientItemsList[index] = client : this.clientItemsList.push(client);    
  }

}

