<div class="row mb-3 no-gutters">
  <h3 class="titleText">Client Assignment</h3>
</div>


<div class="row mb-1">
  <div class="col-md-12">
      <div class="table-responsive">
          <table>
            <thead>
              <tr class="bottomBorder">
                <th class="labelText"><div>CLIENT NAME</div></th>
                <th class="labelText"><div>INVENTORY SKU</div></th>
                <th class="labelText"><div>DATE ADDED</div></th>
                <th class="labelText"><div>DELETE ROW</div></th>
              </tr>
            </thead>
            <tbody class="mr-2">
                <tr class="bottomBorder" *ngFor="let item of product.clients;let i=index">

                    <td class="labelText"><div>{{ item.name }}</div></td>
                    <td class="labelText"><div></div></td>
                    <td class="labelText"><div>{{ getFormatedDate(item.createdAt) }}</div></td>

                    <td class="labelText">
                        <a class="linkstyles" (click)="onRemoveClient(i)">
                            <img style="width: 24px;height:24px" src="../../../../assets/icons/27-icon-trash-2.png">
                        </a>
                    </td>
                </tr>
            </tbody>
          </table>
      </div>
  </div>
</div>


<div class="row mb-3 mt-3">
  <div class="col-md-12">
      <nb-select  *ngIf="clientList.length > 0"
                  class="selectionstyle mb-4"
                  placeholder="Select"
                  (selectedChange)="selectClientItem($event)">
          <nb-option *ngFor="let item of clientList" [value]="item.id">{{ item.name }}</nb-option>
      </nb-select>
      <span class="ml-3" style="vertical-align:middle">
          <button type="button" class="ml-3 addclientbutton" [disabled]="clientExists()" (click)="addClient()">ADD CLIENT</button>
      </span>
  </div>
  <div class="col"></div>
</div>

<div class="row mb-3 mt-5">
  <div class="col-md-12">
      <button class="mb-4"
              [nbSpinner]="processing"
              nbSpinnerStatus="primary"
              shape="round"
              style="background-color:#ebeef7;border: solid 2px #323e48;color: var(--basic-600);width: 99px;"
              nbButton (click)="onSave()">
          SAVE
      </button>

  </div>
</div>
