<nb-select  *ngIf="productList.length > 0"
  class="selectionstyle mb-4"
  placeholder="Select"
  [(ngModel)]="selectedProductId">
<nb-option *ngFor="let item of productList" [value]="item.id">{{ item.name }}</nb-option>
</nb-select>


<button type="button" (click)="analyzeFittingResults()" class="btn btn-primary submitButton">UPDATE</button>

<div *ngIf="range_error_exists">
  <div class="errorTextLarge">RANGE ERROR EXISTS</div>
</div>


<div class="fit-analysis" *ngIf="customer && productList">
  <table *ngIf="analysisColumns.length > 0 && analysisRows.length > 0">
    <tr>
      <th class="labelText"  *ngFor="let column of analysisColumns"> {{column}} </th>
    </tr>
    <tr *ngFor="let row of analysisRows">
      <td *ngFor="let val of row">
        {{ val }}
      </td>
    </tr>
  </table>
</div>
<!-- <div class="labelText">Fit Analysis</div> -->