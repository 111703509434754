<div class="container-fluid">
  <div class="d-flex ml-3">
    <div class="mr-auto"><span class="welcomeText" style="vertical-align: middle">Welcome, {{ getFirstName()}}</span></div>
      <!-- <div>
        <span class="dropdown show">
          <a [routerLink]="" class="linkText" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img style="width: 24px;height:24px" src="../../assets/icons/settings-2.png" class="mr-1">
              <span style="vertical-align: middle">SETTINGS</span>
          </a>
          <div class="row dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink" style="margin-top: 14px;width:255px">
              <div class="col text-center">
                  <form>
                    <span class="summaryText" style="padding-right:15px">Units</span>
                    <div class="btn-group">
                        <button type="button" (click)="onUnits(1)" class="btn unitsButton" [ngClass]="getUnitsButtonStyle(1)">CM</button>
                        <button type="button" (click)="onUnits(2)" class="btn unitsButton" [ngClass]="getUnitsButtonStyle(2)">INCHES</button>
                      </div>
                  </form>
              </div>
          </div>
        </span>
      </div> -->
      <div class="ml-4 mr-3">
        <span>
          <a class="linkText" [nbContextMenu]="items" nbContextMenuTag="header-context-menu">
              <img style="width: 24px;height:24px" src="../../assets/icons/person-fill.png" class="mr-1">
              <span style="vertical-align: middle">ACCOUNT</span>
          </a>
        </span>
      </div>
  </div>
</div>
