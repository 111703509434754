import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerModel, CustomerModelService } from 'src/app/model/customermodel.service';
import { CountryModel, GenderModel } from 'src/app/model/lookupmodel.service';
import { OrganizationModel } from 'src/app/model/portaluser.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {

  customer: CustomerModel;
  genderList: GenderModel[] = [];
  clientList: OrganizationModel[] = [];
  countryList: CountryModel[] = [];
  retailerList: string[] = [];
  processing: boolean = false;

  weightUnit: string = 'kg';
  heightUnit: string = 'cm';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerModelService: CustomerModelService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data: { customer: CustomerModel, genderList: GenderModel[], clientList: OrganizationModel[], countryList: CountryModel[] }) => {
      this.customer = data.customer;
      this.genderList = data.genderList;
      this.clientList = data.clientList;
      this.countryList = data.countryList;

      if (this.authService.activeUser && !this.authService.activeUser.isAdmin()) {
        this.clientList = this.clientList.filter(client => this.authService.activeUser?.organizationIds().indexOf(client.id) !== -1);
      }

      this.loadUniqueRetailers();
    });
  }


  async loadUniqueRetailers() {
    let retailers = [];
    try {
      retailers = await this.customerModelService.loadUniqueBrandNames(this.customer.id);
    } catch (error) {
      console.error('Error loading retailers:', error);
    }
    this.retailerList = retailers;
  }

  onWeightUnitChange() {
    if (this.weightUnit === 'lbs' && this.customer.weight) {
      this.customer.weight = parseFloat((this.customer.weight * 2.20462).toFixed(2));
    } else if (this.weightUnit === 'kg' && this.customer.weight) {
      this.customer.weight = parseFloat((this.customer.weight / 2.20462).toFixed(2));
    }
  }

  onHeightUnitChange() {
    if (this.heightUnit === 'in' && this.customer.height) {
      this.customer.height = parseFloat((this.customer.height * 0.393701).toFixed(2));
    } else if (this.heightUnit === 'cm' && this.customer.height) {
      this.customer.height = parseFloat((this.customer.height / 0.393701).toFixed(2));
    }
  }

  async onSave() {
    if (this.weightUnit === 'lbs' && this.customer.weight) {
      this.customer.weight = parseFloat((this.customer.weight / 2.20462).toFixed(2));
    }

    if (this.heightUnit === 'in' && this.customer.height) {
      this.customer.height = parseFloat((this.customer.height / 0.393701).toFixed(2));
    }

    this.processing = true;
    try {
      const updatedCustomer = await this.customer.update();
      let orgIds = [];
      if (!this.authService.activeUser?.isAdmin()) {
        orgIds = this.authService.activeUser.organizationIds();
      }

      await this.customerModelService.loadCustomerList(true, orgIds);
      this.router.navigate(['/customer', updatedCustomer.id]);
    } catch (err) {
      console.error(err);
    } finally {
      this.processing = false;
    }
  }
}
