import { Injectable } from '@angular/core';

import API, { graphqlOperation } from '@aws-amplify/api';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { deleteProduct,
         deleteProductSize,
         deleteProductMeasurement,
         deleteProductFitParameter,
         deleteProductImage,
         manageProductImageFile,
         deleteOrganizationProduct
        } from '../../graphql/mutations';

import { ProductsQuery,
         ProductQuery,
         CreateProductMutation,
         CreateProductSizeMutation,
         DeleteProductSizeMutation,
         CreateProductMeasurementMutation,
         DeleteProductMeasurementMutation,
         CreateProductFitParameterMutation,
         DeleteProductFitParameterMutation,
         UpdateProductMutation,
         UpdateProductFitParameterMutation,
         UpdateProductMeasurementMutation,
         CreateOrganizationProductMutation,
         DeleteOrganizationProductMutation,
         DeleteProductMutation,
         UpdateProductImageMutation,
         CreateProductImageMutation,
         DeleteProductImageMutation,
         ManageProductImageFileMutation,
         ProductCustomQuery,
         FitRecommendationsQuery,
         AnalyzeFitRecommendationQueryVariables,
         AnalyzeFitRecommendationQuery,
         CloneProductMutation,
         CloneProductCustomMutation,
         GenerateFitSessionComparisonQueryVariables,
         GenerateFitSessionComparisonQuery,
         GenerateFitPreferenceComparisonQueryVariables,
         GenerateFitPreferenceComparisonQuery
      } from '../../API';

import { productListing,
         productWithImagesList,
         createProductCustom,
         createProductSizeCustom,
         createProductMeasurementCustom,
         createProductFitParameterCustom,
         createOrganizationProductCustom,
         updateProductCustom,
         updateProductFitParameterCustom,
         updateProductMeasurementCustom,
         updateProductImageCustom,
         createProductImageCustom,
         productCustom,
         cloneProductCustom
        } from '../../graphql/custom';

import { analyzeFitRecommendation, fitRecommendations, generateFitPreferenceComparison, generateFitSessionComparison, measurements } from '../../graphql/queries';

import {LookupModelService,
        MeasurementModel,
        BrandModel,
        GenderModel,
        ProductTypeModel,
        SizeModel,
        ProductStatusModel,
        FitFormsModel,
        OrganizationModel
        } from './lookupmodel.service'
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { AuthService } from '../services/auth.service';

export const REGULAR_FIT_PREFERENCE = 2;
@Injectable()
export class ProductmodelService {

  currentItemsList:ProductModel[] = [];
  archivedItemList:ProductModel[] = [];
  brandList = undefined;
  archiveBrandList = undefined;
  productTypeList = undefined;
  archiveProductTypeList = undefined;
  clientList = undefined;
  archiveClientList = undefined;

  activeProduct:ProductModel;

  constructor(
    private lookupModelService:LookupModelService,
    private authService: AuthService) { }

  async loadCurrentList(forcereload=false) {
    if(this.currentItemsList.length == 0 || forcereload == true) {
      this.currentItemsList = [];
      let filterDict = {
        "filter": {
          "conjunction":"AND",
          "conditions": [{ "field": "productStatus/name", "operator": "NOT_EQUAL", "value": "archived" }]
        },
        "limit": 500,
        "offset": 0
      }

      try{
        const response = (await API.graphql(graphqlOperation(productListing, filterDict))) as {
          data: ProductsQuery;
        };
        for(let item of response.data.products){
          let product = new ProductModel();
          product.load(item);
          this.currentItemsList.push(product);
        }
        this.brandList = undefined;
        this.productTypeList = undefined;
        this.clientList = undefined;


        if (!this.authService.activeUser?.isAdmin()) {
          // TODO do this filtering in the backend. and merge with customer-resolver.service. code is unnecessarily duplicated.
          let orgIds = []
          orgIds = this.authService.activeUser.organizationIds();
          this.currentItemsList = this.currentItemsList.filter(x => x.clients?.find(client => orgIds.includes(client.id)));
        }


      }
      catch(err){
        console.log('error loading products list',err);
        throw(err)
      }
    }
    return this.currentItemsList;
  }

  async loadArchivedList(forcereload=false) {
    if(this.archivedItemList.length == 0 || forcereload == true) {
      let filterDict = {
        "filter":{
          "conjunction":"AND",
          "conditions": [{ "field": "productStatus/name", "operator": "EQUAL", "value": "archived" }]
        }
      }
      this.archivedItemList = [];

      try{
        const response = (await API.graphql(graphqlOperation(productListing,filterDict))) as {
          data: ProductsQuery;
        };
        for(let item of response.data.products){
          let product = new ProductModel();
          product.load(item);
          this.archivedItemList.push(product);
        }
        this.archiveBrandList = undefined;
        this.archiveProductTypeList = undefined;
        this.archiveClientList = undefined;
      }
      catch(err){
        console.log('error loading archived products list',err);
        throw(err)
      }
    }
    return this.archivedItemList;
  }

  async loadCurrentListWImages(forcereload=false) {
    if(this.currentItemsList.length == 0 || forcereload == true) {
      this.currentItemsList = [];
      let filterDict = {
        "filter":{
          "conjunction":"AND",
          "conditions": [
            {
              "field": "productStatus/name",
              "operator": "NOT_EQUAL",
              "value": "archived"
            }
          ]
        },
        "limit": 500,
        "offset": 0
      }

      try{
        const response = (await API.graphql(graphqlOperation(productWithImagesList,filterDict))) as {
          data: ProductsQuery;
        };

        for(let item of response.data.products){
          let product = new ProductModel();
          product.load(item);
          this.currentItemsList.push(product);
        }
        this.brandList = undefined;
        this.productTypeList = undefined;
        this.clientList = undefined;
      }
      catch(err){
        console.log('error loading products list',err);
        throw(err)
      }
    }
    return this.currentItemsList;
  }

  async loadFitRecommendationsForProduct(customerId, productId, fitPreferenceId = REGULAR_FIT_PREFERENCE) {
    try {
      const recommendationsRes = await API.graphql(graphqlOperation(fitRecommendations, {
        customerId,
        productIds: [productId],
        options: {
          fitPreferences: [
            {
              productId: productId,
              fitPreferenceId: fitPreferenceId
            }
          ]
        }
      })) as {data: FitRecommendationsQuery};

      const sizes = await this.lookupModelService.getSizes();

      const breakdown = [];

      for (const item of recommendationsRes
      .data.fitRecommendations.productFitRecommendations[0]
      .weightedScoreBreakdown) {
        const sizeName = sizes.find((el)=> (`${el.id}` === item.size))

        const size = {
          displaySize: sizeName?.shortName,
          size: item.size,
          weightedScore: recommendationsRes.data.fitRecommendations.productFitRecommendations[0].weightedScores.find(x => x.size === item.size)?.weightedScore || 0,
          scoreBreakdown: []
        };

        for (const scoreEntry of item.scoreBreakdown) {
          const updatedEntry = {
            measurement: await this.lookupModelService.getMeasurement(scoreEntry.fitPoint),
            score: scoreEntry.normalizedScore
          }
          size.scoreBreakdown.push(updatedEntry)
        }

        breakdown.push(size);
      }

      const recSize = recommendationsRes.data.fitRecommendations.productFitRecommendations[0].recommendation.sizeByTopThreeSelectivePreference;

      const recommendation = {
        size: recSize,
        displaySize: (sizes.find((el)=> (`${el.id}` === recSize)))?.shortName,
        weightedScore: recommendationsRes.data.fitRecommendations.productFitRecommendations[0].recommendation.weightedScore
      };


      return {
        productId: recommendationsRes.data.fitRecommendations.productFitRecommendations[0].productId,
        scoreBreakdown: breakdown,
        recommendation,
      }
    } catch (err) {
      const errors = err.errors;
      if (errors[0].errorType === "MissingCustomerMeasurementsError") {
        return {
          productId: productId,
          recommendation: { size: "N/A", weightedScore: 0, displaySize: 'N/A' },
          scoreBreakdown: [] as any[]
        }
      }
      console.log('error loading fit recommendations', err);
      throw(err);
    }
  }

  async analyzeFitRecommendationsForProduct(customerId, productId) {
    try {

      const variables: AnalyzeFitRecommendationQueryVariables = {
        customerId: customerId,
        productId: productId
      };
      const recommendationsRes = (await API.graphql(graphqlOperation(analyzeFitRecommendation, variables)
        )) as {data: AnalyzeFitRecommendationQuery};

      return {
        output: recommendationsRes.data.analyzeFitRecommendation
      }
    } catch (err) {
      console.log('error loading fit analysis', err);
      throw(err);
    }
  }

  async generateFitSessionComparison(customerIds: Array<string>, productIds: Array<string>) {
    try {

      const variables: GenerateFitSessionComparisonQueryVariables = {
        customerIds: customerIds,
        productIds: productIds
      };
      const recommendationsRes = (await API.graphql(graphqlOperation(generateFitSessionComparison, variables)
        )) as {data: GenerateFitSessionComparisonQuery};

      return {
        output: recommendationsRes.data.generateFitSessionComparison
      }
    } catch (err) {
      console.log('error loading fit session (fit point) comparison', err);
      throw(err);
    }
  }

  async generateFitRecommendationComparison(customerIds: Array<string>, productIds: Array<string>) {
    try {

      const variables: GenerateFitPreferenceComparisonQueryVariables = {
        customerIds: customerIds,
        productIds: productIds
      };
      const recommendationsRes = (await API.graphql(graphqlOperation(generateFitPreferenceComparison, variables)
        )) as {data: GenerateFitPreferenceComparisonQuery};

      return {
        output: recommendationsRes.data.generateFitPreferenceComparison
      }
    } catch (err) {
      console.log('error loading fit recommendations comparison', err);
      throw(err);
    }
  }



  getBrandList() {
    if(this.brandList == undefined){
      let brandSet = new Set();
      for(let item of this.currentItemsList){
        item.brand != undefined ? brandSet.add(item.brand.name) : "";
      }
      this.brandList = Array.from(brandSet);
      this.brandList.sort();
    }
    return this.brandList;
  }

  getArchiveBrandList() {
    if(this.archiveBrandList == undefined){
      let archiveBrandSet = new Set();
      for(let item of this.archivedItemList){
        item.brand != undefined ? archiveBrandSet.add(item.brand.name) : "";
      }
      this.archiveBrandList = Array.from(archiveBrandSet);
      this.archiveBrandList.sort();
    }
    return this.archiveBrandList;
  }

  getProductTypeList() {
    if(this.productTypeList == undefined){
      let productTypeSet = new Set();
      for(let item of this.currentItemsList){
        item.productType != undefined ? productTypeSet.add(item.productType.description) : "";
      }
      this.productTypeList = Array.from(productTypeSet);
      this.productTypeList.sort();
    }
    return this.productTypeList;
  }

  getArchiveProductTypeList() {
    if(this.archiveProductTypeList == undefined){
      let archiveProductTypeSet = new Set();
      for(let item of this.archivedItemList){
        item.productType != undefined ? archiveProductTypeSet.add(item.productType.description) : "";
      }
      this.archiveProductTypeList = Array.from(archiveProductTypeSet);
      this.archiveProductTypeList.sort();
    }
    return this.archiveProductTypeList;
  }

  getClientList() {
    if(this.clientList == undefined){
      let clientSet = new Set();
      for(let item of this.currentItemsList){
        let clientsAssignedToProduct = item.clients != undefined ? item.clients.map((el)=>el.name) : [];
        clientsAssignedToProduct.forEach((el)=>clientSet.add(el));
      }
      this.clientList = Array.from(clientSet);
      this.clientList.sort();
    }
    return this.clientList;
  }

  getArchiveClientList() {
    if(this.archiveClientList == undefined){
      let clientSet = new Set();
      for(let item of this.archivedItemList){
        let clientsAssignedToProduct = item.clients != undefined ? item.clients.map((el)=>el.name) : [];
        clientsAssignedToProduct.forEach((el)=>clientSet.add(el));
      }
      this.archiveClientList = Array.from(clientSet);
      this.archiveClientList.sort();
    }
    return this.archiveClientList;
  }

  updateItemsList(product:ProductModel){
    let found = false;
    if(product.productStatus.id = ProductStatusModel.STATUS_ARCHIVED_ID){
      for(let i=0;i<this.archivedItemList.length;i++){
        if(this.archivedItemList[i].id == product.id){
          this.archivedItemList[i] = product;
          found = true;
          break;
        }
      }
      if(!found){
        this.archivedItemList.push(product);
      }
      this.archiveBrandList = undefined;
      this.archiveProductTypeList = undefined;
      this.archiveClientList = undefined;
    }
    else{
      for(let i=0;i<this.currentItemsList.length;i++){
        if(this.currentItemsList[i].id == product.id){
          this.currentItemsList[i] = product;
          found = true;
          break;
        }
      }
      if(!found){
        this.currentItemsList.push(product);
      }
      this.brandList = undefined;
      this.productTypeList = undefined;
      this.clientList = undefined;
    }
  }

  async addNewItemToBenchMarkList(measurementId, product?:ProductModel) {
    product = product || this.activeProduct;
    let myMeasurement:MeasurementModel = await this.lookupModelService.getMeasurement(measurementId);
    let localBenchmarkList:ProductMeasurementsModel[] = [];
    for(let item of product.sizeIds){
      let mySize:SizeModel = await this.lookupModelService.getSize(item);
      let productMeasurement = new ProductMeasurementsModel();
      productMeasurement.id = 0;
      productMeasurement.sizeId = mySize.id;
      productMeasurement.measurementId = myMeasurement.id;
      productMeasurement.isHalfMeasurement = false;
      productMeasurement.size = mySize;
      productMeasurement.value = undefined;
      productMeasurement.measurement = myMeasurement;
      localBenchmarkList.push(productMeasurement);
    }
    product.benchmarkMeasurementsList.push(localBenchmarkList);
  }


  async addNewFieldToBenchMarkItem(measurementId, product?:ProductModel) {
    product = product || this.activeProduct;
    let myMeasurement:MeasurementModel = await this.lookupModelService.getMeasurement(measurementId);
    let localBenchmarkList:ProductMeasurementsModel[] = [];
    for(let item of product.sizeIds){
      let mySize:SizeModel = await this.lookupModelService.getSize(item);
      let productMeasurement = new ProductMeasurementsModel();
      productMeasurement.id = 0;
      productMeasurement.sizeId = mySize.id;
      productMeasurement.measurementId = myMeasurement.id;
      productMeasurement.isHalfMeasurement = false;
      productMeasurement.size = mySize;
      productMeasurement.value = undefined;
      productMeasurement.measurement = myMeasurement;
      localBenchmarkList.push(productMeasurement);
    }
    product.benchmarkMeasurementsList.push(localBenchmarkList);
  }

  async addNewItemToCoreList(measurementId, product?:ProductModel) {
    product = product || this.activeProduct;
    let myMeasurement:MeasurementModel = await this.lookupModelService.getMeasurement(measurementId);
    let localBenchmarkList = [];
    for(let item of product.sizeIds){
      let mySize:SizeModel = await this.lookupModelService.getSize(item);
      let productMeasurement = new ProductMeasurementsModel();
      productMeasurement.id = 0;
      productMeasurement.sizeId = mySize.id;
      productMeasurement.measurementId = myMeasurement.id;
      productMeasurement.isHalfMeasurement = false;
      productMeasurement.size = mySize;
      productMeasurement.value = undefined;
      productMeasurement.measurement = myMeasurement;
      localBenchmarkList.push(productMeasurement);
    }
    product.coreMeasurementsList.push(localBenchmarkList);
  }


  async updateBenchmarkMeasurementsBasedOnSizeIds(){
    let localList = this.activeProduct.benchmarkMeasurementsList.slice();
    this.activeProduct.sizeIds.sort((a,b)=>a-b);
    this.activeProduct.benchmarkMeasurementsList = [];
    for(let item of localList){
      var bmList = [];
      let myMeasurement = await this.lookupModelService.getMeasurement(item[0].measurementId);

      for(let sizeId of this.activeProduct.sizeIds){
        let findPM = item.find((el)=>{return el.sizeId == sizeId});
        if(findPM != undefined){
          bmList.push(findPM);
        }
        else{
          let productMeasurement = new ProductMeasurementsModel();
          productMeasurement.id = 0;
          productMeasurement.sizeId = sizeId;
          productMeasurement.measurementId = myMeasurement.id;
          productMeasurement.isHalfMeasurement = false;
          productMeasurement.size = await this.lookupModelService.getSize(sizeId);
          productMeasurement.value = undefined;
          productMeasurement.measurement = myMeasurement;
          bmList.push(productMeasurement);
        }
      }
      this.activeProduct.benchmarkMeasurementsList.push(bmList);
    }
  }

  async updateCoreMeasurementsBasedOnSizeIds(){
    let localList = this.activeProduct.coreMeasurementsList.slice();
    this.activeProduct.sizeIds.sort((a,b)=>a-b);
    this.activeProduct.coreMeasurementsList = [];
    for(let item of localList){
      var bmList = [];
      let myMeasurement = await this.lookupModelService.getMeasurement(item[0].measurementId);

      for(let sizeId of this.activeProduct.sizeIds){
        let findPM = item.find((el)=>{return el.sizeId == sizeId});
        if(findPM != undefined){
          bmList.push(findPM);
        }
        else{
          let productMeasurement = new ProductMeasurementsModel();
          productMeasurement.id = 0;
          productMeasurement.sizeId = sizeId;
          productMeasurement.measurementId = myMeasurement.id;
          productMeasurement.isHalfMeasurement = false;
          productMeasurement.size = await this.lookupModelService.getSize(sizeId);
          productMeasurement.value = undefined;
          productMeasurement.measurement = myMeasurement;
          bmList.push(productMeasurement);
        }
      }
      this.activeProduct.coreMeasurementsList.push(bmList);
    }
  }

  async updateProductFitParametersBasedOnMeasurements(){
    await this.updateCoreMeasurementsBasedOnSizeIds();
    var saveList:ProductFitParameterModel[] = [];
    for(let item of this.activeProduct.productFitParameters){
      if(this.measurementExists(item.measurement_id)){
        saveList.push(item);
      }
    }

    this.activeProduct.productFitParameters = saveList.slice();
    var saveList:ProductFitParameterModel[] = [];
    for(let item of this.activeProduct.coreMeasurementsList){
      let fitParameter = this.activeProduct.productFitParameters.find((el) => {return el.measurement_id == item[0].measurementId});
      if(fitParameter == undefined){
        let productFitParameter = new ProductFitParameterModel();
        productFitParameter.measurement_id = item[0].measurementId;
        productFitParameter.measurementName = item[0].measurement.description;
        productFitParameter.is_fit_point = false;
        productFitParameter.fit_weight_set = false;
        saveList.push(productFitParameter);
      }
    }

    for(let item of saveList){
      this.activeProduct.productFitParameters.push(item);
    }
    await this.updateIdealSizeOnProductFitParameter(this.activeProduct.ideal_size_id);
    await this.updateLoosePreferenceSizeOnProductFitParameter(this.activeProduct.loose_preference_size_id);
    await this.updateTightPreferenceSizeOnProductFitParameter(this.activeProduct.tight_preference_size_id);
  }

  async updateIdealSizeOnProductFitParameter(sizeId){
    var shortName:string = "";
    var size:SizeModel = undefined;
    let sizeExist = this.activeProduct.sizeIds.find((el)=>{return el==sizeId});
    if(sizeExist != undefined){
      let localSize = await this.lookupModelService.getSize(sizeId);
      if(localSize != undefined){
        shortName = localSize.shortName;
        size = localSize;
      }
    }

    for(let i=0; i<this.activeProduct.productFitParameters.length;i++){
      let sameSize:boolean = (this.activeProduct.productFitParameters[i].idealSizeShortName==shortName);
      this.activeProduct.productFitParameters[i].idealSizeShortName = shortName;
      this.activeProduct.productFitParameters[i].idealSize = size;
      this.activeProduct.productFitParameters[i].ideal_size_adjustment =
        (size != undefined && sameSize) ? this.activeProduct.productFitParameters[i].ideal_size_adjustment : undefined;
    }
  }

  async updateTightPreferenceSizeOnProductFitParameter(sizeId){
    var shortName:string = "";
    var size:SizeModel = undefined;
    let sizeExist = this.activeProduct.sizeIds.find((el)=>{return el==sizeId});
    if(sizeExist != undefined){
      let localSize = await this.lookupModelService.getSize(sizeId);
      if(localSize != undefined){
        shortName = localSize.shortName;
        size = localSize;
      }
    }

    for(let i=0; i<this.activeProduct.productFitParameters.length;i++){
      let sameSize:boolean = (this.activeProduct.productFitParameters[i].tightSizeShortName==shortName);
      this.activeProduct.productFitParameters[i].tightSizeShortName = shortName;
      this.activeProduct.productFitParameters[i].tightPreferenceSize = size;
      this.activeProduct.productFitParameters[i].tight_size_adjustment =
        (size != undefined && sameSize) ? this.activeProduct.productFitParameters[i].tight_size_adjustment : undefined;
    }
  }

  async updateLoosePreferenceSizeOnProductFitParameter(sizeId){

    var shortName:string = "";
    var size:SizeModel = undefined;
    let sizeExist = this.activeProduct.sizeIds.find((el)=>{return el==sizeId});
    if(sizeExist != undefined){
      let localSize = await this.lookupModelService.getSize(sizeId);
      if(localSize != undefined){
        shortName = localSize.shortName;
        size = localSize;
      }
    }

    for(let i=0; i<this.activeProduct.productFitParameters.length;i++){
      let sameSize:boolean = (this.activeProduct.productFitParameters[i].looseSizeShortName==shortName);
      this.activeProduct.productFitParameters[i].looseSizeShortName = shortName;
      this.activeProduct.productFitParameters[i].loosePreferenceSize = size;
      this.activeProduct.productFitParameters[i].loose_size_adjustment =
        (size != undefined && sameSize)  ? this.activeProduct.productFitParameters[i].loose_size_adjustment : undefined;
    }

  }

  measurementExists(measurement_id){
    var returnValue;
    for(let item of this.activeProduct.coreMeasurementsList){
      returnValue = item.find((el)=>{return el.measurementId == measurement_id})
      if (returnValue != undefined){
        break;
      }
    }
    return (returnValue != undefined)
  }

  compareAscSize(a:ProductMeasurementsModel,b:ProductMeasurementsModel){
    return a.sizeId < b.sizeId ? -1 : a.sizeId > b.sizeId ? 1 : 0;
  }

}

export class ProductModel {
  id:string;
  name:string;
  sku:string;
  brand_id:number;
  gender_id:number;
  product_type_id:number;
  type:string;

  fit_form_id:number;
  ideal_size_id:number;
  loose_preference_size_id:number;
  tight_preference_size_id:number;

  base_unit_price:number;
  description:string;
  product_status_id:number;

  brand:BrandModel;
  gender:GenderModel;
  productType:ProductTypeModel;

  idealSize:SizeModel;
  loosePreferenceSize:SizeModel;
  tightPreferenceSize:SizeModel;

  productStatus:ProductStatusModel;
  fitForm:FitFormsModel;

  benchmarkMeasurementsList:ProductMeasurementsModel[][] = [];
  benchmarkMeasurementsPersistedList:ProductMeasurementsModel[][] = [];
  coreMeasurementsList:ProductMeasurementsModel[][] = [];
  coreMeasurementsPersistedList:ProductMeasurementsModel[][] = [];

  sizeIds:number[] = []
  productSizes:ProductSizeModel[] = [];

  productFitParameters:ProductFitParameterModel[] = [];
  productFitParametersPersisted:ProductFitParameterModel[] = [];

  clients:OrganizationModel[] = [];
  clientsPersisted:OrganizationProductModel[] = [];

  images:ProductImageModel[] = [];
  imagesPersisted:ProductImageModel[] = [];
  files: any[] = [];

  constructor() { }

  load(item:{}){
    this.id = item['id'] != undefined ? item['id'] : "";
    this.name = item['name'] != undefined ? item['name'] : "";
    this.sku = item['sku'] != undefined ? item['sku'] : "";
    this.base_unit_price = item['base_unit_price'] != undefined ? item['base_unit_price'] : undefined;
    this.description = item['description'] != undefined ? item['description'] : undefined;

    if(item['brand'] != undefined && item['brand'].id != undefined){
      this.brand = new BrandModel().load(item['brand']);
      this.brand_id = this.brand.id;
    }

    if(item['gender'] != undefined && item['gender'].id != undefined){
      this.gender = new GenderModel().load(item['gender']);
      this.gender_id = this.gender.id;
    }

    if(item['productType'] != undefined && item['productType'].id != undefined){
      this.productType = new ProductTypeModel().load(item['productType']);
      this.product_type_id = this.productType.id;
      this.type = this.productType.name;
    }

    if(item['idealSize'] != undefined && item['idealSize'].id != undefined){
      this.idealSize = new SizeModel().load(item['idealSize']);
      this.ideal_size_id = this.idealSize.id;
    }

    if(item['loosePreferenceSize'] != undefined && item['loosePreferenceSize'].id != undefined){
      this.loosePreferenceSize = new SizeModel().load(item['loosePreferenceSize']);
      this.loose_preference_size_id = this.loosePreferenceSize.id;
    }

    if(item['tightPreferenceSize'] != undefined && item['tightPreferenceSize'].id != undefined){
      this.tightPreferenceSize = new SizeModel().load(item['tightPreferenceSize']);
      this.tight_preference_size_id = this.tightPreferenceSize.id;
    }

    if(item['productStatus'] != undefined && item['productStatus'].id != undefined){
      this.productStatus = new ProductStatusModel().load(item['productStatus']);
      this.product_status_id = this.productStatus.id;
    }

    if(item['fitForm'] != undefined && item['fitForm'].id != undefined){
      this.fitForm = new FitFormsModel().load(item['fitForm']);
      this.fit_form_id = this.fitForm.id;
    }

    this.productFitParameters = [];
    this.productFitParametersPersisted = [];
    if(item['fitParameters'] != undefined) {
      for(let itemValue of item['fitParameters']){
        let fitparm = new ProductFitParameterModel();
        fitparm.idealSize = this.idealSize;
        fitparm.loosePreferenceSize = this.loosePreferenceSize;
        fitparm.tightPreferenceSize = this.tightPreferenceSize;
        fitparm.load(itemValue);
        this.productFitParameters.push(fitparm);
        this.productFitParametersPersisted.push(fitparm);
      }
    }

    this.images = [];
    this.imagesPersisted = [];
    if(item['images'] != undefined) {
      for(let itemValue of item['images']){
        this.images.push(new ProductImageModel().load(itemValue));
      }
      this.imagesPersisted = this.images.slice();
    }

    this.benchmarkMeasurementsList = [];
    this.benchmarkMeasurementsPersistedList = [];
    if(item['benchmarkMeasurements'] != undefined) {
      let localList = {};
      for(let itemValue of item['benchmarkMeasurements']){
        if(localList[itemValue.measurement.id] == undefined){
          localList[itemValue.measurement.id] = [];
        }
        localList[itemValue.measurement.id].push(new ProductMeasurementsModel().load(itemValue));
      }
      for(let key in localList){
        // localList[key].sort(this.compareAscSize);
        this.benchmarkMeasurementsList.push(localList[key]);
        this.benchmarkMeasurementsPersistedList.push(localList[key]);
      }
    }

    this.coreMeasurementsList = [];
    this.coreMeasurementsPersistedList = [];
    if(item['coreMeasurements'] != undefined) {
      let localList = {};
      for(let itemValue of item['coreMeasurements']){
        if(localList[itemValue.measurement.id] == undefined){
          localList[itemValue.measurement.id] = [];
        }
        localList[itemValue.measurement.id].push(new ProductMeasurementsModel().load(itemValue));
      }
      for(let key in localList){
        // localList[key].sort(this.compareAscSize);
        this.coreMeasurementsList.push(localList[key]);
        this.coreMeasurementsPersistedList.push(localList[key]);
      }
    }

    this.clients = [];
    if(item['clients'] != undefined) {
      for(let itemValue of item['clients']){
        this.clients.push(new OrganizationModel().load(itemValue));
      }
    }

    for(let item of this.clients){
      let client = new OrganizationProductModel()
      client.organizationId = item.id;
      client.productId = parseInt(this.id);
      this.clientsPersisted.push(client);
    }

    this.productSizes = [];
    this.sizeIds = [];
    if(item['sizes'] != undefined) {
      for(let itemValue of item['sizes']){
        this.productSizes.push(new ProductSizeModel().load(itemValue));
      }

      for(let item of this.productSizes){
        this.sizeIds.push(item.size_id);
      }
    }
  }

  async get(id:string){
    try{
      this.id = id;

      const response = (await API.graphql(graphqlOperation(productCustom,{id:this.id}))) as {
        data: ProductCustomQuery;
      };

      if(response.data == undefined || response.data.product == undefined){
        throw new Error('Error getting product. Contact your administrator.');
      }
      this.load(response.data.product);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error getting product',err);
      throw(errorMsg)
    }
  }

  async update(){
    if(this.id == undefined || this.id.length == 0){
      return this.create();
    }

    try{
      let updateVars = this.getUpdateVars();
      updateVars['id'] = this.id;

      const response = (await API.graphql(graphqlOperation(updateProductCustom,{id:this.id,input:this.getUpdateVars()}))) as {
        data: UpdateProductMutation;
      };

      if(response.data == undefined || response.data.updateProduct == undefined){
        throw new Error('Error updating product. Contact your administrator.');
      }
      // console.log('product update',response);
      await this.updateProductRelationships();
      let updatedProduct = await this.get(this.id);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating product',err);
      throw(errorMsg)
    }
  }

  async archive(){
    try{
      let updateVars = {};
      updateVars['productStatusId'] = ProductStatusModel.STATUS_ARCHIVED_ID;

      const response = (await API.graphql(graphqlOperation(updateProductCustom,{id:this.id,input:updateVars}))) as {
        data: UpdateProductMutation;
      };

      if(response.data == undefined || response.data.updateProduct == undefined){
        throw new Error('Error archiving product. Contact your administrator.');
      }
      let updatedProduct = await this.get(this.id);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error archiving product',err);
      throw(errorMsg)
    }
  }

  async create(){
    try{
      const response = (await API.graphql(graphqlOperation(createProductCustom,{input:this.getUpdateVars()}))) as {
        data: CreateProductMutation;
      };

      if(response.data == undefined || response.data.createProduct == undefined){
        throw new Error('Error creating product. Contact your administrator.');
      }
      // console.log('product create',response);
      this.id = response.data.createProduct.id;
      await this.updateProductRelationships();
      let updatedProduct = await this.get(this.id);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error creating product',err);
      throw(errorMsg)
    }
  }

  async clone(){
    try{
      const response = (await API.graphql(graphqlOperation(cloneProductCustom,{id:this.id}))) as {
        data: CloneProductCustomMutation;
      };

      if(response.data == undefined){
        throw new Error('Error cloning product. Contact your administrator.');
      }

      return response.data.cloneProduct.id;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error cloning product',err);
      throw(errorMsg)
    }

  }

  async delete(){
    try{
      const response = (await API.graphql(graphqlOperation(deleteProduct,{id:this.id}))) as {
        data: DeleteProductMutation;
      };

      if(response.data == undefined || response.data.deleteProduct == undefined){
        throw new Error('Error deleting product. Contact your administrator.');
      }
      return;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error creating product',err);
      throw(errorMsg)
    }
  }

  async updateProductRelationships(){
    await this.saveImages();
    await this.saveProductSizes();
    await this.saveBenchMarkMeasurments();
    await this.saveCoreMeasurments();
    await this.saveFitParameters();
    await this.saveClients();
  }

  getUpdateVars(){
    var returnValue = {};
    this.name != undefined ? returnValue['name'] = this.name : "";
    this.brand_id != undefined ? returnValue['brandId'] = this.brand_id : "";
    this.sku != undefined ? returnValue['sku'] = this.sku : "";
    this.description != undefined ? returnValue['description'] = this.description : "";
    this.gender_id != undefined ? returnValue['genderId'] = this.gender_id : "";
    this.product_type_id != undefined ? returnValue['productTypeId'] = this.product_type_id : "";
    returnValue['productStatusId'] = this.product_status_id != undefined ? this.product_status_id : 1;
    this.fit_form_id != undefined ? returnValue['fitFormId'] = this.fit_form_id : "";
    this.ideal_size_id != undefined ? returnValue['idealSizeId'] = this.ideal_size_id : "";
    this.loose_preference_size_id != undefined ? returnValue['loosePreferenceSizeId'] = this.loose_preference_size_id : "";
    this.tight_preference_size_id != undefined ? returnValue['tightPreferenceSizeId'] = this.tight_preference_size_id : "";
    return returnValue;
  }

  async saveImages(){
    var savePersisted:ProductImageModel[] = this.imagesPersisted.slice();
    for(let item of this.imagesPersisted){
      if(this.images.find((element) => {return element.id == item.id}) == undefined){
        await item.delete();
      }
    }

    this.imagesPersisted = [];
    for(let item of this.images){
      item.productId = this.id;
      let persistedItem = await item.update();
      this.imagesPersisted.push(persistedItem);
    }
    this.images = this.imagesPersisted.slice();
  }

  async saveClients(){
    var savePersisted:OrganizationProductModel[] = this.clientsPersisted.slice();
    for(let item of this.clientsPersisted){
      if(this.clients.find((element) => {return element.id == item.organizationId}) == undefined){
        await item.delete();
      }
    }

    this.clientsPersisted = [];
    for(let item of this.clients){
      if(savePersisted.find((element)=> {return element.organizationId==item.id}) == undefined){
        let productOrganization = new OrganizationProductModel();
        productOrganization.organizationId = item.id;
        productOrganization.productId = parseInt(this.id);
        await productOrganization.create();
        this.clientsPersisted.push(productOrganization);
      }
    }
  }

  async saveFitParameters(){
    for(let item of this.productFitParametersPersisted){
      if(this.productFitParameters.find((element) => { return element.id == item.id }) == undefined)
        await item.delete();
    }

    this.productFitParametersPersisted = [];
    for(let item of this.productFitParameters){
      item.product_id = parseInt(this.id);
      let persistedItem = await item.update();
      this.productFitParametersPersisted.push(persistedItem);
    }
    this.productFitParameters = this.productFitParametersPersisted.slice();
  }

  async saveBenchMarkMeasurments(){
    this.benchmarkMeasurementsPersistedList = [];
    for(let item of this.benchmarkMeasurementsList){
      var bmList = [];
      for(let pm of item){
        pm.productId = parseInt(this.id);
        pm.isBenchmarkMeasurement = true;
        await pm.update();
        bmList.push(pm);
      }
      this.benchmarkMeasurementsPersistedList.push(bmList.slice());
    }
    this.benchmarkMeasurementsList = this.benchmarkMeasurementsPersistedList.slice();
  }

  measurementExists(list,id){
    var returnValue;
    for(let item of list){
      returnValue = item.find((el)=>{return el.id == id})
      if (returnValue != undefined){
        break;
      }
    }
    return (returnValue != undefined)
  }

  async saveCoreMeasurments(){
    this.coreMeasurementsPersistedList = [];
    for(let item of this.coreMeasurementsList){
      var bmList = [];
      for(let pm of item){
        pm.productId = parseInt(this.id);
        pm.isBenchmarkMeasurement = false;
        await pm.update();
        bmList.push(pm);
      }
      this.coreMeasurementsPersistedList.push(bmList.slice());
    }
    this.coreMeasurementsList = this.coreMeasurementsPersistedList.slice();
  }

  async saveProductSizes(){
    var persistList = this.productSizes.slice();
    for(let item of this.productSizes){
      if(this.sizeIds.find((el)=>{return item.size_id == el})==undefined){
        await item.delete();
      }
    }

    this.productSizes = [];
    for(var item of this.sizeIds){
      var productSize = new ProductSizeModel();
      productSize.product_id = this.id;
      productSize.size_id = item;
      this.productSizes.push(productSize);

      if(persistList.find((el)=>{return item == el.size_id})==undefined){
        let persistedItem = await productSize.update();
      }
    }
    this.sizeIds = [];
    for(let item of this.productSizes){
      this.sizeIds.push(item.size_id);
    }
  }

  compareAscSize(a:ProductMeasurementsModel,b:ProductMeasurementsModel){
    return a.sizeId < b.sizeId ? -1 : a.sizeId > b.sizeId ? 1 : 0;
  }

  clientListing() {
    const clients = this.clients || [];

    const clientNames = clients.map(x => x.name);
    return clientNames.join(", ");
  }

}

export class ProductFitParameterModel {
  id:number;
  product_id:number;
  measurement_id:number;
  is_fit_point:boolean;
  fit_weighting:number;
  fit_weight_set:boolean = false;
  ideal_size_adjustment:number;
  tight_size_adjustment:number;
  loose_size_adjustment:number;

  idealSizeShortName:string="";
  tightSizeShortName:string="";
  looseSizeShortName:string="";
  measurementName:string="";
  measurement:MeasurementModel;

  idealSize:SizeModel;
  loosePreferenceSize:SizeModel;
  tightPreferenceSize:SizeModel;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : undefined;
      this.is_fit_point = item['isFitPoint'] != undefined ? item['isFitPoint'] : undefined;
      this.fit_weighting = item['fitWeighting'] != undefined ? item['fitWeighting'] : undefined;

      this.ideal_size_adjustment = item['idealSizeAdjustment'] != undefined ? item['idealSizeAdjustment'] : undefined;
      this.tight_size_adjustment = item['tightSizeAdjustment'] != undefined ? item['tightSizeAdjustment'] : undefined;
      this.loose_size_adjustment = item['looseSizeAdjustment'] != undefined ? item['looseSizeAdjustment'] : undefined;

      this.measurement = item['measurement'] != undefined ? new MeasurementModel().load(item['measurement']) : undefined;
      this.measurement_id = this.measurement != undefined ? this.measurement.id : undefined;
      this.measurementName = this.measurement != undefined ? this.measurement.description : undefined;

      this.product_id = item['product'] != undefined ? item['product']['id'] : undefined;

      this.idealSizeShortName = this.idealSize != undefined ? this.idealSize.shortName : undefined;
      this.looseSizeShortName = this.loosePreferenceSize != undefined ? this.loosePreferenceSize.shortName : undefined;
      this.tightSizeShortName = this.tightPreferenceSize != undefined ? this.tightPreferenceSize.shortName : undefined;

      return this;
    }
    else {
      return undefined;
    }
  }

  async update(){
    if(this.id == undefined || this.id == 0){
      return this.create();
    }

    var updateVars = this.getUpdateVars();

    try{
      const response = (await API.graphql(graphqlOperation(updateProductFitParameterCustom,{id:this.id,input:updateVars }))) as {
        data: UpdateProductFitParameterMutation;
      };

      if(response.data == undefined || response.data.updateProductFitParameter == undefined){
        throw new Error('Error updating product fit parameter. Contact your administrator.');
      }
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating product fit parameter',err);
      throw(errorMsg)
    }
    return this;
  }

  async create(){
    try{
      const response = (await API.graphql(graphqlOperation(createProductFitParameterCustom,{input:this.getUpdateVars()}))) as {
        data: CreateProductFitParameterMutation;
      };

      if(response.data == undefined || response.data.createProductFitParameter == undefined){
        throw new Error('Error creating product fit parameter. Contact your administrator.');
      }
      this.id = parseInt(response.data.createProductFitParameter.id);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error creating product fit parameter',err);
      throw(errorMsg)
    }
    return this;
  }

  getUpdateVars(){
    var updateVars = {};
    updateVars['productId'] = this.product_id;
    updateVars['measurementId'] = this.measurement_id;
    this.ideal_size_adjustment != undefined ? updateVars['idealSizeAdjustment'] = this.ideal_size_adjustment : "";
    this.loose_size_adjustment != undefined ? updateVars['looseSizeAdjustment'] = this.loose_size_adjustment : "";
    this.tight_size_adjustment != undefined ? updateVars['tightSizeAdjustment'] = this.tight_size_adjustment : "";
    this.is_fit_point != undefined ? updateVars['isFitPoint'] = this.is_fit_point : false;
    this.fit_weighting != undefined ? updateVars['fitWeighting'] = this.fit_weighting : "";
    return updateVars;

  }

  async delete(){
    try{
      const response = (await API.graphql(graphqlOperation(deleteProductFitParameter,{id:this.id}))) as {
        data: DeleteProductFitParameterMutation;
      };

      if(response.data == undefined || response.data.deleteProductFitParameter == undefined){
        throw new Error('Error deleting product fit parameter. Contact your administrator.');
      }
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error deleting product fit parameter',err);
      throw(errorMsg)
    }
  }
}

export class ProductSizeModel {
  product_id:string;
  size_id:number;

  load(item:{}){
    this.product_id = item['productId'] != undefined ? item['productId'] : 0;
    this.size_id = item['sizeId'] != undefined ? item['sizeId'] : 0;
    return this;
  }

  async update(){
    let updateVars = {};
    updateVars['productId'] = this.product_id;
    updateVars['sizeId'] = this.size_id;

    try{
      const response = (await API.graphql(graphqlOperation(createProductSizeCustom,{input:updateVars}))) as {
        data: CreateProductSizeMutation;
      };

      if(response.data == undefined || response.data.createProductSize == undefined){
        throw new Error('Error creating product size. Contact your administrator.');
      }
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error creating product size',err);
      throw(errorMsg)
    }
    return this;
  }

  async delete(){
    console.log('delete:',this.product_id,":",this.size_id);
    try{
      const response = (await API.graphql(graphqlOperation(deleteProductSize,{productId:this.product_id,sizeId:this.size_id}))) as {
        data: DeleteProductSizeMutation;
      };
      console.log('delete',response);
      if(response.data == undefined || response.data.deleteProductSize == undefined){
        throw new Error('Error deleting product size. Contact your administrator.');
      }
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error deleting product size',err);
      throw(errorMsg)
    }
    return this;
  }
}

export class ProductMeasurementsModel {
  id:number;
  productId:number;
  sizeId:number;
  measurementId:number;
  isBenchmarkMeasurement:boolean;
  value:number;
  gradeRule:number;
  gradeRuleEdited:boolean;
  isHalfMeasurement:boolean;

  size:SizeModel;
  measurement:MeasurementModel;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.productId = item['productId'] != undefined ? item['productId'] : 0;
      this.sizeId = item['sizeId'] != undefined ? item['sizeId'] : 0;
      this.isBenchmarkMeasurement = item['isBenchmarkMeasurement'] != undefined ? item['isBenchmarkMeasurement'] : undefined;
      this.gradeRule = item['gradeRule'] != undefined ? item['gradeRule'] : undefined;
      this.value = item['value'] != undefined ? item['value'] : undefined;
      this.isHalfMeasurement = item['isHalfMeasurement'] != undefined ? item['isHalfMeasurement'] : false;
      this.measurement = item['measurement'] != undefined ? new MeasurementModel().load(item['measurement']) : undefined;
      this.measurementId = this.measurement != undefined ? this.measurement.id : undefined;
      this.size = item['size'] != undefined ? new SizeModel().load(item['size']) : undefined;
      this.sizeId = this.size != undefined ? this.size.id : undefined;
      this.productId = item['product'] != undefined ? item['product']['id'] : undefined;
      return this;
    }
    else {
      return undefined;
    }
  }

  async update(){
    if(this.id == undefined || this.id == 0){
      return this.create();
    }

    var updateVars = this.getUpdateVars();

    try{
      const response = (await API.graphql(graphqlOperation(updateProductMeasurementCustom,{id:this.id,input:updateVars}))) as {
        data: UpdateProductMeasurementMutation;
      };

      if(response.data == undefined || response.data.updateProductMeasurement == undefined){
        throw new Error('Error updating product measurement. Contact your administrator.');
      }
      this.id = parseInt(response.data.updateProductMeasurement.id);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating product measurement',err);
      throw(errorMsg)
    }
  }

  async create(){
    try{

      const response = (await API.graphql(graphqlOperation(createProductMeasurementCustom,{input:this.getUpdateVars()}))) as {
        data: CreateProductMeasurementMutation;
      };

      if(response.data == undefined || response.data.createProductMeasurement == undefined){
        throw new Error('Error creating product measurement. Contact your administrator.');
      }
      this.id = parseInt(response.data.createProductMeasurement.id);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error creating product measurement',err);
      throw(errorMsg)
    }
  }

  getUpdateVars(){
    let updateVars = {};
    this.value != undefined ? updateVars['value'] = this.value : 0;
    this.gradeRule != undefined ? updateVars['gradeRule'] = this.gradeRule : 0;
    updateVars['productId'] = this.productId;
    updateVars['measurementId'] = this.measurementId;
    updateVars['sizeId'] = this.sizeId;
    updateVars['isBenchmarkMeasurement'] = this.isBenchmarkMeasurement;
    updateVars['isHalfMeasurement'] = this.isHalfMeasurement;

    return updateVars;
  }

  async delete(){
    try{
      const response = (await API.graphql(graphqlOperation(deleteProductMeasurement,{id:this.id}))) as {
        data: DeleteProductMeasurementMutation;
      };

      if(response.data == undefined || response.data.deleteProductMeasurement == undefined){
        throw new Error('Error deleting product measurement. Contact your administrator.');
      }
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error deleting product measurement',err);
      throw(errorMsg)
    }
    return this;
  }

}

export class OrganizationProductModel {
  productId:number;
  organizationId:number;

  load(item:{}){
    if(Object.keys(item).length > 0){
      this.productId = item['productId'] != undefined ? item['productId'] : 0;
      this.organizationId = item['organizationId'] != undefined ? item['organizationId'] : "";
      return this;
    }
    else {
      return undefined;
    }
  }

  async create(){
    let updateVars = {};
    updateVars['productId'] = this.productId;
    updateVars['organizationId'] = this.organizationId;

    try{
      const response = (await API.graphql(graphqlOperation(createOrganizationProductCustom,{input:updateVars}))) as {
        data: CreateOrganizationProductMutation;
      };

      if(response.data == undefined || response.data.createOrganizationProduct == undefined){
        throw new Error('Error creating organization product model. Contact your administrator.');
      }
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error creating organization product model',err);
      throw(errorMsg)
    }
    return this;
  }

  async delete(){
    try{
      const response = (await API.graphql(graphqlOperation(deleteOrganizationProduct,{productId:this.productId,organizationId:this.organizationId}))) as {
        data: DeleteOrganizationProductMutation;
      };

      if(response.data == undefined || response.data.deleteOrganizationProduct == undefined){
        throw new Error('Error deleting product organization. Contact your administrator.');
      }
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error deleting product organization',err);
      throw(errorMsg)
    }
    return this;
  }
}

export class ProductImageModel {
  id:number;
  productId: string;
  productImageTypeId: string;
  imageKey: string;
  colorId: string;
  imageUrl: string;

  signedUploadUrl:string;


  load(item:{}){
    if(Object.keys(item).length > 0){
      this.id = item['id'] != undefined ? item['id'] : 0;
      this.productId = item['product'] != undefined ? item['product']['id'] : undefined;
      this.productImageTypeId = item['productImageType'] != undefined ? item['productImageType']['id'] : "";
      this.imageKey = item['imageKey'] != undefined ? item['imageKey'] : undefined;
      this.colorId = item['color'] != undefined ? item['color']['id'] : undefined;
      this.imageUrl = item['imageUrl'] != undefined ? item['imageUrl'] : undefined;
      return this;
    }
    else {
      return undefined;
    }
  }

  setFile(file){
    this.imageKey = file.name;
  }

  async update(){
    if(this.id == undefined || this.id == 0){
      return this.create();
    }

    var updateVars = this.getUpdateVars();

    try{
      const response = (await API.graphql(graphqlOperation(updateProductImageCustom,{id:this.id,input:updateVars}))) as {
        data: UpdateProductImageMutation;
      };

      if(response.data == undefined || response.data.updateProductImage == undefined){
        throw new Error('Error updating product image. Contact your administrator.');
      }
      this.id = parseInt(response.data.updateProductImage.id);
      return this;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error updating product image',err);
      throw(errorMsg)
    }
  }

  getUpdateVars(){
    let updateVars = {};
    updateVars['productId'] = this.productId;
    updateVars['productImageTypeId'] = this.productImageTypeId;
    updateVars['imageKey'] = this.imageKey;
    this.colorId != undefined ? updateVars['colorId'] = this.colorId : "";
    return updateVars;
  }

  async create(){
    try{
      const response = (await API.graphql(graphqlOperation(createProductImageCustom,{input:this.getUpdateVars()}))) as {
        data: CreateProductImageMutation;
      };

      if(response.data == undefined || response.data.createProductImage == undefined){
        throw new Error('Error creating product image. Contact your administrator.');
      }
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error creating product image.',err);
      throw(errorMsg)
    }
    return this;
  }

  async delete(){
    try{
      const response = (await API.graphql(graphqlOperation(deleteProductImage,{id:this.id}))) as {
        data: DeleteProductImageMutation;
      };

      if(response.data == undefined || response.data.deleteProductImage == undefined){
        throw new Error('Error deleting product image. Contact your administrator.');
      }
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error deleting product image',err);
      throw(errorMsg)
    }
    return this;
  }

  async loadSignedUrls(){
    console.log('productId:',this.productId,':',this.imageKey);
    if(this.productId != undefined && this.imageKey != undefined){
      try{
        const response = (await API.graphql(graphqlOperation(manageProductImageFile,{productId:this.productId, imageKey:this.imageKey}))) as {
          data: ManageProductImageFileMutation;
        };

        if(response.data == undefined || response.data.manageProductImageFile == undefined){
          throw new Error('Error getting product image signed urls. Contact your administrator.');
        }
        this.signedUploadUrl = response.data.manageProductImageFile.uploadUrl;
        return this;
      }
      catch(err){
        var errorMsg = err.errors != undefined ? err.errors[0].message : err;
        console.log('error getting product image signed urls.',err);
        throw(errorMsg)
      }
    }
  }

  async getUploadUrl(){
    if(this.signedUploadUrl == undefined){
      await this.loadSignedUrls();
    }
    return this.signedUploadUrl;
  }

}

